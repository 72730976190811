import React, { useRef } from "react";
import "./styles/index.css";
import HomeHeader from "./home_header/HomeHeader";
import Aboutus from "./about_us/Aboutus";
import Features from "./features/Features";
import Challenges from "./challenges/Challenges";
import Support from "./support/Support";
import Contactus from "./contactus/Contactus";
import Showcase from "./showcase/Showcase";
import HomeFooter from "./home_footer/HomeFooter";
import Story from "./story/Story";
import Partner from "./partners/Partner";

import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "./blog/Blog";
import Chatbox from "./chatbot/Chatbox";

AOS.init();

export default function Coverpage() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const solutionRef = useRef(null);
  const blogRef = useRef(null);
  const challengeRef = useRef(null);

  const handleNavClick = (option) => {
    switch (option) {
      case "home":
        homeRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "about":
        aboutRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "solution":
        solutionRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "blog":
        blogRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "challenge":
        challengeRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <HomeHeader handleNavClick={handleNavClick} Home={true} />
      <Showcase homeRef={homeRef} />
      <Aboutus aboutRef={aboutRef} />
      <Partner />
      <div className="section_null_space"></div>
      <Story />
      <Features solutionRef={solutionRef} />
      <Blog blogRef={blogRef} />
      <Challenges challengeRef={challengeRef} />
      <Support />
      <Contactus />
      <HomeFooter />
      {/* { <Chatbox />} */}
    </React.Fragment>
  );
}
