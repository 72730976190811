import React, { useEffect, useState } from "react";
import "../../../assets/styles/page/Page.scss";
import CenterIndent from "./Tables/centerIndent/CenterIndent.jsx";
import SectorIndent from "./Tables/sectorIndent/SectorIndent.jsx";
import moment from "moment"
import { Link } from "react-router-dom";
import axios from "axios";

function IndentRequest() {
  const [secID, setSecID] = useState("");
  const [Tab, setTab] = useState(1);
  const [dateVal, setDateVal] = useState(moment().format('YYYY-MM'));
  const [consolidateTrue, setConsolidateTrue] = useState(true)

  const handleDateChange = (e) => {
    // console.log("month", e.target.value);
    setDateVal(e.target.value)
  }
  useEffect(() => {
    getSectorIndentRequest();
    let sec = JSON.parse(localStorage.getItem("user"))
    setSecID((sec.user.Sector))
  }, [])
  const getSectorIndentRequest = async () => {
    try {
      var sec = JSON.parse(localStorage.getItem("user"))
      console.log("******DATE*****");
      let dt = dateVal.split("-")
      console.log(dt)
      console.log(dt[0])

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/supervisors/consolidate`, {
        params: {
          sectorId: sec.user.Sector,
          month: dt[1],//moment().format('MM'),
          year: dt[0], //moment().format('YYYY'),
        }
      });
      console.log("CONSOLIDATED DATA")
      console.log(res.data)
      setConsolidateTrue(!res.data.isSectorIndentView)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">INDENT REQUEST</h1>
      </div>
      <div className="page-tab-group">
        <ul className="tab-list">
          <li
            className={`${Tab === 1 ? "tab-item-active" : "tab-item"}`}
            onClick={() => setTab(1)}
          >
            <span
              className={`${Tab === 1 ? "tab-link-text-active" : "tab-link-text"
                }`}
            >
              Anganwadi Indent
            </span>
          </li>
          <li
            className={`${Tab === 2 ? "tab-item-active" : "tab-item"}`}
            onClick={() => setTab(2)}
          >
            <span
              className={`${Tab === 2 ? "tab-link-text-active" : "tab-link-text"
                }`}
            >
              Sector Indent
            </span>
          </li>
        </ul>
        {Tab == 1 && <div className="page-heading-btn-group">
          <span className="indent-card-data-text-md indent-flex-center">
            {/* October, 2021 <i class="bx bxs-calendar icons-class"></i> */}
            <input type="month" value={dateVal} onChange={handleDateChange} />
          </span>
          {/* this is sector id */}
          {consolidateTrue === true ? <Link to={{ pathname: `/Supervisor/SectorIndent/` + secID }}>
            <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"}>
              Consolidate Indents
            </button>
          </Link> : <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"} disabled={consolidateTrue}>
            Consolidate Indents
          </button>}
        </div>}
      </div>
      <div className="user-table-contents">
        {Tab === 1 && <CenterIndent dateVal={dateVal} setConsolidateTrue={setConsolidateTrue} handleDateChange={handleDateChange} Tab={Tab} />}
        {Tab === 2 && <SectorIndent dateVal={dateVal} Tab={Tab} />}
      </div>
    </div>
  );
}

export default IndentRequest;
