import React from "react";
import "../LoginForm.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

export default function VerifyOtpComponent({ setLayout }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  const verifyOTP = async (values) => {
    try {
      const resetEmail = JSON.parse(localStorage.getItem("reset"));
      const payload = {
        email: resetEmail.email,
        otp: values.otp,
      };

      const userAdd = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/verifyOtp`,
        { params: payload }
      );

      if (!userAdd.data.userVerified) {
        setError("email", {
          type: "custom",
          message: userAdd.data.message,
        });
      } else {
        localStorage.setItem("reset", JSON.stringify(payload));
        setLayout("4");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form
      action="#"
      className="mi_login_form"
      onSubmit={handleSubmit(verifyOTP)}
    >
      <div className="form_headline">
        <h1 className="form_headline_ts">Verify Your Account</h1>
        <p className="form_subheadline_ts">
          Please, Enter the OTP send to your mail
        </p>
      </div>

      <div className="form_input">
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">OTP</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="otp"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  type="text"
                  class="input_field"
                  {...field}
                  error={Boolean(errors.otp)}
                  helperText={
                    errors.otp?.type === "required"
                      ? "OTP is required!"
                      : errors.otp?.message
                  }
                />
              )}
            />
            <p className="custom_error_text">{errors.otp?.type === "required"
                      ? "OTP is required!"
                      : errors.otp?.message}</p>
          </div>
        </div>
      </div>
      <div className="form_action">
        <button type="submit" className="form_submit_button">
          Verify
        </button>
      </div>
    </form>
  );
}
