import React, { useState, useEffect } from "react";
import "../../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import CodeIcon from "@mui/icons-material/Code";
import OutboundRow from "./OutboundRow";
import axios from "axios"
import moment from "moment"
import Popup from "../../../../../common/Popup/Popup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";


function Outbound({ dateVal }) {
  // console.log("****************")
  const [openModal, setOpenModal] = useState(false);
  const [indent , setIndent] = useState([])
  const getIndent = async () => {
    try {
      let dt = dateVal.split("-");
      const data = {
        month: dt[1],
        year: dt[0],
      }
      // console.log(data)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/coindent`, {params: data});
      console.log(res.data)
      // const res =  await axios.get(`${process.env.REACT_APP_API_URL}/co/getCoOutboundList`)
      setIndent(res.data)
      // console.log("OUTBOUND*******");
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    console.log("process.env.REACT_APP_API_URL" , process.env.REACT_APP_API_URL);
    getIndent()
  } , []);
  
  const CustomFilter = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="table-head-wrapper table-flex-center cursorP"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      id="dropdown-basic"
    >
      <span className="table-heading-text">{children}</span>
      <CodeIcon className="filter-icon" />
    </div>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <div className="menu-list">
            <FormControl
              autoFocus
              className="filter-search filter-none"
              placeholder="Search"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <ul className="list-unstyled dropdown-list">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        </div>
      );
    }
  );
  const Data = [
    {
      id: "1",
      status: "APPROVED",
    }
  ];

  return (
    <>
      <TableContainer
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
          borderRadius: "0.4rem",
          background: "#fff",
        }}
      >
        <Table sx={{ minWidth: 1098 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomFilter}
                    id="dropdown-custom-components"
                  >
                    Supplier
                  </Dropdown.Toggle>
                </Dropdown>
              </TableCell>
              <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomFilter}
                    id="dropdown-custom-components"
                  >
                    Commodity
                  </Dropdown.Toggle>
                </Dropdown>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Quantity</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Indent ID</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper table-head-wrapper-border-less">
                  <span className="table-heading-text">Date</span>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {Data.map((rows, index) => (
              <OutboundRow key={index} index={index} rows={rows} />
            ))} */}
            {indent && indent.map((rows, index) => (
              <OutboundRow key={index} index={index} rows={rows} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <DialogContentText>
            <Popup setOpenModal={setOpenModal} indentId={"463"} redirectLink="/Co/IndentRequest"  text="Consolidate requests" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Outbound;
