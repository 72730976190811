import React from "react";

export default function BlogBody({ blog }) {
  return (
    <>
      {/* <article class="blog_full_details">
        {blog?.section1 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section1?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section1?.para}</p>
          </div>
        ) : null}
        {blog?.section2 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section2?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section2?.para}</p>
          </div>
        ) : null}
        {blog?.section3 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section3?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section3?.para}</p>
          </div>
        ) : null}
        {blog?.section4 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section4?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section4?.para}</p>
          </div>
        ) : null}
        {blog?.section5 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section5?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section5?.para}</p>
          </div>
        ) : null}
        {blog?.section6 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section6?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section6?.para}</p>
          </div>
        ) : null}
        {blog?.section7 ? (
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              {blog?.section7?.heading}
            </h1>
            <p class="mi_body site_body_ts">{blog?.section7?.para}</p>
          </div>
        ) : null}
      </article> */}
      {blog.id === 0 && (
        <article class="blog_full_details">
          <div class="paragraph_wrapper">
            <p class="mi_body site_body_ts">
              In a groundbreaking moment for technology-powered humanitarian
              solutions, UnifyAid proudly stood among 16 select innovation teams
              from around the world at the WFP Humanitarian Innovation Pitch
              Event held in Luxembourg on June 29, 2023.
            </p>
          </div>

          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              A Momentous Collaboration
            </h1>
            <p class="mi_body site_body_ts">
              This event was not just another pitch session; it was a milestone
              in collaborative efforts towards resolving global humanitarian
              challenges. Hosted by the WFP Innovation Accelerator, this event
              was initiated in partnership with the Government of Luxembourg and
              the Austrian Development Agency. This collaboration underscores
              the collective commitment to leverage technology for humanitarian
              impact.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              A Rigorous Selection Process
            </h1>
            <p class="mi_body site_body_ts">
              What makes this accolade even more special for us is the rigorous
              selection procedure that led us to this platform. Out of 379
              applications from 85 countries, UnifyAid was one of the 16
              innovations handpicked to present at the event. We were not just
              representing UnifyAid but carrying the hopes and aspirations of
              communities that we serve, making it a truly remarkable
              experience.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <p class="site_bold_text_ts">See It to Believe It</p>
            <p class="mi_body site_body_ts">
              For a deeper insight into this transformative journey and the
              impact we’re striving to make, watch our presentation at the event
              below.
            </p>
          </div>
          <div className="blog_video_preview">
            <div className="blog_video_container">
              <iframe
                src="https://www.youtube-nocookie.com/embed/oJ_h3NS8H00?si=fP6CYBMMbjBC5cCC&amp;start=1320"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              The Gathering of Minds
            </h1>
            <p class="mi_body site_body_ts">
              The event was graced by eminent personalities like Franz Fayot,
              Minister for Development Cooperation and Humanitarian Affairs,
              Luxembourg; Heinz Habertheuer, Deputy Managing Director of the
              Austrian Development Agency; and Bernhard Kowatsch, Head of the
              WFP Innovation Accelerator. Their opening remarks set an engaging
              and collaborative tone for the event, highlighting the importance
              of innovation in humanitarian endeavors.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              A Platform for Real-world Impact
            </h1>
            <p class="mi_body site_body_ts">
              The innovation teams gathered from diverse corners of the world
              shared awe-inspiring presentations that captivated the audience.
              UnifyAid took this opportunity to showcase our advanced biometric
              systems and beneficiary engagement platforms, which are redefining
              the way humanitarian aid is delivered and measured.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              A Beacon for the Future
            </h1>
            <p class="mi_body site_body_ts">
              The closing remarks by Sasha Baillie, CEO of Luxinnovation GIE,
              reinforced Luxembourg's long-standing commitment to prioritize
              innovation. This event was not just a culmination of the intensive
              virtual bootcamp run by WFP Innovation Accelerator but a stepping
              stone toward a more inclusive and tech-driven humanitarian
              landscape.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              Stay Tuned for More
            </h1>
            <p class="mi_body site_body_ts">
              Our journey has just begun, and the road ahead promises more
              challenges and opportunities. Stay updated on the strides we are
              making in transforming the humanitarian sector. For a firsthand
              experience of the event's atmosphere, watch our video presentation
              above.
            </p>
          </div>
        </article>
      )}
      {blog.id === 1 && (
        <article class="blog_full_details">
          <div class="paragraph_wrapper">
            <p class="mi_body site_body_ts">
              In the vast world of humanitarian aid, a new name has emerged,
              redefining the way assistance reaches those who need it most. This
              is our story.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              Why UnifyAid was Born
            </h1>
            <p class="mi_body site_body_ts">
              In regions like Telangana, where our pilot programs first took
              root, the complexities of aid distribution were evident.
              Traditional models struggled to keep pace with the immediacy of
              need, often mired in logistical challenges or lacking
              transparency. UnifyAid was conceived to address these very
              challenges, to transform not just how aid is delivered, but also
              how its impact is felt.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              Our Team: Where Tech Meets Compassion
            </h1>
            <p class="mi_body site_body_ts">
              The heartbeat of UnifyAid is its people. Our team consists of
              tech-savvy innovators and compassionate humanitarians, creating a
              unique synergy. Together, we've developed tools like our advanced
              biometric systems, which prioritize secure and accurate aid
              delivery, ensuring that resources reach the intended hands, every
              single time.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              Engaging the Beneficiary: A Two-Way Street
            </h1>
            <p class="mi_body site_body_ts">
              Historically, aid recipients have often been passive players in
              the grand scheme of things. UnifyAid is changing that narrative.
              Our beneficiary engagement platforms ensure those we assist have a
              voice in the process. Feedback loops, engagement sessions, and
              communication tools allow us to listen, adapt, and evolve, making
              aid a collaborative effort.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              More than Just Aid: Empowering Through Innovation
            </h1>
            <p class="mi_body site_body_ts">
              Our approach at UnifyAid is holistic. We don't just drop off
              supplies and leave. With our incentive mechanisms, we motivate
              communities towards sustainable behaviors, turning immediate
              relief into long-term growth. Every project is an opportunity to
              empower, educate, and elevate.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              In the Trenches: Real-world Impact
            </h1>
            <p class="mi_body site_body_ts">
              Our operations on the ground, like in Telangana, are a testament
              to our commitment. By streamlining supply chain management,
              optimizing logistics, and ensuring a two-way communication
              channel, we've witnessed communities transform. Stories of
              families being able to rebuild, children returning to schools, and
              communities coming together are what fuels our mission.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">The Road Ahead</h1>
            <p class="mi_body site_body_ts">
              While our journey has been rewarding, it's only the beginning. As
              we expand our operations, introduce new technological solutions,
              and partner with global players, our vision remains clear: a world
              where aid is not just delivered but transforms lives.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <p class="mi_body site_body_ts">
              So, as we pen this introduction, it's not just about announcing a
              name in the humanitarian sector. It's about inviting you to
              witness a change, a revolution in aid, and to be part of this
              transformative journey with UnifyAid.
            </p>
          </div>
        </article>
      )}
      {/* {blog.id === 2 && (
        <article class="blog_full_details">
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              1. Embracing Technology, but with Empathy
            </h1>
            <p class="mi_body site_body_ts">
              While the digital age has equipped us with powerful tools, it's
              the human touch that makes them truly effective. For every
              AI-driven solution we adopt, we ensure it resonates with the real
              needs and cultural nuances of the beneficiaries.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: Technology serves best when tempered with empathy.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              2. Multi-Stakeholder Engagement is Key
            </h1>
            <p class="mi_body site_body_ts">
              Our platforms aren’t just tools; they are digital arenas fostering
              dialogue among varied stakeholders in the humanitarian-development
              nexus. By involving all relevant voices, we ensure a richer, more
              effective aid strategy.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: Collaboration amplifies impact.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              3. Flexibility in Delivery
            </h1>
            <p class="mi_body site_body_ts">
              Every region, crisis, and community is unique. Our methodologies
              are not rigid but are adaptable, ensuring that we meet specific
              needs in the most effective way possible.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: One size doesn’t fit all in humanitarian aid.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              4. Prioritizing Local Insights
            </h1>
            <p class="mi_body site_body_ts">
              No one knows a community better than its own members. At UnifyAid,
              we place immense value on ground-level insights, using them to
              shape our aid strategies.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: Local wisdom is an invaluable asset.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              5. Continuous Learning and Iteration
            </h1>
            <p class="mi_body site_body_ts">
              The digital landscape and humanitarian needs are ever-changing.
              Our approach, driven by data and feedback, is constantly
              fine-tuned to remain relevant and impactful.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: Innovation is a continuous journey, not a destination.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              6. Transparency as a Tenet
            </h1>
            <p class="mi_body site_body_ts">
              In our operations, we uphold a transparent approach, ensuring all
              stakeholders, from donors to beneficiaries, understand and trust
              our processes.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: Transparency builds trust, and trust is foundational in
              humanitarian work.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              7. Beyond Aid: Fostering Resilience
            </h1>
            <p class="mi_body site_body_ts">
              Our vision extends beyond immediate relief. By leveraging
              technology and strategic partnerships, we aim to empower
              communities, enabling them to rise stronger post-crisis.
            </p>
            <p class="mi_body site_body_ts">
              Lesson: True humanitarian work empowers communities for the
              future.
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">In Conclusion</h1>
            <p class="mi_body site_body_ts">
              Innovation at UnifyAid isn’t just about integrating the latest
              technologies; it’s about weaving them into the fabric of
              humanitarian efforts with sensitivity, insight, and foresight. As
              we continue our journey, we stay committed to learning, evolving,
              and innovating for a brighter, more hopeful tomorrow.
            </p>
          </div>
        </article>
      )} */}
      {blog.id === 2 && (
        <article class="blog_full_details">
          <div class="paragraph_wrapper">
            <p class="mi_body site_body_ts">
              In a world where identity often dictates access to essential
              services and rights, establishing one's identity becomes
              paramount. For refugees, displaced individuals, and those living
              in crisis-ridden areas, this seemingly simple act can be riddled
              with challenges. How do we, at UnifyAid, navigate this complex
              maze of beneficiary verification, especially when conventional
              methods might not suffice?
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">Introduction</h1>
            <p class="mi_body site_body_ts">
              In a world where identity often dictates access to essential
              services and rights, establishing one's identity becomes
              paramount. For refugees, displaced individuals, and those living
              in crisis-ridden areas, this seemingly simple act can be riddled
              with challenges. How do we, at UnifyAid, navigate this complex
              maze of beneficiary verification, especially when conventional
              methods might not suffice?
            </p>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              The Identity Dilemma in Crisis Areas
            </h1>
            <div className="paragraph_points_list">
              <p class="mi_body site_body_ts">
                <span>1. Displacements and Lost Documents:</span> In the turmoil
                of conflict, natural disasters, or sudden relocations, many lose
                their official papers, from birth certificates to national ID
                cards. This loss doesn't just signify misplaced papers but can
                result in the loss of one's recognized existence in bureaucratic
                systems.
              </p>
              <p class="mi_body site_body_ts">
                <span>2. Unique Challenges of Crisis Zones:</span>{" "}
                Infrastructure deficits, absence of consistent networks, and the
                transient nature of makeshift camps further complicate the
                process of verifying an individual's identity.
              </p>
            </div>
          </div>
          <div class="paragraph_wrapper">
            <h1 class="mi_miniheading site_subheading_ts">
              Traditional Ways of Beneficiary Verification
            </h1>
            <div className="paragraph_points_list">
              <p class="mi_body site_body_ts">
                <span>1. Physical Checks:</span> Many organizations, including
                NGOs, UN agencies, and grassroots groups, rely on ground-level
                personnel to verify individuals. This could be based on personal
                testimonies, cross-referencing family connections, or even
                community validations.
              </p>
              <p class="mi_body site_body_ts">
                <span>2. Paper Documents:</span> While not always official,
                temporary IDs or attestations issued by camps, NGOs, or interim
                administrative bodies can serve as a makeshift solution in lieu
                of official documents.
              </p>
            </div>
            <div class="paragraph_wrapper">
              <h1 class="mi_miniheading site_subheading_ts">
                The Potential of Diverse Identification Methods
              </h1>
              <div className="paragraph_points_list">
                <p class="mi_body site_body_ts">
                  <span>1. Digital Identification:</span> In zones where
                  infrastructure allows, secure digital identification systems
                  serve as a beacon of efficiency, reducing paperwork and
                  accelerating aid distribution.
                </p>
                <p class="mi_body site_body_ts">
                  <span>2. Biometrics & Other Advanced Tech:</span> Where
                  applicable, we explore advanced solutions such as offline
                  biometric verification to establish a beneficiary's identity,
                  ensuring that the process remains secure and swift.
                </p>
                <p class="mi_body site_body_ts">
                  <span>3. Community-Driven Verification:</span> Local community
                  leaders and structures often play an instrumental role in
                  validation, bridging the gap where technology can't reach.
                </p>
              </div>
            </div>
            <div class="paragraph_wrapper">
              <h1 class="mi_miniheading site_subheading_ts">
                UnifyAid's Holistic Approach to Beneficiary Verification
              </h1>
              <div className="paragraph_points_list">
                <p class="mi_body site_body_ts">
                  <span>1. Adaptive Systems:</span> We've designed our processes
                  to be context-specific. Whether it's employing a tech solution
                  or relying on community testimonies, our systems are equipped
                  to adapt to the regional needs.
                </p>
                <p class="mi_body site_body_ts">
                  <span>2. Engaging the Beneficiaries:</span> Every individual
                  has a story, and we believe in listening. Our methods
                  emphasize personal narratives, ensuring the verification
                  process remains humane and considerate.
                </p>
                <p class="mi_body site_body_ts">
                  <span>3. Collaborative Framework:</span> Our strength lies in
                  collaboration. UnifyAid actively seeks partnerships with local
                  entities, international NGOs, and various stakeholders in the
                  humanitarian-development nexus, ensuring a 360-degree approach
                  to verification.
                </p>
              </div>
            </div>
            <div class="paragraph_wrapper">
              <h1 class="mi_miniheading site_subheading_ts">
                Future Prospects: Beyond Digital
              </h1>
              <div className="paragraph_points_list">
                <p class="mi_body site_body_ts">
                  <span>1. Empowering Local Entities:</span> We're committed to
                  upskilling local groups and community leaders, empowering them
                  to play a proactive role in the verification process, thereby
                  decentralizing and democratizing the process.
                </p>
                <p class="mi_body site_body_ts">
                  <span>2. Interlinking Systems:</span> The vision ahead
                  involves creating synergized systems where digital IDs
                  seamlessly integrate with community-driven methods for a
                  robust and holistic solution.
                </p>
                <p class="mi_body site_body_ts">
                  <span>3. Strengthening Partnerships:</span> As we navigate the
                  future, UnifyAid is eager to broaden its horizon of
                  collaborations, with the aim of co-creating shared solutions
                  within the humanitarian-development realm.
                </p>
              </div>
            </div>
            <div class="paragraph_wrapper">
              <h1 class="mi_miniheading site_subheading_ts">Conclusion</h1>
              <p class="mi_body site_body_ts">
                The task of verifying one's identity, especially in
                unpredictable environments, is daunting but vital. At UnifyAid,
                we understand the stakes and are ceaselessly working towards
                innovative, inclusive, and compassionate solutions that bridge
                the gap between immediate humanitarian needs and long-term
                development goals.
              </p>
            </div>
          </div>
        </article>
      )}
    </>
  );
}

{
  /* <div class="paragraph_wrapper">
  <h1 class="mi_miniheading site_subheading_ts"></h1>
  <div className="paragraph_points_list">
    <p class="mi_body site_body_ts">
      <span></span>
    </p>
    <p class="mi_body site_body_ts">
      <span></span>
    </p>
  </div>
</div> */
}
