import React, { useState } from "react";
import "./HomeHeader.css";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import brandLogo from "../../../assets/icons/logo/unifyaid_logo.svg";
import LoginForm from "../LoginForm/LoginForm";
import { Link, useNavigate } from "react-router-dom";

export default function HomeHeader({ handleNavClick, Home }) {
  const [NavClick, setNavClick] = useState("home");
  const [Mode, setMode] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const [MobileMenu, setMobileMenu] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = (value) => {
    setOpen(true);
    console.log(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <header className="Header__main_container">
        <div className="Page__main_container">
          <nav
            className="Header__navbar_wrapper"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <Link to="/" className="Header__brand_logo">
              <img src={brandLogo} alt="website logo" />
            </Link>
            <div className="Header__nav_menu_container">
              <ul className="Header__menu_list">
                <li className="Header__menu_item">
                  <div
                    className={`Header__hyperlink ${
                      NavClick === "home" && "link_active"
                    }`}
                    onClick={() => {
                      if (Home) {
                        setNavClick("home");
                        handleNavClick("home");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <span className={`menu_link_dot`}></span>
                    <p className="Header__menu_link_text">HOME</p>
                  </div>
                </li>
                <li className="Header__menu_item">
                  <div
                    className={`Header__hyperlink ${
                      NavClick === "about" && "link_active"
                    }`}
                    onClick={() => {
                      if (Home) {
                        setNavClick("about");
                        handleNavClick("about");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <span className={`menu_link_dot`}></span>
                    <p className="Header__menu_link_text">ABOUT</p>
                  </div>
                </li>
                <li className="Header__menu_item">
                  <div
                    className={`Header__hyperlink ${
                      NavClick === "solution" && "link_active"
                    }`}
                    onClick={() => {
                      if (Home) {
                        setNavClick("solution");
                        handleNavClick("solution");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <span className={`menu_link_dot`}></span>
                    <p className="Header__menu_link_text">SOLUTION</p>
                  </div>
                </li>
                <li className="Header__menu_item">
                  <div
                    className={`Header__hyperlink ${
                      NavClick === "blog" && "link_active"
                    }`}
                    onClick={() => {
                      if (Home) {
                        setNavClick("blog");
                        handleNavClick("blog");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <span className={`menu_link_dot`}></span>
                    <p className="Header__menu_link_text">BLOG</p>
                  </div>
                </li>
                {/* <li className="Header__menu_item">
                  <div
                    className={`Header__hyperlink ${
                      NavClick === "challenge" && "link_active"
                    }`}
                    onClick={() => {
                      if (Home) {
                        setNavClick("challenge");
                        handleNavClick("challenge");
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <span className={`menu_link_dot`}></span>
                    <p className="Header__menu_link_text">CHALLENGES</p>
                  </div>
                </li> */}
              </ul>
              <div className="Header__menu_action_wrapper">
                <button
                  className="mi_btn mi_btn_medium mi_btn_primary"
                  onClick={() => {
                    setOpen(true);
                    setMode("user");
                  }}
                >
                  Login
                </button>
                <button
                  className="mi_btn mi_btn_medium mi_btn_outline"
                  onClick={() => {
                    setOpen(true);
                    setMode("admin");
                  }}
                >
                  Admin
                </button>
              </div>
              <div
                className="Header__mobile_icon"
                onClick={() => setMobileMenu(true)}
              >
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="header_null_space"></div>

      <div
        className={`unify__mobile_menu ${MobileMenu && "show__mobile_menu"}`}
      >
        <div className="unify__mobile_wrapper">
          <div className="mobile_wrapper_header">
            <h1 className="mobile_headline_text">NAVIGATION.</h1>
            <div
              className="Header__mobile_close_icon"
              onClick={() => setMobileMenu(false)}
            >
              <i class="fa-solid fa-x"></i>
            </div>
          </div>
          <div className="mobile_wrapper_body">
            <div
              className="mobile__nav_link_card"
              onClick={() => {
                handleNavClick("home");
                setMobileMenu(false);
              }}
            >
              <p className="mobile_navlink_text">Home</p>
            </div>
            <div
              className="mobile__nav_link_card"
              onClick={() => {
                handleNavClick("about");
                setMobileMenu(false);
              }}
            >
              <p className="mobile_navlink_text">About Us</p>
            </div>
            <div
              className="mobile__nav_link_card"
              onClick={() => {
                handleNavClick("feature");
                setMobileMenu(false);
              }}
            >
              <p className="mobile_navlink_text">Features</p>
            </div>
            <div
              className="mobile__nav_link_card"
              onClick={() => {
                handleNavClick("blog");
                setMobileMenu(false);
              }}
            >
              <p className="mobile_navlink_text">Blog</p>
            </div>
            <div className="mobile__nav_link_card">
              <div className="Header__mobile_menu_action_wrapper">
                <button
                  className="mi_btn mi_btn_medium mi_btn_primary"
                  onClick={() => {
                    setOpen(true);
                    setMode("user");
                    setMobileMenu(false);
                  }}
                >
                  Login
                </button>
                <button
                  className="mi_btn mi_btn_medium mi_btn_outline"
                  onClick={() => {
                    setOpen(true);
                    setMode("admin");
                    setMobileMenu(false);
                  }}
                >
                  Admin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent sx={{ padding: "1rem !important" }}>
          <LoginForm onClose={handleClose} Mode={Mode} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
