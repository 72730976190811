import React from "react";
import Breadcrumb from "../blogPost/blogComponents/Breadcrumb";
import "./Reachout.css";
import HomeHeader from "../home_header/HomeHeader";
import HomeFooter from "../home_footer/HomeFooter";

export default function Reachout() {
  window.onbeforeunload = () => {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
  };
  return (
    <React.Fragment>
      <HomeHeader />
      <section className="Reachout__main_container section_bottom_space">
        <div className="Page__main_container">
          <Breadcrumb contact={true} />
          <div className="Reachout__layout_wrapper">
            <div class="contact_us_grid">
              <article class="contact_us_content">
                <div class="contact_headline">
                  <h1 class="mi_title feature_title_ts">
                    Let’s Transform Lives Together
                  </h1>
                  <p class="contact_subheading_ts">
                    Your questions and feedback are important to us. Whether you
                    are looking to partner with us, want to know more about our
                    solutions, or simply wish to engage in meaningful dialogue
                    about humanitarian aid and development, we’re here for you.
                  </p>
                </div>
              </article>
              <article class="contact_us_form">
                <form
                  action="https://formspree.io/f/xdovdlna"
                  method="POST"
                  class="site_contact_form"
                >
                  <div class="input_two_column">
                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="firstname">
                          First Name
                        </span>
                        <span class="important_sign">*</span>
                      </label>
                      <div class="input_wrap">
                        <input
                          type="text"
                          name="firstname"
                          required
                          class="input_field"
                        />
                      </div>
                    </div>

                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="lastname">
                          Last Name
                        </span>
                        <span class="important_sign">*</span>
                      </label>
                      <div class="input_wrap">
                        <input
                          type="text"
                          name="lastname"
                          required
                          class="input_field"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="input_two_column">
                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="email">
                          Email
                        </span>
                        <span class="important_sign">*</span>
                      </label>
                      <div class="input_wrap">
                        <input
                          type="text"
                          name="email"
                          required
                          class="input_field"
                        />
                      </div>
                    </div>

                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="company">
                          Company Name
                        </span>
                        <span class="important_sign">*</span>
                      </label>
                      <div class="input_wrap">
                        <input
                          type="text"
                          name="company"
                          required
                          class="input_field"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="input_full_column">
                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="job">
                          Job Title
                        </span>
                        <span class="important_sign">*</span>
                      </label>
                      <div class="input_wrap">
                        <input
                          type="text"
                          name="job"
                          required
                          class="input_field"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="input_full_column">
                    <div class="input_space">
                      <label for="" class="input_label">
                        <span class="label_text" for="message">
                          Your Message?
                        </span>
                      </label>
                      <div class="input_wrap">
                        <textarea
                          id="what_are_you_looking_to_do_with_on_demand_delivery_-2c6ecff4-8e1e-43b3-9961-8a890f3fa5b0"
                          class="hs-input hs-fieldtype-textarea"
                          placeholder=""
                          name="message"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="terms_area">
                    <p class="mi_body terms_body_ts">
                      I confirm, I have read and agree to UnifyAid, Privacy
                      policy and consent to share my information.
                    </p>
                  </div>

                  <div class="input_wrap">
                    <ul class="inputs-list">
                      <li class="hs-form-booleancheckbox">
                        <label
                          for="LEGAL_CONSENT.subscription_type_35377487-2c6ecff4-8e1e-43b3-9961-8a890f3fa5b0"
                          class="hs-form-booleancheckbox-display"
                        >
                          <input
                            id="LEGAL_CONSENT.subscription_type_35377487-2c6ecff4-8e1e-43b3-9961-8a890f3fa5b0"
                            class="hs-input"
                            type="checkbox"
                            value="true"
                            name="terms&condition"
                            required
                          />
                          <span>
                            I agree to the Terms & Conditions of UnifyAid
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div class="form_action">
                    <button
                      className="mi_btn mi_btn_medium mi_btn_primary"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </article>
            </div>
          </div>
        </div>
      </section>
      <HomeFooter />
    </React.Fragment>
  );
}
