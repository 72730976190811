import React from "react";
import "./Features.css";
import SectionTitle from "../common/section_title/SectionTitle";
import Iconcard from "../common/icon_card/Iconcard";

import Feature1 from "../../../assets/icons/pictures/feature1.png";
import Feature2 from "../../../assets/icons/pictures/feature2.png";

export default function Features({ solutionRef }) {
  return (
    <React.Fragment>
      <div className="Features__main_container section_space" ref={solutionRef}>
        <div className="Page__main_container">
          <div className="Features__layout_wrapper">
            <div className="Feature__section_title_tagline section_left_space">
              <SectionTitle
                tagline="Last Mile Empowerment"
                title="Bridging the Last Mile: Innovative Humanitarian Aid Solutions by UnifyAid"
              />
            </div>
            <main className="Features__two_column_layout">
              <article className="Features__content_area">
                <div className="Feature__points_list section_left_space">
                  <div
                    className="fearure_card_wrapper"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Iconcard
                      icon="fa-solid fa-building-circle-arrow-right"
                      title="Aid Distribution Optimization"
                      description="Streamlining supply chain management, inventory tracking, and
            logistics planning for swift and efficient aid distribution."
                    />
                  </div>
                  <div
                    className="fearure_card_wrapper"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Iconcard
                      icon="fa-solid fa-fingerprint"
                      title="Digital Identification and Authentication"
                      description="Implementing robust digital identification systems using advanced biometrics for secure and accurate aid delivery."
                    />
                  </div>
                  <div
                    className="fearure_card_wrapper"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Iconcard
                      icon="fa-solid fa-message"
                      title="Beneficiary Engagement and Feedback"
                      description="Empowering aid recipients to actively participate, provide feedback, and engage in two-way communication with aid providers."
                    />
                  </div>
                  <div
                    className="fearure_card_wrapper"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Iconcard
                      icon="fa-solid fa-chart-column"
                      title="Monitoring and Evaluation"
                      description="Providing comprehensive tools for data collection, analysis, and reporting to measure the impact of aid initiatives."
                    />
                  </div>
                  <div
                    className="fearure_card_wrapper"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Iconcard
                      icon="fa-solid fa-gifts"
                      title="Incentive Mechanisms"
                      description="Introducing innovative reward systems to motivate positive behaviors and ensure effective delivery of aid"
                    />
                  </div>
                </div>
              </article>
              <article className="Features__image_area">
                <img
                  src={Feature1}
                  alt="Feature1"
                  data-aos="fade-left"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                />
                <img
                  src={Feature2}
                  alt="Feature2"
                  data-aos="fade-left"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                />
              </article>
            </main>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
