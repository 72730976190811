import React from "react";
import "./Support.css";
import Iconcard from "../common/icon_card/Iconcard";

export default function Support() {
  return (
    <React.Fragment>
      <section className="Support__main_container section_space">
        <div className="Page__main_container">
          <div className="Support__layout_wrapper">
            <div className="Support__title_tagline">
              <h1 className="SectionTitle__title_text plain_color">
                Who is Our Solution For?
              </h1>
            </div>
            <div className="Support__points_card_list">
              <div
                className="Card_wrapper"
                data-aos="slide-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <Iconcard
                  icon="fa-solid fa-kit-medical"
                  title="Humanitarian Organizations"
                  description="Streamlining operations and improving efficiency, UnifyAid helps humanitarian organizations enhance their impact on the ground. By fostering accountability and transparency, it ensures that aid reaches those in need."
                />
              </div>
              <div
                className="Card_wrapper"
                data-aos="slide-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <Iconcard
                  icon="fa-solid fa-building-ngo"
                  title="Governments and Authorities"
                  description="UnifyAid's platform offers governments and authorities tools to oversee and manage aid distribution effectively. With an emphasis on integrity and innovation, it aids in disaster management and policy implementation."
                />
              </div>
              <div
                className="Card_wrapper"
                data-aos="slide-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                {" "}
                <Iconcard
                  icon="fa-solid fa-heart"
                  title="Donors and Funders"
                  description="Ensuring that contributions are utilized transparently and effectively, UnifyAid's solutions provide donors and funders with insights into where and how their support is making a difference."
                />
              </div>
              <div
                className="Card_wrapper"
                data-aos="slide-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <Iconcard
                  icon="fa-solid fa-handshake"
                  title="Aid Recipients"
                  description="Putting the beneficiaries at the heart of aid delivery, UnifyAid empowers recipients through active participation, feedback mechanisms, and transparent communication."
                />
              </div>
              <div
                className="Card_wrapper"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                {" "}
                <Iconcard
                  icon="fa-solid fa-user-doctor"
                  title="Humanitarian Workers"
                  description="For field workers, volunteers, and distribution, we offers tools to simplify processes, reducing the burden and increasing the focus on human care."
                />
              </div>
              <div
                className="Card_wrapper"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <Iconcard
                  icon="fa-solid fa-microchip"
                  title="Technology Partners"
                  description="Collaborating with UnifyAid, technology partners find opportunities to integrate their solutions into a platform that's transforming humanitarian aid."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
