import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment";

function InboundRow(props) {
	// const { rows, handleCheck, index } = props;
	const { rows, Check, setCheck, index, handleCheck } = props;
	// const handleChange = (position) => {
	//   const updatedCheckedState = Check.map((item, index) =>
	//     index === position ? !item : item
	//   );
	//   setCheck(updatedCheckedState);
	// };
	return (
		<>
			<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} id={index}>
				<TableCell padding="checkbox">
					<Checkbox
						id={rows._id}
						color="primary"
						onChange={handleCheck}
						name={rows?.IndentData?._id}
						checked={rows?.isChecked || false}
					/>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">
							{/* {rows.SectorID?.Project?.District?.Label} */}
							{rows?.DistrictName}
						</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">{rows.IndentData?.ProjectID?.Label}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">{rows?.IndentData?.IndentRef}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">{rows?.cdpoName}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">{moment(rows?.createdAt).format("DD/MM/YYYY")}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span
							className={`btn-small btn-status cursor-none ${
								rows?.IndentData?.SupervisorApproved === true ? "btn-blue" : "btn-yellow"
							}`}
						>
							{rows?.IndentData?.SupervisorApproved === true && "Approved"}
							{rows?.IndentData?.SupervisorApproved === false && "Pending"}
						</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						{/* <Link className="link-none" to={{pathname :`/Co/ViewIndent/${rows._id}`}}> */}
						<Link className="link-none" to={{ pathname: `/Co/ViewIndent/${rows.IndentData?._id}` }}>
							<button className="btn btn-medium btn-blue-outline-hover">View</button>
						</Link>
					</div>
				</TableCell>
			</TableRow>
		</>
		// <>
		//   <TableRow
		//     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
		//     id={index}
		//   >
		//     <TableCell padding="checkbox">
		//       <Checkbox
		//         color="primary"
		//         checked={Check[index]}
		//         onChange={() => handleChange(index)}
		//       />
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">Secunderabad</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">Project Name</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">Addagutta</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">B97YC3798</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">Elizha Fisher</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span className="table-data-text">10/12/2021</span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <span
		//           className={`btn-small btn-status cursor-none ${
		//             rows.status === "APPROVED" ? "btn-blue" : "btn-yellow"
		//           }`}
		//         >
		//           {rows.status === "APPROVED" && "Approved"}
		//           {rows.status === "PENDING" && "Pending"}
		//         </span>
		//       </div>
		//     </TableCell>
		//     <TableCell>
		//       <div className="table-data-wrapper">
		//         <Link className="link-none" to="/Co/ViewIndent">
		//           <button className="btn btn-medium btn-blue-outline-hover">
		//             View
		//           </button>
		//         </Link>
		//       </div>
		//     </TableCell>
		//   </TableRow>
		// </>
	);
}

export default InboundRow;
