import React from "react";
import "../../../assets/styles/layouts/Layout.scss";
import Header from "../../../shared/header/Header.jsx";
import Sidebar from "../../../shared/sidebar/Sidebar.jsx";
import ViewIndent from "../../../components/cdpo/viewIndent/ViewIndent.jsx";

function ViewIndentContainer() {
  const [Open, setOpen] = React.useState(false);
  return (
    <div className="layout-container">
      <Header setOpen={setOpen} Open={Open} />
      <Sidebar Open={Open} />
      <ViewIndent />
    </div>
  );
}

export default ViewIndentContainer;
