import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb({ blog, contact }) {
  return (
    <>
      <section class="breadcrumb_section">
        <div class="mi_container">
          <section class="breadcrumb_link_container">
            <Link to="/" class="breadcrumb_links">
              <p class="breadcrumb_body_ts">Home</p>
            </Link>
            <div class="breadcrumb_divider">
              <p class="breadcrumb_body_ts">/</p>
            </div>
            {contact ? (
              <div class="breadcrumb_links breadcrumb_active">
                <p class="breadcrumb_body_ts">Contact Us</p>
              </div>
            ) : (
              <div class="breadcrumb_links breadcrumb_active">
                <p class="breadcrumb_body_ts">{blog.blogheading}</p>
              </div>
            )}
          </section>
        </div>
      </section>
    </>
  );
}
