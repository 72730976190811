import React from "react";
import "./HomeFooter.css";

import brandLogo from "../../../assets/icons/logo/unifyaid_logo_alt.svg";

export default function HomeFooter() {
  return (
    <React.Fragment>
      <footer className="HomeFooter__main_container">
        <div className="Page__main_container">
          <div className="HomeFooter__layout_wrapper">
            <figure className="HomeFooter__footer_brand">
              <img src={brandLogo} alt="brandLogo" />
            </figure>
            <p className="copyright_text">
              &copy; Powered by StaTwig 2023
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
