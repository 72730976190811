import React from "react";
import "./LoginForm.css";
import LoginComponent from "./Layouts/LoginComponent";
import ForgotPasswordComponent from "./Layouts/ForgotPasswordComponent";
import VerifyOtpComponent from "./Layouts/VerifyAccountComponent";
import ResetPasswordComponent from "./Layouts/ResetPasswordComponent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LoginForm({ onClose, Mode }) {
  const [Layout, setLayout] = React.useState("1");

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <section className="login_container">
      <div className="close_btn" onClick={onClose}>
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div className="form_grid_layout">
        <article className="form_banner">
          <div className="mi_img">
            <div className="bigtext_wrap">
              <h1 className="login_bigtitle_ts">Come,</h1>
              <h1 className="login_bigtitle_ts">Join Us !</h1>
            </div>
          </div>
        </article>
        <article className="form_system">
          {Layout === "1" && (
            <LoginComponent Mode={Mode} setLayout={setLayout} />
          )}
          {Layout === "2" && <ForgotPasswordComponent setLayout={setLayout} />}
          {Layout === "3" && <VerifyOtpComponent setLayout={setLayout} />}
          {Layout === "4" && (
            <ResetPasswordComponent
              setLayout={setLayout}
              handleClickAlert={handleClickAlert}
            />
          )}
        </article>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your Password has been Changed Successfully, Please Login
        </Alert>
      </Snackbar>
    </section>
  );
}
