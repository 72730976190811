import React from "react";
import "./Contactus.css";
import { Link } from "react-router-dom";

export default function Contactus() {
  return (
    <React.Fragment>
      <section className="Contactus__main_container section_space">
        <div className="Page__main_container">
          <div className="Contactus__layout_wrapper">
            <div className="Contactus__content_info">
              <h1
                className="SectionTitle__title_text white_color"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                Join the Revolution in Humanitarian Aid! Contact Us to Get
                Involved
              </h1>
              <div
                className="Contactus__menu_action_wrapper"
                data-aos="fade-in"
                data-aos-offset="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <Link to="/contact">
                  <button className="mi_btn mi_btn_medium mi_btn_primary">
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
