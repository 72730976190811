import React from "react";
import "./Partner.css";

import Partner1 from "../../../assets/icons/partners/client1.png";
import Partner2 from "../../../assets/icons/partners/client2.png";
import Partner3 from "../../../assets/icons/partners/client3.png";
import Partner4 from "../../../assets/icons/partners/client4.png";
import Partner5 from "../../../assets/icons/partners/client5.png";
import Partner6 from "../../../assets/icons/partners/client6.png";
import Partner7 from "../../../assets/icons/partners/client7.png";
import Partner8 from "../../../assets/icons/partners/client8.png";

export default function Partner() {
  return (
    <React.Fragment>
      <section className="Partner__main_container">
        <div className="Page__main_container">
          <div className="Partner__layout_wrapper">
            <div class="message_heading">
              <p class="section_tagline_ts">our Supporters</p>
              <div className="Showcase__line_border">
                <div className="line_vector"></div>
              </div>
            </div>
            <div class="client_list_first_row">
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="20"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner1} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="40"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner2} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="60"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner3} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="80"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner4} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner5} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="120"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner6} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="140"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner7} alt="client" />
              </div>
              <div
                class="client_wrap"
                data-aos="fade-up"
                data-aos-offset="160"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <img src={Partner8} alt="client" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
