import React, { useState } from "react";
import "../LoginForm.css";
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";

export default function ForgotPasswordComponent({ setLayout }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const[Loader, setLoader] = useState(false)

  const validateEmailPattern = (emailId) => {
    try {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailId) {
        if (emailId.match(emailRegex) === null) {
          return {
            error: true,
            message: "Email ID is invalid!",
          };
        }
      } else {
        return {
          error: true,
          message: "Please provide an Email ID!",
        };
      }
      return {
        error: false,
      };
    } catch (err) {
      throw err;
    }
  };

  const sendOTP = async (values) => {
    try {
      setLoader(true);
      const emailRes = validateEmailPattern(values.email);
      if (emailRes.error) {
        setError("email", {
          type: "custom",
          message: emailRes.message,
        });
        setLoader(false);
        return;
      } else {
        const payload = {
          email: values.email,
        };

        const userAdd = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/forgotPassword`,
          { params: payload }
        );

        if (userAdd.data.type == 0) {
          setError("email", {
            type: "custom",
            message: "Email does not exist.",
          });
          setLoader(false);
        } else {
          if (userAdd.data.type == -1) {
            setError("email", {
              type: "custom",
              message: "Error in generating OTP, please contact admin.",
            });
            setLoader(false);
          } else {
            localStorage.setItem("reset", JSON.stringify(payload));
            setLayout("3");
            setLoader(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form action="#" className="mi_login_form" onSubmit={handleSubmit(sendOTP)}>
      <div className="form_headline">
        <h1 className="form_headline_ts">Forgot Password</h1>
        <p className="form_subheadline_ts">
          Don't worry, we are here to help you
        </p>
      </div>

      <div className="form_input">
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">Email</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  type="text"
                  class="input_field"
                  {...field}
                  error={Boolean(errors.email)}
                  helperText={
                    errors.email?.type === "required"
                      ? "Email-ID is required!"
                      : errors.email?.message
                  }
                />
              )}
            />
               <p className="custom_error_text">{ errors.email?.type === "required"
                      ? "Email ID is required!"
                      : errors.email?.message}</p>
          </div>
        </div>
      </div>
      <div className="form_action">
        <button type="submit" className="form_submit_button">
        
          {Loader ? (<Box sx={{ display: 'flex',color: 'white.500'  }}>
        <CircularProgress size={30} color="inherit" />
        </Box>) : (<Box sx={{ display: 'flex'}}>
          <div className="button_text">
          Send OTP
          </div>
        </Box>)}
        </button>
      </div>
    </form>
  );
}
