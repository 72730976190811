import React from "react";
import "../LoginForm.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

export default function LoginComponent({ Mode, setLayout }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const validateEmailPattern = (emailId) => {
    try {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailId) {
        if (emailId.match(emailRegex) === null) {
          return {
            error: true,
            message: "Email ID is invalid!",
          };
        }
      } else {
        return {
          error: true,
          message: "Please provide an Email ID!",
        };
      }
      return {
        error: false,
      };
    } catch (err) {
      throw err;
    }
  };

  const handleLogin = async (values) => {
    try {
      // Validate email and password patterns
      const emailRes = validateEmailPattern(values.email);
      if (emailRes.error) {
        setError("email", {
          type: "custom",
          message: emailRes.message,
        });
        return;
      }
      if (!values.password?.length) {
        setError("password", {
          type: "custom",
          message: "Enter a password!",
        });
        return;
      }
      const payload = {
        email: values.email,
        password: values.password,
      };
      const userAdd = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/login`,
        { params: payload }
      );

      if (Mode === "user") {
        if (userAdd.data.status == 1 || userAdd.data.status == 2) {
          localStorage.setItem("user", JSON.stringify(userAdd.data));
          if (userAdd.data.type == 1) {
            //CO/user
            window.location = "/Co/IndentRequest";
          } else {
            if (userAdd.data.type == 2) {
              //Cdpo
              window.location = "/Cdpo/IndentRequest";
            } else {
              if (userAdd.data.type == 3) {
                //Supervisor
                window.location = "/Supervisor/IndentRequest";
              } else {
                setError("email", {
                  type: "custom",
                  message: "Please Check your Email or Password!",
                });
              }
            }
          }
        } else {
          if (userAdd.data.status == 2) {
            console.log("Invalid email or password");
          } else {
            setError("email", {
              type: "custom",
              message: "Please Check your Email or Password!",
            });
          }
        }
      } else {
        //status => 1-success, 2-new, 3-invalid
        if (userAdd.data.type == 1) {
          //CO/user
          console.log("Invalid email or password");
          localStorage.setItem("user", JSON.stringify(userAdd.data));
          window.location = `/admin/ManageUsers`;
        } else {
          setError("email", {
            type: "custom",
            message: "Please Check your Email or Password!",
          });
        }
      }
    } catch (err) {
      console.log("Error in login - ", err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)} className="mi_login_form">
      {Mode === "user" ? (
        <div className="form_headline">
          <h1 className="form_headline_ts">Welcome Back</h1>
          <p className="form_subheadline_ts">
            Please enter your details to login your account
          </p>
        </div>
      ) : (
        <div className="form_headline">
          <h1 className="form_headline_ts">Welcome Admin</h1>
          <p className="form_subheadline_ts">
            Please enter your details to login your account
          </p>
        </div>
      )}

      <div className="form_input">
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">Email</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  type="text"
                  class="input_field"
                  {...field}
                  error={Boolean(errors.email)}
                  helperText={
                    errors.email?.type === "required"
                      ? "Email-ID is required!"
                      : errors.email?.message
                  }
                />
              )}
            />
            <p className="custom_error_text">
              {errors.email?.type === "required" && "Email ID is required!"}
            </p>
          </div>
        </div>
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">Password</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="input_password">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="input_field password_field"
                    {...field}
                    error={Boolean(errors.password)}
                    helperText={errors.password && "Password is required!"}
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="password_visible_icon"
                  >
                    {showPassword ? (
                      <i class="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i class="fa-solid fa-eye"></i>
                    )}
                  </div>
                </div>
              )}
            />
            <p className="custom_error_text">
              {errors.password && "Password is required!"}
            </p>
          </div>
        </div>
        <div className="forgot_link">
          <div className="error_area">
            {errors.email?.message && <i class="fa-solid fa-circle-info"></i>}
            <p className="custom_error_text_alt">{errors.email?.message}</p>
          </div>
          <p className="forgot_message_ts" onClick={() => setLayout("2")}>
            Forgot Password ?
          </p>
        </div>
      </div>
      <div className="form_action">
        <button type="submit" className="form_submit_button">
          Login
        </button>
      </div>
    </form>
  );
}
