import React from "react";
import "./SectionTitle.css";

export default function SectionTitle({ tagline, title }) {
  return (
    <React.Fragment>
      <section className="SectionTitle__wrapper">
        <p
          className="SectionTitle__tagline_text"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {tagline}
        </p>
        <h1
          className="SectionTitle__title_text plain_color"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {title}
        </h1>
      </section>
    </React.Fragment>
  );
}
