export const blogcontent = [
  {
    id: 0,
    title:
      "Elevating Humanitarian Aid: UnifyAid at the WFP Innovation Pitch Event 2023",
    description:
      "In a groundbreaking moment for technology-powered humanitarian solutions, UnifyAid proudly stood among 16 select innovation teams from around the world at the WFP Humanitarian Innovation Pitch Event held in Luxembourg on June 29, 2023.",
    blogheading: "UnifyAid at the WFP Innovation Pitch Event 2023",
    bloginfo:
      "In a groundbreaking moment for technology-powered humanitarian solutions, UnifyAid proudly stood among 16 select innovation teams...",
    image: "blog1",
    date: "12 JULY, 2023",
  },
  {
    id: 1,
    title: "Introducing UnifyAid: How We're Making a Difference",
    description:
      "In the vast world of humanitarian aid, a new name has emerged, redefining the way assistance reaches those who need it most. This is our story.",
    blogheading: "Introducing UnifyAid: How We're Making a Difference",
    bloginfo:
      "In the vast world of humanitarian aid, a new name has emerged, redefining the way assistance reaches those who need it most. This is our story...",
    image: "blog2",
    date: "03 JULY, 2023",
  },
  // {
  //   id: 1,
  //   title:
  //     "Crafting Innovation: Lessons from UnifyAid's Unique Approaches in Humanitarian Aid",
  //   description:
  //     "At UnifyAid, we believe in the transformative power of innovation. The humanitarian sector, while age-old in its essence, is ever-evolving in its approach. Here, we shed light on our distinct methodologies and the lessons we've learned along our journey.",
  //   blogheading: "Lessons from UnifyAid's Approaches in Humanitarian Aid",
  //   bloginfo:
  //     "At UnifyAid, we believe in the transformative power of innovation. The humanitarian sector, while age-old in its essence, is ever-evolving in its approach...",
  //   image: "blog2",
  // },
  {
    id: 2,
    title:
      "Navigating Identity: The Evolving Landscape of Beneficiary Verification in Crisis Zones",
    description:
      "In a world where identity often dictates access to essential services and rights, establishing one's identity becomes paramount. For refugees, displaced individuals, and those living in crisis-ridden areas, this seemingly simple act can be riddled with challenges. How do we, at UnifyAid, navigate this complex maze of beneficiary verification, especially when conventional methods might not suffice?",
    blogheading: "The Evolving Landscape of Beneficiary Verification",
    bloginfo:
      "In a world where identity often dictates access to essential services and rights, establishing one's identity becomes paramount. For refugees...",
    image: "blog3",
    date: "05 JULY, 2023",
  },
];
