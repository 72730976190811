import React from "react";

export default function BlogHeader({ title, description, id }) {
  return (
    <React.Fragment>
      <section class="blog_headline">
        <div class="mi_container">
          <div class="blog_headline_wrapper">
            <h1 class="mi_headingLite blog_headline_ts max_width">{title}</h1>
          </div>
          <div class={`blog_headline_image b${id}`}></div>
        </div>
      </section>
    </React.Fragment>
  );
}
