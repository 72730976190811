import React from "react";
import Mission from "../../../assets/icons/images/mission.png";
import Solution from "../../../assets/icons/images/solutions.png";
import "./About.css";

export default function About({ aboutRef }) {
  return (
    <section className="About_container" ref={aboutRef}>
      <div className="mi_page_container">
        <div className="about_header">
        <div className="section_headline">
            <h1 className="mi_headingLite section_headline_ts">
              ABOUT UNIFYAID
            </h1>
          </div>
          <div className="about_headline_grid">
          <p className="column_body_ts">
          Welcome to UnifyAid, a revolutionary platform that leverages technology to optimize and streamline humanitarian aid delivery. We enhance transparency, efficiency, and accountability through targeted aid distribution and active engagement with beneficiaries.
          </p>
          <div className="about_img">
            <div className="about_img_holder">
              <h1 className="logo_ts">UNIFYAID</h1>
            </div>
          </div>
          </div>
         
          
        </div>

        <div className="about_inner_container">
          <div className="mi_two_column_md">
            <article className="column_image">
              <img src={Mission} alt="Mission" />
            </article>
            <article className="column_content_flex">
              <h1 className="column_heading_ts">Challenges in Humanitarian Aid Delivery</h1>
              <div className="paragraph_wrapper">
                <div className="about_point_List">
                <div className="about_point_card">
                    <div className="icon_holder">
                      <div className="black_dot"></div>
                    </div>
                    <div className="title_holder"> <h1 className="title_holder_ts">Not delivering to targeted beneficiaries</h1> </div>
                  </div>
                  <div className="about_point_card">
                    <div className="icon_holder">
                      <div className="black_dot"></div>
                    </div>
                    <div className="title_holder"> <h1 className="title_holder_ts">Lack of engagement</h1> </div>
                  </div>
                  <div className="about_point_card">
                    <div className="icon_holder">
                      <div className="black_dot"></div>
                    </div>
                    <div className="title_holder"> <h1 className="title_holder_ts">Aid Leakage</h1> </div>
                  </div>
                  <div className="about_point_card">
                    <div className="icon_holder">
                      <div className="black_dot"></div>
                    </div>
                    <div className="title_holder"> <h1 className="title_holder_ts">Pilferages</h1> </div>
                  </div>
                 
                </div>
              </div>
            </article>
          </div>
          {/* <div className="mi_two_column_md">
            <article className="column_image">
              <img src={Mission} alt="Mission" />
            </article>
            <article className="column_content">
              <h1 className="column_heading_ts">Our Mission</h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                  At UnifyAid, our mission is to empower humanitarian
                  organizations with innovative technology to overcome the
                  challenges in aid distribution.
                </p>
                <p className="column_body_ts">
                  We believe that by harnessing the power of data, automation,
                  and real-time visibility, we can create a more sustainable and
                  impactful approach to delivering aid to those in need.
                </p>
              </div>
            </article>
          </div> */}
          <div className="mi_two_column_xl mi_align_center">
            <article className="column_content_alt">
              <h1 className="column_heading_ts">Our Solution</h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                UnifyAid offers a comprehensive suite of tools and services designed to revolutionize humanitarian aid delivery.
                </p>
                <p className="column_body_ts">
                Our mobile application and web dashboard are meticulously crafted to enhance visibility in the crucial last mile of aid distribution. Our platform serves as a vital tool for humanitarian organizations, governments, banks, and NGOs.
                </p>
                <p className="column_body_ts">
                Through the utilization of advanced analytics and real-time insights, organizations can make well-informed decisions, optimize resources, and significantly enhance the effectiveness of their aid programs. 
                </p>
              </div>
            </article>
            <article className="column_image">
              <img src={Solution} alt="Mission" />
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
