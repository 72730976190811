import React, { useState, useEffect } from "react";
import "../../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import ProjectIndentRow from "./ProjectIndentRow";
import Popup from "../../../../../common/Popup/Popup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import axios from "axios"
import moment from "moment"

function ProjectIndent() {

  const [projectid, setProjectid] = useState("");
  const [indent, setIndent] = useState([])
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    // getSectorIndentRequest();
    let sec = JSON.parse(localStorage.getItem("user"))
    setProjectid((sec.user.Project))
    console.log("****************************************", sec)
  }, [])

  const getIndent = async () => {
    let sec = JSON.parse(localStorage.getItem("user"))
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/cdpos/project-indent?projectid=` + sec.user.Project)
      setIndent(res.data)
      console.log("indent data", res);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    getIndent()
  }, [])
  // Dropdown Button Style
  const CustomStatus = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="table-head-wrapper cursorP"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      id="dropdown-basic"
    >
      <span className="table-heading-text">{children}</span>
    </div>
  ));

  const CustomMenuStatus = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const Data = [
    {
      id: "1",
      status: "APPROVED",
    },
    {
      id: "2",
      status: "PENDING",
    },
    {
      id: "3",
      status: "APPROVED",
    },
    {
      id: "4",
      status: "PENDING",
    },
    {
      id: "5",
      status: "APPROVED",
    },
    {
      id: "6",
      status: "APPROVED",
    },
    {
      id: "7",
      status: "APPROVED",
    },
    {
      id: "8",
      status: "PENDING",
    },
    {
      id: "9",
      status: "PENDING",
    },
    {
      id: "10",
      status: "APPROVED",
    },
  ];

  return (
    <>
      <TableContainer
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
          borderRadius: "0.4rem",
          background: "#fff",
        }}
      >
        <Table sx={{ minWidth: 1098 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">District</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Project</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Indent ID</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Month</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Total Beneficiary</span>
                </div>
              </TableCell>
              <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomStatus}
                    id="dropdown-custom-components"
                  >
                    Status
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenuStatus} align="end">
                    <Dropdown.Item>
                      <button className="btn drop-btn">Approved</button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="btn drop-btn">Pending</button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indent && indent.map((rows, index) => (
              <ProjectIndentRow key={index} index={index} rows={rows} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <DialogContentText>
            <Popup setOpenModal={setOpenModal} indentId={"463"} redirectLink="/Cdpo/IndentRequest" text="Consolidate requests" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProjectIndent;
