import React from "react";
import { Routes } from "react-router-dom";
import routes from "../routes/Route.jsx";
import Webdefaults from "../webdefaults.js";

function App() {
  return (
    <>
      <Webdefaults />
      <Routes>{routes}</Routes>
    </>
  );
}

export default App;
