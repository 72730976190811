import React from "react";
import Feature1 from "../../../assets/icons/images/feature1.png";
import Feature2 from "../../../assets/icons/images/feature2.png";
import Feature3 from "../../../assets/icons/images/feature3.png";
import Feature4 from "../../../assets/icons/images/feature4.png";
import "./Features.css";

export default function Features({ featureRef }) {
  return (
    <section className="Features_container" ref={featureRef}>
      <div className="mi_page_container">
        <div className="about_header">
          <div className="section_headline">
            <h1 className="mi_headingLite section_headline_ts">KEY FEATURES</h1>
          </div>
        </div>

        <div className="features_inner_container">
          <div className="mi_two_column_xl mi_align_center">
            <article className="column_image">
              <img src={Feature1} alt="Mission" />
            </article>
            <article className="column_content">
              <h1 className="column_heading_ts">
              Reliable method to record proof of deliveries
              </h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                Recording the proof of delivery is an extremely seamless process with UnifyAid. The delivery person and the beneficiary can easily record the proof of delivery using their mobile phones. They have the option to scan the QR code on the package or capture a picture. 
                </p>
                <p className="column_body_ts">
                This ensures a reliable record of the delivery. With our user-friendly technology, organizations can streamline their delivery operations and enhance transparency in aid distribution.
                </p>
              </div>
            </article>
          </div>
          <div className="mi_two_column_xl alternative mi_align_center">
            <article className="column_content">
              <h1 className="column_heading_ts">
              Actively Engaging with the beneficiaries
              </h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                The one thing we found missing in the the last mile aid delivery system is that the end beneficiaries do not have a say in what is going on
                </p>
                <p className="column_body_ts">
                We do not know if they are getting Aid in time or if they are receiving the aid that is currently needed. The quality of the aid delivered is not monitored either. Our application has an inbuilt feedback mechanism that ensures that the beneficiaries voice is taken into account.

                </p>
              </div>
            </article>
            <article className="column_image">
              <img src={Feature2} alt="Mission" />
            </article>
          </div>
          {/* <div className="mi_two_column_xl mi_align_center">
            <article className="column_image">
              <img src={Feature3} alt="Mission" />
            </article>
            <article className="column_content">
              <h1 className="column_heading_ts">
                Real-time Monitoring and Reporting
              </h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                  With UnifyAid, organizations can monitor aid distribution in
                  real-time, accessing valuable insights and analytics at their
                  fingertips.
                </p>
                <p className="column_body_ts">
                  Our intuitive dashboard provides comprehensive visibility into
                  inventory levels, distribution progress, and beneficiary data.
                  This enables proactive decision-making, rapid response to
                  emergencies, and effective resource allocation.
                </p>
              </div>
            </article>
          </div> */}
          <div className="mi_two_column_xl mi_align_center">
          <article className="column_image">
              <img src={Feature1} alt="Mission" />
            </article>
            <article className="column_content">
              <h1 className="column_heading_ts">
                Unique Business Model Driven by Incentives
              </h1>
              <div className="paragraph_wrapper">
                <p className="column_body_ts">
                  Our solution fosters collaboration by offering innovative
                  incentives that promote stakeholder cooperation, data
                  collection, and active participation.
                </p>
                <p className="column_body_ts">
                  Stakeholders earn rewards redeemable for monetary value or
                  discounts, motivating their involvement and enhancing
                  distribution network efficiency.
                </p>
              </div>
            </article>
            
          </div>
        </div>
      </div>
    </section>
  );
}
