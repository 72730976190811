import React from "react";
import UserAdmin from "../../../assets/icons/user-admin.png";
import UserOthers from "../../../assets/icons/user-others.png";

function Selection(props) {
  const { Role, setRole, setStep } = props;
  return (
    <div className='user-selection-container'>
      <div className='select-text-group'>
        <span className='homepage-heading-text-sm'>Select your role</span>
      </div>
      <div className='selection-field'>
        <div className='select-role-wrapper'>
          <div
            className={`role admin-role ${Role === "ADMIN" && "role-active"}`}
            onClick={() =>
              window.open(process.env.REACT_APP_ADMIN_URL, "_self")
            }
            // {() => {
            //   setRole("ADMIN");
            //   setStep(2);
            // }}
          >
            <img src={UserAdmin} className='login-user-icons' alt='Admin' />
            {/* <i class={`bx bx-support icon-role ${ Role === "ADMIN" && " role-icon-active" }`} ></i> */}
            <span
              className={`role-text ${Role === "ADMIN" && " role-icon-active"}`}
            >
              {" "}
              Admin{" "}
            </span>
          </div>
          <div
            className={`role user-role ${Role === "USER" && "role-active"}`}
            onClick={() => window.open("/Login", "_self")}
            // onClick={() => {
            //   setRole("USER");
            //   setStep(2);
            // }}
          >
            {/* <i
              class={`bx bx-user icon-role ${
                Role === "USER" && " role-icon-active"
              }`}
            ></i> */}
            <img src={UserOthers} className='login-user-icons' alt='User' />
            <span
              className={`role-text ${Role === "USER" && " role-icon-active"}`}
            >
              User
            </span>
          </div>
        </div>
      </div>
      {/* <button
        disabled={!Role ? true : false}
        className={`btn btn-blue btn-homepage ${!Role && "btn-hidden"}`}
        onClick={() => setStep(2)}
      >
        Continue
      </button> */}
    </div>
  );
}

export default Selection;
