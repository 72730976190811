import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/indent/Indent.scss";
import "../../../assets/styles/input/Input.scss";
import "../../../assets/styles/inventory/Inventory.scss";
import InventoryHistoryDetail from "./inventoryHistoryDetails/InventoryHistoryDetail";
import InventoryHistoryTable from "./inventoryhistoryTable/InventoryHistoryTable";

function InventoryHistory() {
  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">INVENTORY HISTORY</h1>
        <Link className="link-none" to="/Co/Inventory">
        <button className="btn-alt btn-link-view">
          <i class="bx bx-arrow-back icons-btn"></i> Back to Inventory
        </button>
          </Link>
      </div>
      <div className="user-table-grid-card">
        <InventoryHistoryDetail />
      </div>
      <div className="user-table-content">
        <InventoryHistoryTable />
      </div>
    </div>
  );
}

export default InventoryHistory;
