import React, { useState, useEffect } from "react";
import "../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CenterDetailsRow from "./CenterDetailsRow";
import axios from "axios";
import moment from "moment";
// import { useState, useEffect } from "react";

function CenterDetailsTable() {
	const [indent, setIndent] = useState([]);
	const getIndent = async () => {
		try {
			let sec = JSON.parse(localStorage.getItem("user"));
			sec = sec.user.Sector;
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/supervisors/getAnganwadiIndents?sectorId=` +
					sec +
					`&month=` +
					moment().format("MM") +
					`&year=` +
					moment().format("YYYY"),
			);
			setIndent(res.data);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getIndent();
	}, []);

	const Data = [
		{
			id: "1",
			status: "APPROVED",
		},
	];

	return (
		<TableContainer
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
				borderRadius: "0.4rem",
				background: "#fff",
				maxHeight: 460,
			}}
		>
			<Table stickyHeader sx={{ minWidth: 1098 }} aria-label="sticky table">
				<TableHead stickyHeader>
					<TableRow>
						<TableCell
							align="center"
							colSpan={5}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						></TableCell>
						<TableCell
							align="center"
							colSpan={5}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Beneficiary</span>
							</div>
						</TableCell>
						<TableCell align="center" colSpan={8}>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Commodity</span>
							</div>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">District</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Project</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Sector</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Anganwadi</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text w-5">AWW Name</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								padding: "0px 16px",
								borderRight: "1px solid #ddd !important",
							}}
							align="center"
						>
							<div className="table-head-wrapper-xl">
								<p className="table-heading-text-sm">Pregnant & Lactating women</p>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								padding: "0px 16px",
								borderRight: "1px solid #ddd !important",
							}}
							align="center"
						>
							<div className="table-head-wrapper-xl">
								<p className="table-heading-text-sm w-4">Normal Children (7m to 3yrs)</p>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								padding: "0px 16px",
								borderRight: "1px solid #ddd !important",
							}}
							align="center"
						>
							<div className="table-head-wrapper-xl">
								<p className="table-heading-text-sm w-4">Supervised Feeding (7m to 3yrs)</p>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								padding: "0px 16px",
								borderRight: "1px solid #ddd !important",
							}}
							align="center"
						>
							<div className="table-head-wrapper-xl">
								<p className="table-heading-text-sm w-55">Normal Pre-School Children (3 - 6 yrs)</p>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								padding: "0px 16px",
								borderRight: "1px solid #ddd !important",
							}}
							align="center"
						>
							<div className="table-head-wrapper-xl">
								<p className="table-heading-text-sm w-35">Supervised Feeding (3 - 6 yrs)</p>
							</div>
						</TableCell>
						{/* <TableCell
              style={{ top: 57 }}
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Rice</span>
              </div>
            </TableCell>
            <TableCell
              style={{ top: 57 }}
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Dal</span>
              </div>
            </TableCell>
            <TableCell
              style={{ top: 57 }}
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Oil</span>
              </div>
            </TableCell>
            <TableCell
              style={{ top: 57 }}
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Milk</span>
              </div>
            </TableCell>
            <TableCell
              style={{ top: 57 }}
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Egg</span>
              </div>
            </TableCell> */}
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Balamurtham</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Murukulu</span>
							</div>
						</TableCell>
						<TableCell
							style={{ top: 57 }}
							sx={{
								borderRight: "1px solid #ddd !important",
							}}
						>
							<div className="table-head-wrapper-xl">
								<span className="table-heading-text">Oil</span>
							</div>
						</TableCell>
						{/* <TableCell
              style={{ top: 57 }}
              sx={{
                padding: "0px 16px",
              }}
              align="right"
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Delivery</span>
                <span className="table-subheading-text">Date & Time</span>
              </div>
            </TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
					{/* {Data.map((rows, index) => (
            <CenterDetailsRow key={index} index={index} rows={rows} />
          ))} */}
					{indent &&
						indent.map((rows, index) => <CenterDetailsRow key={index} index={index} rows={rows} />)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default CenterDetailsTable;
