import React from "react";
import "../../../assets/styles/layouts/Layout.scss";
import Header from "../../../shared/header/Header.jsx";
import Sidebar from "../../../shared/sidebar/Sidebar.jsx";
import Inventory from "../../../components/co/inventoryReport/RInventory";

function InventoryContainer() {
  const [Open, setOpen] = React.useState(false);
  return (
    <div className="layout-container">
      <Header setOpen={setOpen} Open={Open} />
      <Sidebar Open={Open} />
      <Inventory />
    </div>
  );
}

export default InventoryContainer;
