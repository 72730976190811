import React from "react";
import "./Site_footer.css";
import Statwig from "../../../assets/icons/logo/statwig-logo.png";
import googlestore from "../../../assets/icons/social/googlestore.png";
import appstore from "../../../assets/icons/social/appstore.png";
import { Link } from "react-router-dom";

export default function Site_footer({ contactRef }) {
  return (
    <>
      <div className="footer-section section-space bg-blue" ref={contactRef}>
        <div className="mi_page_container">
          <div className="footer-four-column-layout">
            <div className="powered-column">
              <h1 className="footer_heading_ts">Download Our Apps</h1>
              <div className="footer-content-area">
                {/* <figure className="company-logo">
                  <img
                    src={Statwig}
                    alt="statwig"
                    className="company-logo-image"
                  />
                </figure> */}
                <figure className="mobile-app-stores">
                  <a
                    href="https://apps.apple.com/us/app/unifyaid/id1601818184"
                    target="_blank"
                  >
                    <img
                      src={appstore}
                      alt="store"
                      className="mobile-store-icons"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.statwig.wcw"
                    target="_blank"
                  >
                    <img
                      src={googlestore}
                      alt="store"
                      className="mobile-store-icons"
                    />
                  </a>
                </figure>
              </div>
            </div>
            <div className="address-column">
              <h1 className="footer_heading_ts">Office Address</h1>
              <div className="footer-content-area">
                <div className="address-card">
                  <p className="footer_subheading_ts">India</p>
                  <article className="full-address">
                    <p className="footer_body_ts">
                      T-Hub, 2.0, Silpa Gram Craft Village, Gachibowli,
                      Hyderabad, Telangana,
                    </p>
                    <p className="footer_body_ts">India – 500032</p>
                  </article>
                </div>
                <div className="address-card">
                  <p className="footer_subheading_ts">Singapore</p>
                  <article className="full-address">
                    <p className="footer_body_ts">3 Fraser Street #05-25,</p>
                    <p className="footer_body_ts">
                      Duo Tower, Singapore - 189352
                    </p>
                  </article>
                </div>
              </div>
            </div>
            <div className="contact-column">
              <h1 className="footer_heading_ts">Contact Info</h1>
              <div className="footer-content-area">
                <section className="contact-group">
                  <article className="contact-card">
                    <div className="contact-heading">
                      <i className="fa-solid fa-phone vl-grey-sm vl-icon-xs"></i>
                      <p className="footer_body_ts">Phone :</p>
                    </div>
                    <p className="footer_body_ts">(+91) 939 059 0319</p>
                  </article>
                  <article className="contact-card">
                    <div className="contact-heading">
                      <i className="fa-solid fa-envelope vl-grey-sm vl-icon-xs"></i>
                      <p className="footer_body_ts">Email :</p>
                    </div>
                    <p className="footer_body_ts">contactus@statwig.com</p>
                  </article>
                </section>
              </div>
              <h1 className="footer_heading_ts">Help Center</h1>
              <div className="footer-content-area">
                <section className="contact-group">
                  <p className="footer_body_ts">FAQ</p>
                  <p className="footer_body_ts">Terms & Conditions</p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="copy-section bg-blue">
        <div className="vl-page-container">
          <article className="copy-rights ">
            <p className="footer_body_ts">
              © 2023 StaTwig. All rights reserved.
            </p>
          </article>
        </div>
      </section>
    </>
  );
}
