import React, { useEffect } from "react";
// import "./LandingPage.scss";
// import Selection from "./commons/Selection.jsx";
// import Brandlogo from "../../assets/icons/wcwledgerlogo.png";
// import Brandlogo from "../../assets/icons/svgs/Brandlogo.svg";
// import Login from "./commons/Login.jsx";
// import Background from "../../assets/icons/backgrounds/background.png";

function LogoutPage() {
  useEffect(() => {
    localStorage.clear();
    window.location.href = '/';
  });
  return (
    <div>
    </div>
  );
}

export default LogoutPage;