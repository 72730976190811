import React, { useState, useEffect } from "react";
import "../../../assets/styles/page/Page.scss";
import Inbound from "./Tables/inbound/Inbound.jsx";
import Outbound from "./Tables/outbound/Outbound.jsx";
import moment from "moment";
import Popup from "../../../common/Popup/Popup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import axios from "axios";

function IndentRequest() {
	const [openModal, setOpenModal] = useState(false);
	const [Tab, setTab] = useState(1);
	const [dateVal, setDateVal] = useState(moment().format("YYYY-MM"));
	const [consolidateTrue, setConsolidateTrue] = useState(true);

	const handleDateChange = (e) => {
		console.log("month", e.target.value);
		setDateVal(e.target.value);
	};
	const consolidateAll = async () => {
		try {
			// let sec = JSON.parse(localStorage.getItem("user"))
			// sec = sec.user.Sector;
			let dt = dateVal.split("-");
			const data = {
				month: dt[1],
				year: dt[0],
			};
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/coindent`, data);
			setOpenModal(true);
		} catch (error) {
			console.log(error);
		}
		// setOpenModal(true)
		// setTab(2);
	};
	const checkConsolidate = async () => {
		try {
			let dt = dateVal.split("-");
			const data = {
				month: dt[1],
				year: dt[0],
			};
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/coindent`, { params: data });
			console.log("CONSOLIDATED: ", res.data);
			if (res.data.length > 0) setConsolidateTrue(!true);
			// setIndent(res.data)
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		// console.log(localStorage.getItem("user"))
		// let sec = JSON.parse(localStorage.getItem("user"))
		// setProjectid((sec.user.Project))
		// console.log("****************************************", sec)
		checkConsolidate();
		setTab(1);
		// checkIfConsolidated();
	}, [dateVal]);
	checkConsolidate();

	return (
		<div id="page-container">
			<div className="users-page-header">
				<h1 className="page-heading-text">INDENT REQUEST</h1>
				{/* {Tab === 2 && (
          <div className="page-heading-btn-group">
            <span className="indent-card-data-text-md indent-flex-center">
              March, 2022 <i class="bx bxs-calendar icons-class"></i>
            </span>
          </div>
        )} */}
			</div>
			<div className="page-tab-group">
				<ul className="tab-list">
					<li className={`${Tab === 1 ? "tab-item-active" : "tab-item"}`} onClick={() => setTab(1)}>
						<span className={`${Tab === 1 ? "tab-link-text-active" : "tab-link-text"}`}>
							Inbound
						</span>
					</li>
					<li className={`${Tab === 2 ? "tab-item-active" : "tab-item"}`} onClick={() => setTab(2)}>
						<span className={`${Tab === 2 ? "tab-link-text-active" : "tab-link-text"}`}>
							Outbound
						</span>
					</li>
				</ul>
				<div className="page-heading-btn-group">
					<span className="indent-card-data-text-md indent-flex-center">
						<input type="month" value={dateVal} onChange={handleDateChange} />
					</span>
					{Tab === 1 && (
						<button
							className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"}
							disabled={!consolidateTrue}
							onClick={consolidateAll}
						>
							Consolidate Indents
						</button>
					)}
					{/* {consolidateTrue === true ? <Link to={{pathname :`/Cdpo/ProjectIndent/61f78ebef784779d146462e8`}}>  
         
                <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"}>
                Consolidate Indents
                </button>
            </Link> :  <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"} disabled={consolidateTrue}>
                Consolidate Indents
                </button>} */}
				</div>
			</div>
			<div className="user-table-contents">
				{Tab === 1 && (
					<Inbound
						dateVal={dateVal}
						handleDateChange={handleDateChange}
						setConsolidateTrue={setConsolidateTrue}
						consolidateTrue={consolidateTrue}
					/>
				)}
				{Tab === 2 && <Outbound dateVal={dateVal} />}
			</div>
			<Dialog open={openModal} onClose={() => setOpenModal(false)}>
				<DialogContent>
					<DialogContentText>
						<Popup
							setOpenModal={setOpenModal}
							indentId={"463"}
							redirectLink="/Co/IndentRequest"
							text="Consolidate requests"
						/>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default IndentRequest;
