import React from "react";
import ForgotPassword from "../../components/homePage/ForgotPassword";

function ForgotPasswordContainer() {
  return (
    <div className="home-container">
      <ForgotPassword />
    </div>
  );
}

export default ForgotPasswordContainer;
