import React, { useState } from "react";
import "./BlogPost.css";
import BlogHeader from "./blogComponents/BlogHeader";
import BlogBody from "./blogComponents/BlogBody";
import RelatedBlog from "./blogComponents/RelatedBlog";
import HomeHeader from "../home_header/HomeHeader";
import HomeFooter from "../home_footer/HomeFooter";
import Breadcrumb from "./blogComponents/Breadcrumb";
import { blogcontent } from "./data/blogcontent";
import { useParams } from "react-router-dom";

export default function BlogPost() {
  const BlogIndex = useParams();

  return (
    <React.Fragment>
      <HomeHeader />
      <div className="BlogPost__main_container section_bottom_space">
        <div className="Page__main_container">
          <Breadcrumb blog={blogcontent[BlogIndex.id]} />
          <div className="BlogPost__layout_wrapper">
            <BlogHeader
              id={blogcontent[BlogIndex.id].id}
              title={blogcontent[BlogIndex.id].title}
              description={blogcontent[BlogIndex.id].description}
            />
            <div className="BlogPost__two_column_layout">
              <BlogBody blog={blogcontent[BlogIndex.id]} />
              <RelatedBlog />
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </React.Fragment>
  );
}
