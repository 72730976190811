import React from "react";
import "./Countercard.css";

export default function Countercard({ count, unit, title, description }) {
  return (
    <React.Fragment>
      <div className="Countercard__wrapper">
        <div className="Countercard__count">
          <h1 className="Countercard__count_text">{count}</h1>
          <p className="Countercard__unit_text">{unit}</p>
        </div>
        <div className="Countercard__info">
          <p className="Iconcard__message_text">{description}</p>
          <h2 className="Iconcard__title_text">{title}</h2>
        </div>
      </div>
    </React.Fragment>
  );
}
