import React, { useState, useEffect } from "react";
import "../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import CodeIcon from "@mui/icons-material/Code";
import ShipmentRow from "./ShipmentRow";
import axios from "axios"

function ShipmentTable() {

  const [shipment, setShipment] = useState(null)

  const getIndentView = async () => {
    try {
      // var sec = JSON.parse(localStorage.getItem("user"))
      let data = { CdpoID: -1 };
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/cdpos/getShipmentByCdpo/`, { params: data })
      console.log("view indent", res.data)
      setShipment(res.data.finalData)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getIndentView()
  }, []);

  // Dropdown Button Style
  const CustomFilter = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="table-head-wrapper-xl table-flex-center cursorP"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      id="dropdown-basic"
    >
      <span className="table-heading-text">{children}</span>
      <CodeIcon className="filter-icon" />
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <div className="menu-list">
            <FormControl
              autoFocus
              className="filter-search filter-none"
              placeholder="Search"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <ul className="list-unstyled dropdown-list">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        </div>
      );
    }
  );

  const Data = [
    {
      id: "1",
      status: "APPROVED",
    },
    // {
    //   id: "2",
    //   status: "PENDING",
    // },
    // {
    //   id: "3",
    //   status: "APPROVED",
    // },
    // {
    //   id: "4",
    //   status: "PENDING",
    // },
    // {
    //   id: "5",
    //   status: "APPROVED",
    // },
    // {
    //   id: "6",
    //   status: "APPROVED",
    // },
    // {
    //   id: "7",
    //   status: "APPROVED",
    // },
    // {
    //   id: "8",
    //   status: "PENDING",
    // },
    // {
    //   id: "9",
    //   status: "PENDING",
    // },
    // {
    //   id: "10",
    //   status: "APPROVED",
    // },
  ];

  return (
    <TableContainer
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
        borderRadius: "0.4rem",
        background: "#fff",
      }}
    >
      <Table sx={{ minWidth: 1098 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomFilter}
                  id="dropdown-custom-components"
                >
                  Project
                </Dropdown.Toggle>

                {/* <Dropdown.Menu as={CustomMenu} align="start">
                  <Dropdown.Item className="filter-option-text">
                    District
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    District 1
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    District 2
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    District 3
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    District 4
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    District 5
                  </Dropdown.Item>
                </Dropdown.Menu> */}
              </Dropdown>
            </TableCell>
            <TableCell
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomFilter}
                  id="dropdown-custom-components"
                >
                  Sector
                </Dropdown.Toggle>

                {/* <Dropdown.Menu as={CustomMenu} align="start">
                  <Dropdown.Item className="filter-option-text">
                    Sector
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    1 Sector
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    2 Sector
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    3 Sector
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    4 Sector
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    5 Sector
                  </Dropdown.Item>
                </Dropdown.Menu> */}
              </Dropdown>
            </TableCell>
            <TableCell
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomFilter}
                  id="dropdown-custom-components"
                >
                  Anganwadi
                </Dropdown.Toggle>

                {/* <Dropdown.Menu as={CustomMenu} align="start">
                  <Dropdown.Item className="filter-option-text">
                    Anganwadi
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    1 Anganwadi
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    2 Anganwadi
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    3 Anganwadi
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    4 Anganwadi
                  </Dropdown.Item>
                  <Dropdown.Item className="filter-option-text">
                    5 Anganwadi
                  </Dropdown.Item>
                </Dropdown.Menu> */}
              </Dropdown>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Balamrutham</span>
                <span className="table-subheading-text">(in KG)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Murukulu</span>
                <span className="table-subheading-text">(in KG)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Oil</span>
                <span className="table-subheading-text">(in KG)</span>
              </div>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {Data.map((rows, index) => (
            <ShipmentRow key={index} index={index} rows={rows} />
          ))} */}
          {shipment && shipment.map((rows, index) => (
            <ShipmentRow key={index} index={index} rows={rows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ShipmentTable;
