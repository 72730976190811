import React from "react";
import "./Showcase.css";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import LoginForm from "../LoginForm/LoginForm";

export default function Showcase() {
  const [Mode, setMode] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClickOpen = (value) => {
    setOpen(true);
    console.log(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <section className="showcase_container">
        <div className="mi_page_container">
          <div className="showcase_inner_wrapper">
            <div className="showcase_content ">
              <h1 className="mi_title showcase_title_ts">
                Driving measurable impact in last-mile aid delivery
              </h1>
              <div className="showcase_mobile_btns">
                <div className="header_action">
                  <button
                    className="mi_btn mi_btn_md mi_btn_primary"
                    onClick={() => {
                      setOpen(true);
                      setMode("user");
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="mi_btn mi_btn_md mi_btn_secondary"
                    onClick={() => {
                      setOpen(true);
                      setMode("admin");
                    }}
                  >
                    Admin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent sx={{ padding: "1rem !important" }}>
          <LoginForm onClose={handleClose} Mode={Mode} />
        </DialogContent>
      </Dialog>
    </>
  );
}
