import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

function InventoryRow(props) {
  const { rows, keys, index } = props;
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Project}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Sector}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper table-flex-column">
            <span className="table-data-text text-pink">{rows?.Center}</span>
            <span className="table-data-sub-text">{rows?.Code}</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Bala/1000}</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Muru/1000}</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Oil/1000}</span>
          </div>
        </TableCell>
        
        <TableCell>
          {/* <Link className="link-none" to="/Cdpo/InventoryHistory">
            <button className="btn btn-medium btn-blue-outline-hover">
              View
            </button>
          </Link> */}
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryRow;
