import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "../../../assets/styles/indent/Indent.scss";
import Popup from "../../../common/Popup/Popup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ViewConsolidate() {
  let { sectorId } = useParams();
  const [sectorRes, setSectorRes] = useState({})
  useEffect(() => {
    getSectorIndentRequest()
  }, [])
  const getSectorIndentRequest = async () => {
    try {
      let sec = JSON.parse(localStorage.getItem("user"))
      sec = sec.user.Sector;
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/supervisors/consolidate`, {
        params: {
          // sectorId: "61f82e8d17cca790581fdf7b",
          sectorId: sec,
          month: moment().format('MM'),
          year: moment().format("YYYY")
        }
      });
      console.log(res.data)
      setSectorRes(res.data)
    } catch (error) {
      console.log(error);
    }
  }
  const [openModal, setOpenModal] = useState(false);

  const handleSectorIndentApprove = async () => {
    try {
      let sec = JSON.parse(localStorage.getItem("user"))
      let usr = sec.user._id
      sec = sec.user.Sector;
      const data = {
        beneficaryData: sectorRes.beneficaryData,
        commodityData: sectorRes.commodityData,
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        sectorId: sec,//"61f82e8d17cca790581fdf7b",
        IndentRef: "",
        userId:usr,
        centerId: ""//,"6214cf59fc12a069b7de760d"
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/supervisors/saveSupervisorIndent`, data)
      setOpenModal(true)
    } catch (error) {
      console.log(error);
    }
  }

  let indentDetail

  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">SECTOR INDENT REQUEST</h1>
        <Link className="link-none" to="/Supervisor/IndentRequest">
          <button className="btn-alt btn-link-view">
            <i class="bx bx-arrow-back icons-btn"></i> Back to Indent Request
          </button>
        </Link>
      </div>
      <div className="indent-main-grid-container">
        <div className="indent-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Indent Details
          </span>
          <div className="indent-grid-card">
            {/* {sectorRes.isSectorIndentView === true &&
              <div className="indent-data-group-grid mb-large">
                <span className="indent-card-text">Indent ID</span>
                <div className="id-status-wrapper">
                  <span className="indent-card-text-md-extrabold"></span>
                  <span className="btn-small btn-blue btn-status cursor-none">Approved</span>
                </div>
              </div>
            } */}
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Date</span>
              <span className="indent-card-text-md-bold">{moment().format('DD-MM-YYYY')}</span>
            </div>
            {/* <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Anaganwadi Name</span>
              <span className="indent-card-text-md-extrabold">
                A.C.S Nagar - 1
              </span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Anaganwadi</span>
              <span className="indent-card-text-md-bold">1602011</span>
            </div> */}
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">District</span>
              <span className="indent-card-text-md-bold">{sectorRes.projectStaticData?.District?.Label}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Project</span>
              <span className="indent-card-text-md-extrabold">{sectorRes.projectStaticData?.Label}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Sector</span>
              <span className="indent-card-text-md-bold">{sectorRes.sectorStaticData?.Label}</span>
            </div>
          </div>
          <Link className="link-none" to="/Supervisor/CenterDetails">
            <button className="btn btn-blue-outline btn-center-link">
              View Anganwadi Indent
            </button>
          </Link>
        </div>
        <div className="beneficiary-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Beneficiary Details
          </span>
          <div className="indent-grid-card">
            <div className="indent-grid-card-body">
              <div className="indent-data-group">
                <span className="indent-card-text">Month</span>
                <span className="indent-card-data-text indent-flex-center">
                  {moment().format('MMMM YYYY')} <i class="bx bxs-calendar icons-class"></i>
                </span>
              </div>
              <div className="indent-data-group">
                <span className="indent-card-heading-text">Beneficiary</span>
                <span className="indent-card-heading-text">
                  No of Beneficiary
                </span>
              </div>
              {sectorRes?.beneficaryData && sectorRes.beneficaryData.map((benefiar, index) => (
                <div className="indent-data-group" key={index}>
                  <div className="indent-text-wrapper">
                    <span className="indent-card-text">
                      {benefiar.name}
                    </span>
                  </div>
                  <input
                    disabled={true}
                    type="text"
                    className={`indent-card-text-bold indent-edit-text`}
                    value={benefiar.qty}
                  />
                </div>
              ))}

            </div>
            <div className="indent-grid-card-footer">
              <div className="indent-data-group">
                <span className="indent-card-heading-text">
                  Total Beneficiary
                </span>
                <span className="indent-card-total-text">{sectorRes?.beneficaryTotal}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="commodity-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Commodity Details
          </span>
          <div className="indent-grid-card">
            <div className="indent-data-group mb-large">
              <span className="indent-card-heading-text">Commodity</span>
              <span className="indent-card-heading-text">Total Quantity</span>
            </div>
            {sectorRes.commodityData && sectorRes.commodityData.map((comm, index) => (
              <div key={index} className="indent-data-group mb-large">
                <span className="indent-card-text-md">{comm.name}</span>
                <span className="indent-card-text-md-bold">{comm.qty / 1000} KG
                  {/* {comm.unit} */}
                </span>
              </div>
            ))}

          </div>
        </div>
      </div>

      {sectorRes.isSectorIndentView === false &&
        <div className="button-group-container">
          <div className="button-group">
            <Link to="/Supervisor/IndentRequest">
              <button className="btn btn-blue-outline">Cancel</button>
            </Link>

            <button className="btn btn-blue" onClick={handleSectorIndentApprove}>
              Send Request
            </button>
          </div>
        </div>}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <DialogContentText>
            <Popup setOpenModal={setOpenModal} indentId={indentDetail?.IndentRef} redirectLink="/Supervisor/IndentRequest" text="Consolidate request" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>

  );
}

export default ViewConsolidate;
