import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment"

function CenterIndentRow(props) {
  const { rows, Check, setCheck, index  ,handleCheck} = props;

  console.log("rows"  , rows);

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onChange={handleCheck}
            name={rows._id}
            checked={rows?.isChecked || false}
          />
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.AnganwadiID?.district.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.AnganwadiID?.sector?.Label}</span>
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="table-data-wrapper table-flex-column">
            <span className="table-data-text text-bold text-pink">{rows.AnganwadiID?.anganwadi}</span>
            <span className="table-data-sub-text">{rows.AnganwadiID?.code}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.IndentRef}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.AnganwadiID?.awwName}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{moment(rows.createdAt).format('DD-MM-YYYY')}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span
              className={`btn-small btn-status cursor-none ${
                rows.SupervisorApproved === true ? "btn-blue" : "btn-yellow"
              }`}
            >
              {rows.SupervisorApproved === true ?  "Approved" : "Pending"}

            </span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <Link className="link-none" to={{pathname :`/Supervisor/ViewIndent/${rows._id}`}}>
              <button className="btn btn-medium btn-blue-outline-hover">
                View
              </button>
            </Link>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CenterIndentRow;
