import React from "react";
import { Route, Navigate } from "react-router-dom";
// import { Redirect } from 'react-router';
import Landpage from "../containers/homePage/LandpageContainer.jsx";
import Loginpage from "../containers/homePage/LoginPage.jsx";
import LogoutPage from "../containers/homePage/LogoutPage.jsx";
import ForgotPassword from "../containers/homePage/ForgotPassword.jsx";
import ResetPassword from "../containers/homePage/ResetPassword.jsx";
import ViewIndentCo from "../containers/co/viewIndent/ViewIndentContainer.jsx";
import IndentRequestCo from "../containers/co/indentRequest/IndentRequestContainer.jsx";
import InventoryCo from "../containers/co/inventory/InventoryContainer.jsx";
import InventoryHistoryCo from "../containers/co/inventoryHistory/InventoryHistoryContainer.jsx";
import ShipmentCo from "../containers/co/shipment/ShipmentContainer.jsx";
import ViewShipmentCo from "../containers/co/viewShipment/ViewShipmentContainer.jsx";
import CenterDetailsCo from "../containers/co/centerDetails/CenterDetailsContainer.jsx";
import CenterDetailsSup from "../containers/supervisor/centerDetails/CenterDetailsContainer.jsx";
import ViewIndentCdpo from "../containers/cdpo/viewIndent/ViewIndentContainer.jsx";
import IndentRequestCdpo from "../containers/cdpo/indentRequest/IndentRequestContainer.jsx";
import InventoryCdpo from "../containers/cdpo/inventory/InventoryContainer.jsx";
import InventoryHistoryCdpo from "../containers/cdpo/inventoryHistory/InventoryHistoryContainer.jsx";
import ShipmentCdpo from "../containers/cdpo/shipment/ShipmentContainer.jsx";
import ViewShipmentCdpo from "../containers/cdpo/viewShipment/ViewShipmentContainer.jsx";
import CenterDetailsCdpo from "../containers/cdpo/centerDetails/CenterDetailsContainer.jsx";
import ViewIndentSuper from "../containers/supervisor/viewIndent/ViewIndentContainer.jsx";
import IndentRequestSuper from "../containers/supervisor/indentRequest/IndentRequestContainer.jsx";
import ViewConsolidate from "../containers/supervisor/viewIndent/ViewConsolidate.jsx";
import ViewConsolidateCdpo from "../containers/cdpo/viewIndent/ViewConsolidate.jsx";
import CoInventoryReport from "../containers/co/inventoryReport/RInventoryContainer.jsx";
import Home from "../components/landing/Home.jsx";
import Coverpage from "../components/home/Coverpage.jsx";
import BlogPost from "../components/home/blogPost/BlogPost.jsx";
import Reachout from "../components/home/reachout/Reachout.jsx";

// function requireAuth(nextState, replace, next) {
//   var authenticated = localStorage.getItem("user");
//   console.log("authenticated", authenticated);
//   if (authenticated == null) {
//     replace({
//       pathname: "/Login",
//       state: { nextPathname: nextState.location.pathname }
//     });
//   }
//   next();
// }
function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = localStorage.getItem("user");
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
const routes = (
  <>
    {/* Home Page */}

    <Route exact path="/" element={<Coverpage />} />
    <Route exact path="/blog/:id" element={<BlogPost />} />
    <Route exact path="/contact" element={<Reachout />} />
    <Route exact path="/Oldhome" element={<Home />} />
    <Route exact path="/Home" element={<Landpage />} />
    <Route exact path="/Login" element={<Loginpage />} />
    <Route exact path="/Logout" element={<LogoutPage />} />
    <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
    <Route exact path="/ResetPassword" element={<ResetPassword />} />

    {/* <Route element={<RequireAuth />}> */}
    {/* CO Pages */}
    <Route
      exact
      path="/Co/ViewIndent/:superIndentId"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewIndentCo />
        </RequireAuth>
      }
    />
    <Route exact path="/Co/IndentRequest" element={<IndentRequestCo />} />
    <Route
      exact
      path="/Co/CenterDetails/:projectId"
      element={
        <RequireAuth redirectTo="/Login">
          <CenterDetailsCo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Co/Inventory"
      element={
        <RequireAuth redirectTo="/Login">
          <InventoryCo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Co/InventoryHistory"
      element={
        <RequireAuth redirectTo="/Login">
          <InventoryHistoryCo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Co/Shipment"
      element={
        <RequireAuth redirectTo="/Login">
          <ShipmentCo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Co/ViewShipment"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewShipmentCo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Co/InventoryReport"
      element={
        <RequireAuth redirectTo="/Login">
          <CoInventoryReport />
        </RequireAuth>
      }
    />

    {/* CDPO Page */}
    <Route
      exact
      path="/Cdpo/ViewIndent/:superIndentId"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewIndentCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/IndentRequest"
      element={
        <RequireAuth redirectTo="/Login">
          <IndentRequestCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/CenterDetails/:sectorId"
      element={
        <RequireAuth redirectTo="/Login">
          <CenterDetailsCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/Inventory"
      element={
        <RequireAuth redirectTo="/Login">
          <InventoryCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/InventoryHistory"
      element={
        <RequireAuth redirectTo="/Login">
          <InventoryHistoryCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/Shipment"
      element={
        <RequireAuth redirectTo="/Login">
          <ShipmentCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/ViewShipment"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewShipmentCdpo />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Cdpo/ProjectIndent/:projectID"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewConsolidateCdpo />
        </RequireAuth>
      }
    />

    {/* Supervisor */}
    <Route
      exact
      path="/Supervisor/CenterDetails"
      element={
        <RequireAuth redirectTo="/Login">
          <CenterDetailsSup />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Supervisor/ViewIndent/:IndentId"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewIndentSuper />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Supervisor/IndentRequest"
      element={
        <RequireAuth redirectTo="/Login">
          <IndentRequestSuper />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/Supervisor/SectorIndent/:sectorID"
      element={
        <RequireAuth redirectTo="/Login">
          <ViewConsolidate />
        </RequireAuth>
      }
    />
    {/* </Route> */}
  </>
);

export default routes;
