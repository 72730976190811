import React, { useEffect, useState } from "react";
import "./Showcase.css";

// import slide1 from "../../../assets/icons/slider/slide1.jpg";
// import slide2 from "../../../assets/icons/slider/slide2.jpg";
// import slide3 from "../../../assets/icons/slider/slide3.jpg";
// import slide4 from "../../../assets/icons/slider/slide4.jpg";
// import slide5 from "../../../assets/icons/slider/slide5.jpg";

// const SlideImages = [slide1, slide2, slide3, slide4, slide5];

export default function Showcase({ homeRef }) {
  const [IndexCount, setIndexCount] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndexCount(SlideImages.length - 1 !== IndexCount ? IndexCount + 1 : 0);
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [IndexCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndexCount(true);
    }, 500);

    return () => clearInterval(interval);
  }, [IndexCount]);

  console.log(IndexCount);

  return (
    <React.Fragment>
      <section
        className="Showcase__main_container section_space"
        ref={homeRef}
        // style={{
        //   background: `url(${SlideImages[IndexCount]}) no-repeat center`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="Page__main_container">
          <div className="Showcase__layout_wrapper">
            <div
              className="Showcase__content_info"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="Main__headline_wrap">
                <div class="heading_wrapper">
                  <h1 class="leader_showcase_title max_width">
                    Revolutionizing Humanitarian Aid Through Innovative
                    Technology with
                  </h1>
                  <h2
                    class={`brand_text ${IndexCount && "text_active"}`}
                    data-fill-text="UNIFYAID"
                  >
                    UNIFYAID
                  </h2>
                </div>
              </div>

              {/* <div className="Showcase__action_wrapper">
                <button className="mi_btn mi_btn_medium mi_btn_secondary">
                  Lets Talk
                </button>
              </div> */}
            </div>
            {/* <div className="Showcase__slider_info">
              <div className="Showcase__line_border">
                <div className="line_vector"></div>
              </div>
              <div className="Showcase__slider_count">
                <p className="mi_small slider_count_text">1/3</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
