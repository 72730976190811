import React from "react";
import LoginPage from "../../components/homePage/LoginPage";

function LoginPageContainer() {
  return (
    <div className="home-container">
      <LoginPage />
    </div>
  );
}

export default LoginPageContainer;
