import React from "react";
import "./Blog.css";

export default function Blog({ blogRef }) {
  return (
    <section className="blog_container" ref={blogRef}>

      
      <div className="mi_page_container">
      <div className="blog_header">
        <div className="section_headline">
          <h1 className="mi_headingLite section_headline_ts">
            INDUSTRIES WE SERVE
          </h1>
        </div>
      </div>

      <div className="blog_alt_container">
        <div className="blog_alt_card">
          <div className="blog_alt_banner b1"></div>
          <h1 className="column_subheading_ts">
            Humanitarian Organizations
          </h1>
        </div>

        <div className="blog_alt_card">
          <div className="blog_alt_banner b2"></div>
          <h1 className="column_subheading_ts">
          NGO's
          </h1>
        </div>

        <div className="blog_alt_card">
          <div className="blog_alt_banner b3"></div>
          <h1 className="column_subheading_ts">
          Governments
          </h1>
        </div>

        <div className="blog_alt_card">
          <div className="blog_alt_banner b4"></div>
          <h1 className="column_subheading_ts">
          Banks 
          </h1>
        </div>

       
      </div>

      {/* <div className="blog_card_wrapper1">
        <div className="mi_page_container">
          <div className="blog_card_inner_wrapper">
            <div className="blog_null_space"></div>
            <div className="blog_content_space">
              <div className="blog_content_holder">
                <article className="column_content">
                  <h1 className="column_heading_ts">
                    Humanitarian Organizations
                  </h1>
                  <div className="paragraph_wrapper">
                    <p className="column_body_ts">
                      UnifyAid is designed to meet the unique needs of
                      humanitarian organizations, both large and small. Our
                      solution empowers organizations to deliver aid more
                      efficiently, reduce operational costs, and enhance
                      transparency. Whether responding to emergencies, managing
                      long-term development programs, or addressing nutrition
                      challenges, UnifyAid is the platform of choice for
                      modernizing aid delivery.
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog_card_wrapper2">
        <div className="mi_page_container">
          <div className="blog_card_inner_wrapper_alt">
            <div className="blog_content_space">
              <div className="blog_content_holder">
                <article className="column_content">
                  <h1 className="column_heading_ts">Nongovernmental organization (NGO)</h1>
                  <div className="paragraph_wrapper">
                    <p className="column_body_ts">
                      Government agencies can leverage UnifyAid to strengthen
                      their efforts in delivering social welfare programs,
                      disaster response, and public health initiatives. Our
                      technology provides the necessary tools to optimize supply
                      chains, monitor program impact, and ensure effective
                      utilization of resources, resulting in improved services
                      and greater accountability.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="blog_null_space"></div>
          </div>
        </div>
      </div>
      <div className="blog_card_wrapper3">
        <div className="mi_page_container">
          <div className="blog_card_inner_wrapper">
            <div className="blog_null_space"></div>
            <div className="blog_content_space">
              <div className="blog_content_holder">
                <article className="column_content">
                  <h1 className="column_heading_ts">
                  Government Agencies
                  </h1>
                  <div className="paragraph_wrapper">
                    <p className="column_body_ts">
                      UnifyAid is designed to meet the unique needs of
                      humanitarian organizations, both large and small. Our
                      solution empowers organizations to deliver aid more
                      efficiently, reduce operational costs, and enhance
                      transparency. Whether responding to emergencies, managing
                      long-term development programs, or addressing nutrition
                      challenges, UnifyAid is the platform of choice for
                      modernizing aid delivery.
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog_card_wrapper4">
        <div className="mi_page_container">
          <div className="blog_card_inner_wrapper_alt">
            <div className="blog_content_space">
              <div className="blog_content_holder">
                <article className="column_content">
                  <h1 className="column_heading_ts">Bank Sector</h1>
                  <div className="paragraph_wrapper">
                    <p className="column_body_ts">
                      Government agencies can leverage UnifyAid to strengthen
                      their efforts in delivering social welfare programs,
                      disaster response, and public health initiatives. Our
                      technology provides the necessary tools to optimize supply
                      chains, monitor program impact, and ensure effective
                      utilization of resources, resulting in improved services
                      and greater accountability.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="blog_null_space"></div>
          </div>
        </div>
      </div> */}
      </div>
    </section>
  );
}
