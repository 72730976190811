import React from "react";
import Logo from "../../../assets/icons/logo/logo.svg";
import "./HomeHeader.css";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import LoginForm from "../LoginForm/LoginForm";
import { Link } from "react-router-dom";

export default function HomeHeader({ handleNavClick }) {
  const [Mode, setMode] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClickOpen = (value) => {
    setOpen(true);
    console.log(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <header className="site_header">
        <div className="mi_page_container">
          <nav className="site_navbar">
            <Link to="/" className="brand_logo">
              <img src={Logo} alt="Logo" />
            </Link>
            <ul className="site_menulist">
              <li
                className="site_menuItem"
                onClick={() => handleNavClick("about")}
              >
                <div className=" site_menuLink">About</div>
              </li>
              <li
                className="site_menuItem"
                onClick={() => handleNavClick("feature")}
              >
                <div className=" site_menuLink">Features</div>
              </li>
              <li
                className="site_menuItem"
                onClick={() => handleNavClick("blog")}
              >
                <div className=" site_menuLink">Blogs</div>
              </li>
              <li
                className="site_menuItem"
                onClick={() => handleNavClick("contact")}
              >
                <div className=" site_menuLink">Contact</div>
              </li>
              <li className="site_menuItem">
                <div className="header_action">
                  <button
                    className="mi_btn mi_btn_md mi_btn_primary"
                    onClick={() => {
                      setOpen(true);
                      setMode("user");
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="mi_btn mi_btn_md mi_btn_secondary"
                    onClick={() => {
                      setOpen(true);
                      setMode("admin");
                    }}
                  >
                    Admin
                  </button>
                </div>
              </li>
            </ul>
            <div className="mobile_btns">
              <div className="header_action">
                <button className="mi_btn mi_btn_md mi_btn_primary" onClick={() => {
                      setOpen(true);
                      setMode("user");
                    }}>
                  Login
                </button>
                <button className="mi_btn mi_btn_md mi_btn_secondary"  onClick={() => {
                      setOpen(true);
                      setMode("admin");
                    }}>
                  Admin
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent sx={{ padding: "1rem !important" }}>
          <LoginForm onClose={handleClose} Mode={Mode} />
        </DialogContent>
      </Dialog>
    </>
  );
}
