import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "../../../assets/styles/indent/Indent.scss";
import Popup from "../../../common/Popup/Popup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function ViewIndent() {

  let { IndentId } = useParams();

  const [indentDetail, setIndentDetail] = useState(null)
  const [beneficiaryArr, setBeneficaryArr] = useState([])
  const [comodityArr, setCommodityArr] = useState([])
  const [total, setTotal] = useState(null)
  const [updateView, setUpdate] = useState(false)
  const [beneficaryEdit, setBeneficaryEdit] = useState(null)


  useEffect(() => {
    console.log("effect...");
    getIndentView()
  }, [updateView]);

  // useEffect(()=>{
  //   console.log("Indent data request" , indentDetail,
  //     beneficiaryArr,
  //     comodityArr , beneficaryEdit);
  // } , [indentDetail,beneficiaryArr,comodityArr,beneficaryEdit])

  const getIndentView = async () => {
    console.log("in update...")
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/indent-request/view-indent/${IndentId}`)
      console.log("viewe indent", res.data)
      let obj = {}
      res.data.beneficary?.map((ben) => {
        obj[ben._id] = ben.Qty
      });

      console.log("Obj data", obj);

      setIndentDetail(res.data.indentDetails.indent)
      setTotal(res.data.indentDetails.total)
      setBeneficaryArr(res.data.beneficary)
      setCommodityArr(res.data.comodity)
      setBeneficaryEdit(obj)

    } catch (error) {
      console.log(error);
    }
  }

  const [Edit, setEdit] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleIndentApprove = async () => {
    try {
      // () => setOpenModal(true)

      const data = {
        id: IndentId,
        SupervisorApproved: true,
        SupervisorApprovedAt: Date.now()
      }

      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/indent-request/indent`, data)
      setOpenModal(true)


    } catch (error) {
      console.log(error);
    }
  }

  const handleBenInputChange = async (e) => {
    console.log("name", e.target.name);
    console.log("value", [e.target.value]);
    console.log("beneficaryEdit", beneficaryEdit);
    console.log("name data", beneficaryEdit[e.target.name]);
    console.log("value data", [e.target.value]);


    setBeneficaryEdit({
      ...beneficaryEdit,
      [e.target.name]: e.target.value
    })
  }


  const handleSaveClick = async () => {
    setEdit(!Edit)

    if (Edit) {
      try {

        let arr = []

        for (const [key, value] of Object.entries(beneficaryEdit)) {
          console.log(`${key}: ${value}`);
          arr.push({
            id: key,
            qty: value
          })
        }

        console.log("Arr");

        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/map-beneficary/quantity`, arr)
        console.log(res);
        console.log("before updating...");
        // setUpdate()
        getIndentView()
        toast.success("Quantity Updated")

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.error)
      }
    }
  }


  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">VIEW ANGANWADI INDENT</h1>
        {/* <button className="btn btn-link-view">
          <i class="bx bx-arrow-back icons-btn"></i> Back to Indent Request
        </button> */}
        {indentDetail?.SupervisorApproved === !true && <button className="btn btn-orange" onClick={handleSaveClick} >
          {Edit ? (
            <>
              <i class="bx bx-save icons-btn"></i>Save

            </>
          ) : (
            <>
              <i class="bx bx-pencil icons-btn"></i>Edit
            </>
          )}
        </button>}
      </div>
      <div className="indent-main-grid-container">
        <div className="indent-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Indent Details
          </span>
          <div className="indent-grid-card">
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Indent ID</span>
              <div className="id-status-wrapper">
                <span className="indent-card-text-md-extrabold">{indentDetail?.IndentRef}</span>
                {/* <span className="btn-small btn-blue btn-status cursor-none">Approved</span> */}
              </div>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Date</span>
              <span className="indent-card-text-md-bold">{indentDetail && moment(indentDetail.createdAt).format('DD-MM-YYYY')}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Anaganwadi Name</span>
              <span className="indent-card-text-md-extrabold">
                {indentDetail?.AnganwadiID?.anganwadi}
              </span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Anaganwadi</span>
              <span className="indent-card-text-md-bold">{indentDetail?.AnganwadiID?.code}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Project</span>
              <span className="indent-card-text-md-extrabold">{indentDetail?.AnganwadiID?.project.Label}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">Sector</span>
              <span className="indent-card-text-md-bold">{indentDetail?.AnganwadiID?.sector.Label}</span>
            </div>
            <div className="indent-data-group-grid mb-large">
              <span className="indent-card-text">District</span>
              <span className="indent-card-text-md-bold">{indentDetail?.AnganwadiID?.district.Label}</span>
            </div>
          </div>
        </div>
        <div className="beneficiary-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Beneficiary Details
          </span>
          <div className="indent-grid-card">
            <div className="indent-grid-card-body">
              <div className="indent-data-group">
                <span className="indent-card-text">Month</span>
                <span className="indent-card-data-text indent-flex-center">
                  {moment().format('MMMM YYYY')} <i class="bx bxs-calendar icons-class"></i>
                </span>
              </div>
              <div className="indent-data-group">
                <span className="indent-card-heading-text">Beneficiary</span>
                <span className="indent-card-heading-text">
                  No of Beneficiary
                </span>
              </div>
              {beneficiaryArr && beneficiaryArr.map((benefiar) => (
                <div className="indent-data-group" key={benefiar._id}>
                  <div className="indent-text-wrapper">
                    <span className="indent-card-text">
                      {benefiar.BeneficiaryID?.Label}
                    </span>
                  </div>
                  <input
                    disabled={Edit ? false : true}
                    name={benefiar._id}
                    type="text"
                    className={`indent-card-text-bold indent-edit-text ${Edit && "indent-edit-text-active"
                      }`}

                    onChange={handleBenInputChange}
                    // value={ Edit ? benefiar.Qty :  getBenValue(benefiar._id)}
                    value={beneficaryEdit && beneficaryEdit[benefiar?._id]}
                  />
                </div>
              ))}
              {/* <div className="indent-data-group">
                <div className="indent-text-wrapper">
                  <span className="indent-card-text">Normal Children</span>
                  <span className="indent-card-sub-text">( 7 m to 3 yrs )</span>
                </div>
                <input
                  disabled={Edit ? false : true}
                  type="text"
                  className={`indent-card-text-bold indent-edit-text ${
                    Edit && "indent-edit-text-active"
                  }`}
                  value="92"
                />
              </div>
              <div className="indent-data-group">
                <div className="indent-text-wrapper">
                  <span className="indent-card-text">Supervised Feeding</span>
                  <span className="indent-card-sub-text">( 7 m to 3 yrs )</span>
                </div>
                <input
                  disabled={Edit ? false : true}
                  type="text"
                  className={`indent-card-text-bold indent-edit-text ${
                    Edit && "indent-edit-text-active"
                  }`}
                  value="33"
                />
              </div>

              <div className="indent-data-group">
                <div className="indent-text-wrapper">
                  <span className="indent-card-text">
                    Normal Pre-School Children
                  </span>
                  <span className="indent-card-sub-text">( 3 - 6 yrs )</span>
                </div>
                <input
                  disabled={Edit ? false : true}
                  type="text"
                  className={`indent-card-text-bold indent-edit-text ${
                    Edit && "indent-edit-text-active"
                  }`}
                  value="98"
                />
              </div>

              <div className="indent-data-group">
                <div className="indent-text-wrapper">
                  <span className="indent-card-text">Supervised Feeding</span>
                  <span className="indent-card-sub-text">( 3 - 6 yrs )</span>
                </div>
                <input
                  disabled={Edit ? false : true}
                  type="text"
                  className={`indent-card-text-bold indent-edit-text ${
                    Edit && "indent-edit-text-active"
                  }`}
                  value="19"
                />
              </div> */}
            </div>
            <div className="indent-grid-card-footer">
              <div className="indent-data-group">
                <span className="indent-card-heading-text">
                  Total Beneficiary
                </span>
                <span className="indent-card-total-text">{total && total}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="commodity-detail-area">
          <span className="detail-area-heading indent-heading-text ml-70">
            Commodity Details
          </span>
          <div className="indent-grid-card">
            <div className="indent-data-group mb-large">
              <span className="indent-card-heading-text">Commodity</span>
              <span className="indent-card-heading-text">Total Quantity</span>
            </div>
            {comodityArr && comodityArr.map((comm) => (
              <div key={comm._id} className="indent-data-group mb-large">
                <span className="indent-card-text-md">{comm.CommodityID.Label}</span>
                <span className="indent-card-text-md-bold">{comm.Qty/1000} KG</span>
                {/* {comm.CommodityID.UOM} */}
              </div>
            ))}
            {/* <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Rice</span>
              <span className="indent-card-text-md-bold">1496 KG</span>
            </div>
            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Dal (RedGram)</span>
              <span className="indent-card-text-md-bold">294 KG</span>
            </div>
            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Oil</span>
              <span className="indent-card-text-md-bold">153 L</span>
            </div>

            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Milk</span>
              <span className="indent-card-text-md-bold">2106 L</span>
            </div>
            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Egg</span>
              <span className="indent-card-text-md-bold">15722 EA</span>
            </div>
            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Nutri snacks</span>
              <span className="indent-card-text-md-bold">89 KG</span>
            </div>
            <div className="indent-data-group mb-large">
              <span className="indent-card-text-md">Balamrutham</span>
              <span className="indent-card-text-md-bold">336 KG</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="button-group-container">
        <div className="button-group">

          <Link to="/Supervisor/IndentRequest">
            Back to Indent Request
          </Link>

          <Link to="/Supervisor/IndentRequest">
            <button className="btn btn-blue-outline">Cancel</button>
          </Link>

          <button className="btn btn-blue" disabled={indentDetail?.SupervisorApproved} onClick={handleIndentApprove}>
            {indentDetail?.SupervisorApproved === true ? "Approved" : "Approve"}
          </button>
        </div>
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <DialogContentText>
            <Popup setOpenModal={setOpenModal} indentId={indentDetail?.IndentRef} redirectLink="/Supervisor/IndentRequest" text="Indent Request" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>

  );
}

export default ViewIndent;
