import React, { useRef, useState } from "react";
import "./Blog.css";
import SectionTitle from "../common/section_title/SectionTitle";
import Blogcard from "../common/blog_card/Blogcard";
import { blogcontent } from "../blogPost/data/blogcontent";

export default function Blog({ blogRef }) {
  const [ArrowBtnRight, setArrowBtnRight] = useState(false);
  const myref = useRef();

  console.log(ArrowBtnRight);

  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setArrowBtnRight(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setArrowBtnRight(false);
  };
  return (
    <React.Fragment>
      <section className="Blog__main_container section_space" ref={blogRef}>
        <div className="Page__main_container">
          <div className="Blog__layout_wrapper">
            <div className="Blog__header_area">
              <div className="Blog__section_title_tagline section_left_space">
                <SectionTitle
                  tagline="stay updated here"
                  title="From Global Humanitarian News to UnifyAid’s Latest Steps"
                />
              </div>
              <div class="scroll_button_group">
                <div class="mi_scroll_button" onClick={() => scrollLeft(-1024)}>
                  <i class="fa-solid fa-circle-left"></i>
                </div>
                <div
                  class={`mi_scroll_button `}
                  onClick={() => scrollRight(1024)}
                >
                  <i class="fa-solid fa-circle-right"></i>
                </div>
              </div>
            </div>

            <div className="Blog__post_list_container section_left_space">
              <div className="Blog__grid_layout" ref={myref}>
                {blogcontent?.map((bloginfo) => (
                  <div
                    className="Blog__block_wrap"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Blogcard bloginfo={bloginfo} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
