import * as React from "react";
// import { useState, useEffect } from "react";
// import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword({ handleOtherRole, Role }) {
	// const { Role } = props;
	const [values, setValues] = React.useState({
		email: "",
		password: "",
		showPassword: false,
	});

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	// const handleClickShowPassword = () => {
	//     setValues({
	//         ...values,
	//         showPassword: !values.showPassword,
	//     });
	// };

	const sendOTP = async (e) => {
		e.preventDefault();
		// setErrors(!Errors)
		console.log(values);
		if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) {
			/* return true */
			const data2 = {
				email: values.email,
			};
			try {
				// console.log("local storage******************")
				// console.log(localStorage.getItem("user"))
				const userAdd = await axios.get(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, {
					params: data2,
				});
				// const userAdd = await axios.post( myVars.serverURL + 'users', data)
				console.log(userAdd.data);
				if (userAdd.data.type == 0) {
					toast.error("Email does not exist.");
				} else {
					if (userAdd.data.type == -1) {
						toast.error("Error in generating OTP. Contact Admin.");
					} else {
						console.log("local storage******************");
						console.log(localStorage.getItem("reset"));
						localStorage.setItem("reset", JSON.stringify(data2));
						window.location = "/ResetPassword";
					}

					//CALL SEND OTP API

					// const min = 100001;
					// const max = 999999;
					// const randm = Math.ceil(min + Math.random() * (max - min));
					// const data1 = {
					//     "email": values.email,
					//     "mobile": "",
					//     "OTP": randm,
					//     "source": "wcw.statledger.io"
					// }
					// const generateOTP  = await axios.get(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, { params: data })
					// console.log(data1)
					// const generateOTP = await axios.post(`http://integrations.vaccineledger.com:9003/integrationmanagement/api/v1/otp/sendotp`, data1)
					// const generateOTP = await axios({
					//     method: 'post',
					//     url: "http://integrations.vaccineledger.com:9003/integrationmanagement/api/v1/otp/sendotp",
					//     headers: {
					//       'Content-Type': 'application/json'
					//     },
					//     data: data1
					//   });
					// console.log(generateOTP)
				}

				//status => 1-success, 2-new, 3-invalid
				// if (userAdd.data.status == 1 || userAdd.data.status == 2) {
				//     localStorage.setItem("user", JSON.stringify(userAdd.data));
				//     if (userAdd.data.type == 1) {   //CO/user
				//         window.location = "/Co/IndentRequest";
				//     } else {
				//         if (userAdd.data.type == 2) {   //Cdpo
				//             window.location = "/Cdpo/IndentRequest";
				//         } else {
				//             if (userAdd.data.type == 3) {   //Supervisor
				//                 window.location = "/Supervisor/IndentRequest";
				//             } else {
				//                 toast.error("Invalid email or password.");
				//             }
				//         }
				//     }
				// } else {
				//     if (userAdd.data.status == 2) {
				//         toast.success("Welcome.");
				//     } else {
				//         toast.error("Invalid email or password.");
				//     }
				// }

				//if(userAdd.status === 201) window.location ="/ManageUsers";
			} catch (error) {
				console.log(error.response);
				//toast.error(error.response.data.error || error.response.data.message.split(":")[2]);
				// toast.error(error.response.data.error)
			}
		} else {
			toast.error("Enter a valid Email.");
		}
		// if (values.email.length <= 6) {
		// } else {
		// }
	};

	// const handleMouseDownPassword = (event) => {
	//     event.preventDefault();
	// };

	return (
		<form>
			<div className="user-selection-container">
				<div className="select-text-group">
					<span className="login-heading-text">Forgot Password?</span>
				</div>

				<div className="login-field">
					<div className="login-input-group">
						<FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
							<InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
							<OutlinedInput
								sx={{
									backgroundColor: "#fff",
									input: {
										"&:-webkit-autofill": {
											"-webkit-box-shadow": "0 0 0 100px #fff inset",
											"-webkit-text-fill-color": "#111",
											"-internal-autofill-selected": "#fff !important",
										},
									},
								}}
								id="outlined-adornment-email"
								type="text"
								value={values.email}
								onChange={handleChange("email")}
								label="Email"
							/>
							{/* <span className="input-error-text error-text">
              Invalid Username
            </span> */}
						</FormControl>
					</div>
					{/* <div className="login-input-group">
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                sx={{
                  backgroundColor: "#fff",
                  input: {
                    "&:-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 100px #fff inset",
                      "-webkit-text-fill-color": "#111",
                      "-internal-autofill-selected": "#fff !important",
                    },
                  },
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {/* <span className="input-error-text error-text">
              Invalid Username
            </span> */}
					{/* </FormControl>
          </div>
          <div className="login-action-group">
            <div className="checkbox-group">
              <FormGroup>
                <FormControlLabel
                  sx={{ color: "red" }}
                  control={<Checkbox size="small" />}
                  label={<span className="table-data-text">Remember me</span>}
                />
              </FormGroup>
            </div>
            <div className="forgot-link">
              <Link
              style={{ textDecoration: "none" }}
              to="/forgot-password"
              className="table-data-text"
            >
              Forgot password?
            </Link>
            </div>
          </div> */}
				</div>
				<Link
					to={`${
						values.email === "cdpo"
							? "Cdpo/IndentRequest"
							: values.email === "co"
							? "Co/IndentRequest"
							: values.email === "supervisor" && "Supervisor/IndentRequest"
					}`}
					// to='Supervisor/IndentRequest'
					className="link-none"
				>
					<button type="submit" className={`btn btn-pink btn-homepage`} onClick={sendOTP}>
						Generate OTP
					</button>
				</Link>

				<div className="forgot-link">
					<Link style={{ textDecoration: "none" }} to="/Login" className="table-data-text">
						Back to Login
					</Link>
				</div>
				<ToastContainer />
			</div>
		</form>
	);
}

export default ForgotPassword;
