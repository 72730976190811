import React, { useState } from "react";
import "../../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import CenterIndentRow from "./CenterIndentRow";
import { useEffect } from "react";
import axios from "axios";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { textAlign } from "@mui/system";

function CenterIndent({ dateVal, setConsolidateTrue, handleDateChange, Tab }) {

  const [indent, setIndent] = useState([])
  const [checked, setChecked] = useState([])
  const [Check, setCheck] = useState();
  const [CheckAll, setCheckAll] = useState(false);
  const [triggerChange, setTriggerChange] = useState(false)
  const [handleEffect, setHandleEffect] = useState(false);
  const [isFilter, setIsFilter] = useState(false)
  // const [isFilter, setIsFilter] = useState({
  //   project: false,
  //   sector: false,
  //   district: false,
  //   status: false
  // });

  useEffect(() => {
    console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    console.log("Date Val", dateVal);
    getIndent()
  }, [dateVal, triggerChange]);

  const getIndent = async () => {
    console.log(dateVal)
    if (dateVal.length > 4) {
      try {
        // console.log("*****************************************Storage")
        var sec = JSON.parse(localStorage.getItem("user"))
        // console.log(sec.user.Sector);
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/indent-request/indent`, { params: { month: dateVal, SectorID: sec.user.Sector } })
        console.log(res.data)
        setIndent(res.data)
        const data = res.data
        const falseIndents = data.filter((indent) => {
          // (rows.AnganwadiID._id == "6214cf59fc12a069b7de760d") ? 
          return indent.SupervisorApproved === false
        })
        setCheck(new Array(data.length).fill(false));

        console.log("falseIndents", falseIndents);
        console.log("data.length", data.length);

        if (falseIndents.length > 0 || data.length <= 0) {
          // setConsolidateTrue(false)
        }

        console.log("indent data", res);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // Dropdown Button Style
  const CustomStatus = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="table-head-wrapper cursorP"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      id="dropdown-basic"
    >
      <span className="table-heading-text">{children}</span>
    </div>
  ));

  const CustomMenuStatus = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const Data = [
    {
      id: "1",
      status: "APPROVED",
    },
    {
      id: "2",
      status: "PENDING",
    },
    {
      id: "3",
      status: "APPROVED",
    },
    {
      id: "4",
      status: "PENDING",
    },
    {
      id: "5",
      status: "APPROVED",
    },
    {
      id: "6",
      status: "APPROVED",
    },
    {
      id: "7",
      status: "APPROVED",
    },
    {
      id: "8",
      status: "PENDING",
    },
    {
      id: "9",
      status: "PENDING",
    },
    {
      id: "10",
      status: "APPROVED",
    },
  ];

  const handleCheck = (e) => {
    const { name, checked } = e.target
    console.log("name checked", name, checked);
    let tempUser
    if (name == "allCheck") {
      tempUser = indent.map(ind => { return { ...ind, isChecked: checked } })
      setIndent(tempUser)
      console.log("tempuser", tempUser);
    } else {
      tempUser = indent.map(ind => ind._id === name ? { ...ind, isChecked: checked } : ind)
      setIndent(tempUser)
    }
    const ids = []
    tempUser.map((ti) => {
      if (ti.isChecked === true) {
        ids.push(ti._id)
      }
    })
    setChecked(ids)
  }

  const handleMutipleApproval = async () => {
    try {
      const updatedData = await axios.patch(`${process.env.REACT_APP_API_URL}/indent-request/approve-multiple`, { indentIdArr: checked })
      toast.success("Indents approved succesfully")
      setTriggerChange(!triggerChange)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }

  const handleStatusCLick = async (status) => {
    var sec = JSON.parse(localStorage.getItem("user"))
    console.log(sec.user.Sector);
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/indent-request/indent`, { params: { month: dateVal, SectorID: sec.user.Sector } })
    const indents = res.data
    setIsFilter(true)
    let data

    if (status === "active") {
      data = indents.filter((indent) => {
        return indent.SupervisorApproved === true
      })

      setIndent(data)
    }

    if (status === "inactive") {
      data = indents.filter((indent) => {
        return indent.SupervisorApproved === !true
      })
      setIndent(data)
    }

    if (status === "clear") {
      setIndent(indents)
      setIsFilter(false)
    }

    console.log("active users", data);
  }

  return (
    <>
      {checked.length > 0 && (
        <div className="selected-row-counter">
          <span className="selected-row-text">{checked.length} Indent Selected</span>
          <button className="btn btn-medium btn-blue-outline-hover" onClick={handleMutipleApproval}>
            <i class="bx bxs-check-circle icons-btn-sm"></i> Approve
          </button>
        </div>
      )}
      <TableContainer
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
          borderRadius: "0.4rem",
          background: "#fff",
          minHeight: "200px"
        }}
      >
        <Table sx={{ minWidth: 1098 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={handleCheck}
                  name="allCheck"

                />
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">District</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Sector</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Anganwadi</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Indent ID</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">AWW Name</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper table-head-wrapper-border-less">
                  <span className="table-heading-text">Date</span>
                </div>
              </TableCell>
              <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomStatus}
                    id="dropdown-custom-components"
                  >
                    Status <FilterAltIcon fontSize="small" /> {isFilter && <span onClick={() => handleStatusCLick("clear")}><CancelIcon fontSize="small" /></span>}
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenuStatus} align="end">
                    <Dropdown.Item>
                      <button className="btn drop-btn" onClick={() => handleStatusCLick("active")}>Approved</button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="btn drop-btn" onClick={() => handleStatusCLick("inactive")}>Pending</button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indent.length > 0 ?
              indent && indent.map((rows, index) => (
                // (rows.AnganwadiID._id == "6214cf59fc12a069b7de760d") ?
                <CenterIndentRow
                  key={index}
                  index={index}
                  rows={rows}
                  Check={Check}
                  setCheck={setCheck}
                  handleCheck={handleCheck}
                />
                // :
                // ""
              ))
              :
              <TableRow>
                <TableCell colSpan={9} sx={{ textAlign: "center", minHeight: "150px" }}>No Data Found</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer />
    </>
  );
}

export default CenterIndent;
