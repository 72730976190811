import React, { useState, useEffect } from "react";
import "../../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "react-bootstrap/FormControl";
import InboundRow from "./InboundRow";
import CodeIcon from "@mui/icons-material/Code";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CancelIcon from "@mui/icons-material/Cancel";

function Inbound({ dateVal, setConsolidateTrue, consolidateTrue }) {
	const [indent, setSuperIndent] = useState([]);
	const [checked, setChecked] = useState([]);
	// const [Check, setCheck] = useState(new Array(Data.length).fill(false));
	// const [CheckAll, setCheckAll] = useState(false);
	const [Check, setCheck] = useState();
	const [CheckAll, setCheckAll] = useState(false);
	const [triggerChange, setTriggerChange] = useState(false);
	const [handleEffect, setHandleEffect] = useState(false);
	// const [isFilter , setIsFilter]  = useState(false)
	const [isFilter, setIsFilter] = useState(false);
	//   {
	//   project: false,
	//   sector: false,
	//   district: false,
	//   status: false
	// });

	console.log("Indent - ", indent);

	const getIndent = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/co/get-superindents`, {
				params: { month: dateVal },
			});
			console.log(res);
			const falseIndents = res.data.filter((indent) => {
				return indent.SupervisorApproved === false;
			});

			console.log("falseIndents", falseIndents);
			console.log("data.length", res.data.length);

			if (falseIndents.length > 0 || res.data.length <= 0) {
				setConsolidateTrue(false);
			} else {
				setConsolidateTrue(true);
			}
			setCheck(new Array(res.data.length).fill(false));
			// setConsolidateTrue(true);
			let tempIndent = res.data.map((ind) => {
				return { ...ind, isChecked: false };
			});
			setSuperIndent(tempIndent);
			console.log("SUPER INDENT data", res);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		// console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
		getIndent();
		// let sec = JSON.parse(localStorage.getItem("user"))
		// console.log(sec)
	}, [triggerChange, dateVal]);

	// Dropdown Button Style
	const CustomFilter = React.forwardRef(({ children, onClick }, ref) => (
		<div
			className="table-head-wrapper table-flex-center cursorP"
			ref={ref}
			onClick={(e) => {
				// e.preventDefault();
				onClick(e);
			}}
			id="dropdown-basic"
		>
			<span className="table-heading-text">{children}</span>
			<CodeIcon className="filter-icon" />
		</div>
	));

	const CustomMenu = React.forwardRef(
		({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
			const [value, setValue] = useState("");

			return (
				<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
					<div className="menu-list">
						<FormControl
							autoFocus
							className="filter-search filter-none"
							placeholder="Search"
							onChange={(e) => setValue(e.target.value)}
							value={value}
						/>
						<ul className="list-unstyled dropdown-list">
							{React.Children.toArray(children).filter(
								(child) => !value || child.props.children.toLowerCase().startsWith(value),
							)}
						</ul>
					</div>
				</div>
			);
		},
	);

	// Dropdown Button Style
	const CustomStatus = React.forwardRef(({ children, onClick }, ref) => (
		<div
			className="table-head-wrapper table-flex-center cursorP"
			ref={ref}
			onClick={(e) => {
				// e.preventDefault();
				onClick(e);
			}}
			id="dropdown-basic"
		>
			<span className="table-heading-text">{children}</span>
			<CodeIcon className="filter-icon" />
		</div>
	));

	const CustomMenuStatus = React.forwardRef(
		({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
			const [value] = useState("");

			return (
				<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
					<ul className="list-unstyled">
						{React.Children.toArray(children).filter(
							(child) => !value || child.props.children.toLowerCase().startsWith(value),
						)}
					</ul>
				</div>
			);
		},
	);

	const Data = [
		{
			id: "1",
			status: "APPROVED",
		},
		{
			id: "2",
			status: "PENDING",
		},
		{
			id: "3",
			status: "APPROVED",
		},
		{
			id: "4",
			status: "PENDING",
		},
		{
			id: "5",
			status: "APPROVED",
		},
		{
			id: "6",
			status: "APPROVED",
		},
		{
			id: "7",
			status: "APPROVED",
		},
		{
			id: "8",
			status: "PENDING",
		},
		{
			id: "9",
			status: "PENDING",
		},
		{
			id: "10",
			status: "APPROVED",
		},
	];

	// const count = Check.filter((item) => item === true).length;

	const handleChange2 = () => {
		// const updatedCheckedState = Check.map(() => !CheckAll);
		// setCheckAll(!CheckAll);
		// setCheck(updatedCheckedState);
	};

	const handleCheck = (e) => {
		console.log("Handle Check");
		const { name, checked } = e.target;
		let tempUser;
		if (name === "allCheck") {
			tempUser = indent.map((ind) => {
				return { ...ind, isChecked: checked };
			});
			setSuperIndent(tempUser);
		} else {
			tempUser = indent.map((ind) => {
				let temp = ind?.IndentData?._id === name ? { ...ind, isChecked: checked } : ind;
				return temp;
			});
			setSuperIndent(tempUser);
		}
		const ids = [];
		tempUser.map((ti) => {
			if (ti.isChecked === true) {
				ids.push(ti?.IndentData?._id);
			}
		});
		setChecked(ids);
		console.log(ids);
	};

	const handleMutipleApproval = async () => {
		try {
			const updatedData = await axios.patch(
				`${process.env.REACT_APP_API_URL}/co/approve-multiple-cos`,
				{ indentIdArr: checked },
			);
			setChecked([]);
			toast.success("Indents Approved");
			setTriggerChange(!triggerChange);
		} catch (error) {
			toast.error(error.response.data.error);
		}
	};

	const handleDDCLick = async (filterName, filterValue) => {
		console.log("project id", filterName, filterValue);
		// const res =  await axios.get(myVars.serverURL + 'supervisors');
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/co/get-superindents`, {
			params: { month: dateVal },
		});
		console.log(res);
		let data;

		if (filterName === "project") {
			setIsFilter({
				project: true,
				sector: false,
				district: false,
				status: false,
			});
			data = res.data.filter((cdpo) => cdpo?.ProjectID?.Label === filterValue);
			console.log("data", data);
			setSuperIndent(res.data);
			return;
		}

		if (filterName === "sector") {
			setIsFilter({
				project: false,
				sector: true,
				district: false,
				status: false,
			});
			data = res.data.filter((cdpo) => cdpo?.Sector?.Label === filterValue);
			console.log("data", data);
			setSuperIndent(res.data);
			return;
		}

		if (filterName === "district") {
			setIsFilter({
				project: false,
				sector: false,
				district: true,
				status: false,
			});
			data = res.data.filter((cdpo) => cdpo?.District?.Label === filterValue);
			console.log("data", data);
			setSuperIndent(res.data);
			return;
		}
	};

	// const handleFilterClear = () => {
	//   console.log("clear clicked");
	//   setHandleEffect(!handleEffect)
	//   setIsFilter({
	//     project: false,
	//     sector: false,
	//     district: false,
	//     status: false
	//   })
	// }

	const handleStatusCLick = async (status) => {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/co/get-superindents`, {
			params: { month: dateVal },
		});
		const indents = res.data;
		setIsFilter(true);
		let data;
		console.log(indents);
		if (status === "active") {
			data = indents.filter((indent) => {
				console.log(indent);
				return indent.IndentData.SupervisorApproved === true;
			});
			setSuperIndent(data);
		}

		if (status === "inactive") {
			data = indents.filter((indent) => {
				return indent.IndentData.SupervisorApproved === !true;
			});
			setSuperIndent(data);
		}

		if (status === "clear") {
			setSuperIndent(indents);
			setIsFilter(false);
		}

		console.log("status-change: ", data);
	};

	return (
		<>
			{checked.length > 0 && (
				<div className="selected-row-counter">
					<span className="selected-row-text">{checked.length} Indent Selected</span>
					<button className="btn btn-medium btn-blue-outline-hover" onClick={handleMutipleApproval}>
						<i class="bx bxs-check-circle icons-btn-sm"></i> Approve
					</button>
				</div>
			)}
			<TableContainer
				sx={{
					boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
					borderRadius: "0.4rem",
					background: "#fff",
					minHeight: "200px",
				}}
			>
				<Table sx={{ minWidth: 1098 }} aria-label="simple table">
					{!indent || !indent.length ? <caption>No data found.</caption> : null}
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox color="primary" onChange={handleCheck} name="allCheck" />
								{/* <Checkbox
                  color="primary"
                  onChange={() => handleChange2()}
                  Checked={CheckAll}
                /> */}
							</TableCell>
							<TableCell>
								{/* <div className="table-head-wrapper">
                  <span className="table-heading-text">District</span>
                </div> */}
								<Dropdown>
									<Dropdown.Toggle as={CustomFilter} id="dropdown-custom-components">
										District
										{/* <FilterAltIcon fontSize="small" /> {isFilter.district && <span onClick={handleFilterClear}><CancelIcon fontSize="small" /></span>} */}
									</Dropdown.Toggle>
									<Dropdown.Menu align="end">
										{indent?.districts &&
											indent.districts.map((dis) => (
												<Dropdown.Item>
													{/* <button  onClick={handleDDCLick} value={dis._id} className="filter-option-text">{dis.Label}</button> */}
													<button
														className="btn drop-btn"
														onClick={() => handleDDCLick("district", dis.Label)}
														value={dis._id}
													>
														{dis.Label}
													</button>
												</Dropdown.Item>
											))}
									</Dropdown.Menu>
								</Dropdown>
							</TableCell>
							<TableCell>
								<Dropdown>
									<Dropdown.Toggle as={CustomFilter} id="dropdown-custom-components">
										Project
										{/* <FilterAltIcon fontSize="small" /> {isFilter.project && <span onClick={handleFilterClear}><CancelIcon fontSize="small" /></span>} */}
									</Dropdown.Toggle>
									<Dropdown.Menu align="end">
										{indent?.ProjectID &&
											indent.ProjectID.map((pro) => (
												<Dropdown.Item>
													<button
														className="btn drop-btn"
														onClick={() => handleDDCLick("project", pro.Label)}
														value={pro._id}
													>
														{pro.Label}
													</button>
												</Dropdown.Item>
											))}
									</Dropdown.Menu>
								</Dropdown>
							</TableCell>
							<TableCell>
								<div className="table-head-wrapper">
									<span className="table-heading-text">Indent ID</span>
								</div>
							</TableCell>
							<TableCell>
								<div className="table-head-wrapper">
									<span className="table-heading-text">CDPO Name</span>
								</div>
							</TableCell>
							<TableCell>
								<div className="table-head-wrapper ">
									<span className="table-heading-text">Date</span>
								</div>
							</TableCell>
							<TableCell>
								<Dropdown>
									<Dropdown.Toggle as={CustomStatus} id="dropdown-custom-components">
										Status <FilterAltIcon fontSize="small" />{" "}
										{isFilter && (
											<span onClick={() => handleStatusCLick("clear")}>
												<CancelIcon fontSize="small" />
											</span>
										)}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenuStatus} align="end">
										<Dropdown.Item>
											<button className="btn drop-btn" onClick={() => handleStatusCLick("active")}>
												Approved
											</button>
										</Dropdown.Item>
										<Dropdown.Item>
											<button
												className="btn drop-btn"
												onClick={() => handleStatusCLick("inactive")}
											>
												Pending
											</button>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</TableCell>
							{/* <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomStatus}
                    id="dropdown-custom-components"
                  >
                    Status <FilterAltIcon fontSize="small" />{isFilter.status && <span onClick={() => handleStatusCLick("clear")}><CancelIcon fontSize="small" /></span>}
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenuStatus} align="end">
                    <Dropdown.Item>
                      <button className="btn drop-btn" onClick={() => handleStatusCLick("active")}>Approved</button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="btn drop-btn" onClick={() => handleStatusCLick("inactive")}>Pending</button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TableCell> */}
							<TableCell
								sx={{
									padding: "0px 16px",
								}}
							>
								{/* <button className="btn btn-mini btn-varblue">
                  <i class="bx bxs-file-export  icons-btn-sm"></i> Export{" "}
                  <i class="bx bx-chevron-down icons-btn-sm-l"></i>
                </button> */}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{indent &&
							indent.map((rows, index) => (
								<InboundRow
									key={index}
									index={index}
									rows={rows}
									handleCheck={handleCheck}
									Check={Check}
									setCheck={setCheck}
									// setHandleEffect={setHandleEffect}
									// handleEffect={handleEffect}
								/>
							))}
						{/* {Data.map((rows, index) => (
              <InboundRow
                key={index}
                index={index}
                rows={rows}
                Check={Check}
                setCheck={setCheck}
              />
            ))} */}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default Inbound;
