import React, { useState, useEffect } from "react";
import "../../../assets/styles/indent/Indent.scss";
import "../../../assets/styles/input/Input.scss";
import InventoryTable from "./RinventoryTable/RInventoryTable";
// import React, { useState } from "react";
import "../../../assets/styles/page/Page.scss";
import moment from "moment";
import { Select } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";

import InventoryRow from "./RinventoryTable/RInventoryRow.jsx";
import "../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// import TableCell from "@mui/material/TableCell";
// import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

function Inventory() {
	const [Errors, setErrors] = useState(false);
	const [formState, setFormState] = useState({
		month: "",
		district: "",
		project: "",
		sector: "",
		product: "0",
	});

	const [inventory, setInventory] = useState([]);
	const [district, setDistrict] = useState([]);
	const [sector, setSector] = useState([]);
	const [project, setProject] = useState([]);
	const [product, setProduct] = useState([]);
	const [selectedSector, setSelectedSector] = useState([]);
	const [dateVal, setDateVal] = useState(moment().format("YYYY-MM"));
	const handleDateChange = (e) => {
		console.log("month", e.target.value);
		setDateVal(e.target.value);
		setFormState({
			...formState,
			month: e.target.value,
		});
	};

	const getInventory = async () => {
		try {
			console.log("getting inventory...", sector);
			// formState.product = product
			console.log(formState);
			setInventory(null);
			var data = JSON.stringify({ formState });
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/reports/getInventory`, {
				params: { formState },
			});
			console.log(res.data);
			setInventory(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		console.log("formstate", formState);
		if (formState.product.length > 5 || formState.sector.length > 5) {
			getInventory();
		}
	}, [formState]);

	const handleInput = async (e) => {
		const val = e.target.value;
		setFormState({
			...formState,
			[e.target.name]: val,
		});
		console.log(formState);
		if (e.target.name == "district") {
			console.log("district changed,...");
			setSector(null);
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/projects/getProjectsByDistrict?District=${val}`,
			);
			console.log(res);
			setProject(res.data);
			if (res.data.length > 0) {
				const res1 = await axios.get(
					`${process.env.REACT_APP_API_URL}/sectors/getSectorsByProject?Project=${res.data[0]._id}`,
				);
				setSector(res1.data);
			}
		}
		if (e.target.name == "project") {
			console.log("project changed...");
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/sectors/getSectorsByProject?Project=${val}`,
			);
			setSector(res.data);
		}
		if (e.target.name == "sector") {
			console.log("sector changed...");
			// getInventory();
			// const res = await axios.get(`${process.env.REACT_APP_API_URL}/sectors/getSectorsByProject?Project=` + val);
			// setSector(res.data);
		}
		if (e.target.name == "product") {
			console.log("product changed...");
			// setProduct(e.target.value)
			// getInventory();
		}
	};

	const getDistricts = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/districts`);
			// const res =  await axios.get(`${process.env.REACT_APP_API_URL}/co/get-superindents` , {params : {month : dateVal}})
			console.log("District res: ", res.data);
			setDistrict(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getProjects = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/projects`);
			console.log("Project res: ", res.data);
			setProject(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getSectors = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/sectors`);
			console.log("Sector res: ", res.data);
			setSector(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getProducts = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/commodities`);
			console.log("Product res: ", res.data);
			setProduct(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getDistricts();
		// getSectors();
		// getProjects();
		getProducts();
		setFormState({
			...formState,
			month: dateVal,
		});
		// getInventory();
	}, []);

	useEffect(() => {
		console.log("Sector updated", selectedSector);
		// getInventory();
	}, [product]);

	return (
		<div id="page-container" className="report">
			<div className="users-page-header">
				<h1 className="page-heading-text">INVENTORY REPORT</h1>
				{/* <span className="indent-card-data-text-md indent-flex-center">
          February, 2022 <i class="bx bxs-calendar icons-class"></i>
        </span> */}
				<span className="indent-card-data-text-md indent-flex-center">
					<input type="month" value={dateVal} onChange={handleDateChange} />
				</span>
			</div>
			<br />
			<div className="row">
				<div className="col-md-2">
					<div className="input-group-grid1">
						<div className="input-label">
							<span className="input-label-text">District</span>
						</div>
						<div className="input-data">
							<select required name="district" onChange={handleInput}>
								<option value="" disabled selected>
									Select
								</option>
								{district &&
									district.map((r) => (
										<option key={r._id} value={r._id}>
											{r.Label}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className="input-group-grid1">
						<div className="input-label">
							<span className="input-label-text">Project</span>
						</div>
						<div className="input-data">
							<select required name="project" onChange={handleInput}>
								<option value="" disabled selected>
									Select
								</option>
								{project &&
									project.map((r) => (
										<option key={r._id} value={r._id}>
											{r.Label}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className="input-group-grid1">
						<div className="input-label">
							<span className="input-label-text">Sector</span>
						</div>
						<div className="input-data">
							<select required name="sector" onChange={handleInput}>
								<option value="" disabled selected>
									Select
								</option>
								{sector &&
									sector.map((r) => (
										<option key={r._id} value={r._id}>
											{r.Label}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className="input-group-grid1">
						<div className="input-label">
							<span className="input-label-text">Product</span>
						</div>
						<div className="input-data">
							<select required name="product" onChange={handleInput}>
								<option value="" selected>
									Select
								</option>
								{product &&
									product.map((r) => (
										<option key={r._id} value={r._id}>
											{r.Label}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="user-table-content">
				{/* <InventoryTable dateVal={dateVal} handleSectorChange={handleSectorChange} sector={sector} product={product} /> */}
				<TableContainer
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
						borderRadius: "0.4rem",
						background: "#fff",
					}}
				>
					<Table sx={{ minWidth: 1098 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Anganwadi</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Registered Beneficiaries</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Requirement</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Opening Bal</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Received at AWC</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Total in Stock</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Qty Utilised</span>
									</div>
								</TableCell>
								<TableCell sx={{ borderRight: "1px solid #ddd !important" }}>
									<div className="table-head-wrapper-xl">
										<span className="table-heading-text">Closing Bal</span>
									</div>
								</TableCell>
								{/* <TableCell sx={{ padding: "0px 16px" }}>
                  <div className="table-head-wrapper-xl table-flex-column-center">
                    <span className="table-heading-text">Balamrutham</span>
                    <span className="table-subheading-text">(in Kg)</span>
                  </div>
                </TableCell>
                <TableCell sx={{ padding: "0px 16px" }}>
                  <div className="table-head-wrapper-xl table-flex-column-center">
                    <span className="table-heading-text">Murukulu</span>
                    <span className="table-subheading-text">(in Kg)</span>
                  </div>
                </TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{/* {Data.map((rows, index) => ( 
                <InventoryRow key={index} index={index} rows={rows} />
              ))} */}
							{inventory !== null ? (
								inventory &&
								inventory.map((rows, index) => (
									<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} id={index}>
										<TableCell>
											<div className="table-data-wrapper table-flex-column">
												<span className="table-data-text text-pink">{rows.Name}</span>
												<span className="table-data-sub-text">{rows.Code}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">{rows.RegisteredBeneficiaries}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">{rows.IndentCommodity / 1000}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">{rows.OpeningBal / 1000}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">{rows.receivedCnt / 1000}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">
													{(rows.receivedCnt + rows.OpeningBal) / 1000}
												</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">{rows.utilisedCnt / 1000}</span>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className="table-data-wrapper">
												<span className="table-data-text">
													{(rows.receivedCnt + rows.OpeningBal - rows.utilisedCnt) / 1000}
												</span>
											</div>
										</TableCell>
										{/* <TableCell>
                    <div className="table-data-wrapper">
                      <Link className="link-none" to="/Co/InventoryHistory">
                        <button className="btn btn-medium btn-blue-outline-hover">
                          View
                        </button>
                      </Link>
                    </div>
                  </TableCell> */}
									</TableRow>
									// <InventoryRow
									//   key={index}
									//   index={index}
									//   rows={rows}
									// // handleCheck={handleCheck}
									// // setHandleEffect={setHandleEffect}
									// // handleEffect={handleEffect}
									// />
								))
							) : (
								<TableRow>
									<TableCell colSpan={8} sx={{ textAlign: "center", minHeight: "150px" }}>
										No Data Found
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
}

export default Inventory;
