import React, { useState } from "react";
import "./LandingPage.scss";
import Selection from "./commons/Selection.jsx";
import Brandlogo from "../../assets/icons/logo/logo.svg";
// import Brandlogo from "../../assets/icons/svgs/Brandlogo.svg";
import Login from "./commons/Login.jsx";
import Background from "../../assets/icons/backgrounds/background.png";

function LandingPage() {
  const [Role, setRole] = useState(null);
  const [Step, setStep] = useState(1);

  const handleOtherRole = () => {
    setStep(1)
  }


  return (
    <div
      style={{ backgroundImage: `url(${Background})` }}
      className="landing-container"
    >
      <div className="container-center-wrap">
        <div className="landing-page-box">
          <div className="user-content-area">
            <img src={Brandlogo} alt="logo" className="landing-brand-logo" />
            <span className="landing-large-text">Welcome back,</span>
            <span className="landing-large-sub-text">Login to Continue</span>
          </div>
          <div className="user-selection-area">
            {Step === 1 && (
              <Selection
                Role={Role}
                setRole={setRole}
                Step={Step}
                setStep={setStep}
              />
            )}
            {Step === 2 && <Login Role={Role} handleOtherRole={handleOtherRole}/>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
