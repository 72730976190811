import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment"


function SectorIndentRow(props) {

  const { rows, Check, setCheck, index , data } = props;


  console.log("data prop" , data);

  const handleChange = (position) => {
    const updatedCheckedState = Check.map((item, index) =>
      index === position ? !item : item
    );
    setCheck(updatedCheckedState);
  };

  console.log('rows' ,rows);
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={Check[index]}
            onChange={() => handleChange(index)}
          />
        </TableCell> */}
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.element?.SectorID?.Project?.District?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.element?.SectorID?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.element?.IndentRef}</span>
          </div>
        </TableCell>
        {/* <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">Vincenza</span>
          </div>
        </TableCell> */}
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.totalQty}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{moment(rows?.element?.createdAt).format('MMMM YYYY')}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span
              className={`btn-small btn-status cursor-none ${
                rows?.element?.SupervisorApproved === true ? "btn-blue" : "btn-yellow"
              }`}
            >
              {rows?.element?.SupervisorApproved === true ? "Approved" : "Pending"}
         
            </span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <Link className="link-none"  to={{pathname :`/Supervisor/SectorIndent/${rows?.element?.SectorID?._id}`}}>
            {/* <Link className="link-none" to={{pathname :`/Supervisor/ViewIndent/${rows._id}`}}> */}
              <button className="btn btn-medium btn-blue-outline-hover">
                View
              </button>
            </Link>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SectorIndentRow;
