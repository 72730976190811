import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";


function OutboundRow(props) {
  // const { index } = props;
  const { rows, keys, index } = props;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  // console.log(rows)
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell>
          <div className="table-data-wrapper table-flex-column">
            <span className="table-data-text text-pink">
              {rows?.Supplier.Firmname}
            {/* {(rows[1] === "Oil") ? "TS OilFed " : "Telangana Foods"} */}
            </span>
            {/* <span className="table-data-sub-text">Balamrutham3</span> */}
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Commodity.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Qty/1000} KG</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.IndentRef}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{formatDate(rows?.createdAt)}</span>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default OutboundRow;
