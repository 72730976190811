import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/indent/Indent.scss";
import "../../../assets/styles/input/Input.scss";
import "../../../assets/styles/inventory/Inventory.scss";
import CenterDetailsTable from "./centerDetailsTable/CenterDetailsTable";

function CenterDetails() {
  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">ANGANWADI INDENT REQUEST</h1>
        <Link className="link-none" to="/Supervisor/IndentRequest">
          <button className="btn-alt btn-link-view">
            <i class="bx bx-arrow-back icons-btn"></i> Back to Indent Request
          </button> 
        </Link>
      </div>
      <div className="user-table-content">
        <CenterDetailsTable />
      </div>
    </div>
  );
}

export default CenterDetails;
