import React from "react";
import "../../../assets/styles/indent/Indent.scss";
import "../../../assets/styles/input/Input.scss";
import InventoryTable from "./inventoryTable/InventoryTable";

function Inventory() {
  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">INVENTORY</h1>
        {/* <span className="indent-card-data-text-md indent-flex-center">
          October, 2021 <i class="bx bxs-calendar icons-class"></i>
        </span> */}
      </div>
      <div className="user-table-content">
        <InventoryTable />
      </div>
    </div>
  );
}

export default Inventory;
