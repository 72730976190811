import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function CenterDetailsRow(props) {
	const { rows, keys, index } = props;

	const [quantity, setQuantity] = useState();
	useEffect(() => {
		let obj = {};
		rows?.mapSuperBen?.map((elem) => {
			obj[elem?.BeneficiaryID?.Label] = elem?.Qty;
    });
    setQuantity(obj);
	}, []);

	return (
		<>
			<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text table-flex-center">
							{rows?.element.AnganwadiID.sector.Project.District.Label}
						</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">
							{rows?.element.AnganwadiID.sector.Project.Label}
						</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper">
						<span className="table-data-text">{rows?.element.AnganwadiID.sector.Label}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper table-flex-column w-7">
						<span className="table-data-text-bold">{rows?.element.AnganwadiID.anganwadi}</span>
						<span className="table-data-sub-text">{rows?.element.AnganwadiID.code}</span>
					</div>
				</TableCell>
				<TableCell>
					<div className="table-data-wrapper table-flex-column w-8">
						<span className="table-data-text-bold">{rows?.element.AnganwadiID.awwName}</span>
						<span className="table-data-sub-text">{rows?.element.AnganwadiID.mobile}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper w-5">
						<span className="table-data-text">{quantity && quantity["Pregnant and Lactating Women"]}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper">
						<span className="table-data-text">{quantity && quantity["Normal Children (7m-3yrs)"]}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper">
						<span className="table-data-text">{quantity && quantity["Supervised Feeding (7m-3yrs)"]}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper">
						<span className="table-data-text">{quantity && quantity["Normal Pre-School Children (3-6yrs)"]}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper">
						<span className="table-data-text">{quantity && quantity["Supervised Feeding (3-6yrs)"]}</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper w-4">
						<span className="table-data-text">{rows?.mapIndentCmd[0]?.Qty / 1000} KG</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper w-4">
						<span className="table-data-text">{rows?.mapIndentCmd[1]?.Qty / 1000} KG</span>
					</div>
				</TableCell>
				<TableCell align="center">
					<div className="table-data-wrapper w-4">
						<span className="table-data-text">{rows?.mapIndentCmd[2]?.Qty / 1000} KG</span>
					</div>
				</TableCell>
				{/* <TableCell align="center">
          <div className="table-data-wrapper w-4">
            <span className="table-data-text">153 L</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper w-4">
            <span className="table-data-text">2106 L</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper w-4">
            <span className="table-data-text">15722 EA</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper w-5">
            <span className="table-data-text">895 KG</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">153 KG</span>
          </div>
        </TableCell> */}
				{/* <TableCell align="right">
          <div className="table-data-wrapper table-flex-column-right">
            <span className="table-data-text">09/03/2021</span>
            <span className="table-data-sub-text">12.35 am</span>
          </div>
        </TableCell> */}
			</TableRow>
		</>
	);
}

export default CenterDetailsRow;
