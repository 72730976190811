import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function InventoryHistoryRow() {
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">February 2021</span>
          </div>
        </TableCell>
        {/* <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">750</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">338</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">3318</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">4424</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">750</span>
          </div>
        </TableCell> */}
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">380</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">365</span>
          </div>
        </TableCell>
        <TableCell align="right">
          <div className="table-data-wrapper table-flex-column-right">
            <span className="table-data-text">07/02/2022</span>
            <span className="table-data-sub-text">12:35pm</span>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryHistoryRow;
