import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function ViewShipmentRow() {
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text table-flex-center">
              Balamruthum{" "}
              <i class="bx bxs-check-circle table-check-icons icon-available"></i>
            </span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">0 KG</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">0 KG</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text"></span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">02/2022</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text"></span>
          </div>
        </TableCell>
        <TableCell align="right">
          <div className="table-data-wrapper table-flex-column-right">
            <span className="table-data-text">07/02/2022</span>
            <span className="table-data-sub-text">12:47 pm</span>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ViewShipmentRow;
