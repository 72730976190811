import React from "react";
import "./Story.css";

import Banner from "../../../assets/icons/pictures/team.png";

export default function Story() {
  return (
    <React.Fragment>
      <section className="Story__main_container section_space">
        <div className="Page__main_container">
          <div className="Story__layout_wrapper">
            <div className="Story__banner_wrapper">
              <img
                src={Banner}
                alt="Banner"
                data-aos="slide-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </div>
            <div className="Story_two_column_grid">
              <div
                className="Story__content_wrapper"
                data-aos="fade-right"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <p className="SectionTitle__tagline_text">Our Story</p>
                <h1 className="story_heading_text">
                  UnifyAid: Where Aid Meets Innovation, Compassion, and
                  Precision.
                </h1>
                <p className="Iconcard__message_text">
                  UnifyAid was founded with the vision of revolutionizing
                  humanitarian aid delivery. Our team of dedicated professionals
                  combines expertise in the humanitarian and technology sectors,
                  driven by a shared commitment to creating positive change.
                  Through our innovative solutions, we aim to empower humanity
                  and ensure that aid reaches the right people at the right
                  time.
                </p>
              </div>
              <div
                className="Story__content_wrapper"
                data-aos="fade-left"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <p className="SectionTitle__tagline_text">Our Team</p>
                <h1 className="story_heading_text">
                  Passion. Expertise. Impact. Meet the Team Behind the Change
                </h1>
                <p className="Iconcard__message_text">
                  Meet our passionate and experienced team members who are at
                  the forefront of the UnifyAid mission. With diverse
                  backgrounds in technology, humanitarian aid, and project
                  management, our team brings a wealth of knowledge and
                  expertise to ensure the success of our solutions. Together, we
                  are dedicated to making a real difference in the lives of
                  those in need.
                </p>
              </div>
            </div>
            <div className="Story__mobile_banner_wrapper">
              <img src={Banner} alt="Banner" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
