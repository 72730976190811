import React from "react";
import ResetPassword from "../../components/homePage/ResetPassword";

function ResetPasswordContainer() {
  return (
    <div className="home-container">
      <ResetPassword />
    </div>
  );
}

export default ResetPasswordContainer;
