import React from "react";
import "./Challenges.css";
import SectionTitle from "../common/section_title/SectionTitle";
import Iconcard from "../common/icon_card/Iconcard";
import Countercard from "../common/counter_card/Countercard";

export default function Challenges({ challengeRef }) {
  return (
    <React.Fragment>
      <section
        className="Challenges__main_container section_space"
        ref={challengeRef}
      >
        <div className="Page__main_container">
          <div className="Challenges__layout_wrapper">
            <div className="Challenges__section_title_tagline section_left_space">
              <SectionTitle
                tagline="Addressing the Monumental Challenge"
                title="Ensuring Effective Humanitarian Aid Delivery"
              />
            </div>
            <div className="Challenges__point_wrapper section_left_space">
              <div className="Challenges__card_list ">
                <div
                  className="challenge_card_wrapper"
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <Iconcard
                    icon="fa-solid fa-hand-holding-heart"
                    title="Reaching the Right Hands"
                    description="Delivering aid to targeted beneficiaries remains a significant challenge worldwide."
                  />
                </div>
                <div
                  className="challenge_card_wrapper"
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <Iconcard
                    icon="fa-solid fa-people-group"
                    title="Engaging Communities"
                    description="Enhancing community engagement is essential to address humanitarian needs effectively."
                  />
                </div>
                <div
                  className="challenge_card_wrapper"
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <Iconcard
                    icon="fa-solid fa-heart-crack"
                    title="Tackling Aid Leakage"
                    description="Ensuring transparency and accountability to prevent aid leakage and optimize resources."
                  />
                </div>
                <div
                  className="challenge_card_wrapper"
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <Iconcard
                    icon="fa-solid fa-circle-question"
                    title="Combatting Pilferages"
                    description="Addressing pilferages to guarantee that aid reaches those who need it most."
                  />
                </div>
              </div>
            </div>

            <div
              className="Challenge__counter_list section_left_space"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Countercard
                count="$51.5"
                unit="billion"
                title="Enormous Funding"
                description="Demanding $51.5 billion in 2023, efficient resource utilization becomes critical. Yet, pilferages and engagement gaps hinder effective allocation."
              />
              <Countercard
                count="332.9"
                unit="million"
                title="Scale of Need"
                description="People require assistance globally (1 in every 23 people), highlighting the widespread problem. But not all aid reaches the intended beneficiaries, leading to inefficiencies."
              />

              <Countercard
                count="7.5%"
                unit="percentage"
                title="Aid Leakage & Transparency"
                description="7.5% of aid funneled to tax havens urges transparent and accountable systems. Ensuring aid reaches the right people at the right time is crucial."
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
