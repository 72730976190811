import React, { useState, useEffect } from "react";
import "../../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import SectorIndentRow from "./SectorIndentRow";
import axios from "axios"

function SectorIndent({ dateVal, Tab }) {
  const [sectorInd, setSectorInd] = useState([])
  const getSectorIndent = async () => {
    try {
      let sec = JSON.parse(localStorage.getItem("user"))
      sec = (sec.user.Sector);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/supervisors/sector-indent?sector=` + sec)
      setSectorInd(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  // Dropdown Button Style
  const CustomStatus = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="table-head-wrapper cursorP"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      id="dropdown-basic"
    >
      <span className="table-heading-text">{children}</span>
    </div>
  ));

  const CustomMenuStatus = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const Data = [
    {
      id: "1",
      status: "APPROVED",
    },
    {
      id: "2",
      status: "PENDING",
    },
    {
      id: "3",
      status: "APPROVED",
    },
    {
      id: "4",
      status: "PENDING",
    },
    {
      id: "5",
      status: "APPROVED",
    },
    {
      id: "6",
      status: "APPROVED",
    },
    {
      id: "7",
      status: "APPROVED",
    },
    {
      id: "8",
      status: "PENDING",
    },
    {
      id: "9",
      status: "PENDING",
    },
    {
      id: "10",
      status: "APPROVED",
    },
  ];

  const [Check, setCheck] = useState(new Array(Data.length).fill(false));
  const count = Check.filter((item) => item === true).length;
  const handleChange2 = () => {
    const updatedCheckedState = Check.map((item) => !item);
    setCheck(updatedCheckedState);
  };
  useEffect(() => {
    console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    getSectorIndent()
  }, []);

  return (
    <>
      {count > 0 && (
        <div className="selected-row-counter">
          <span className="selected-row-text">{count} Indent Selected</span>
          <button className="btn btn-medium btn-blue-outline-hover">
            <i class="bx bxs-check-circle icons-btn-sm"></i> Approve
          </button>
        </div>
      )}
      <TableContainer
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
          borderRadius: "0.4rem",
          background: "#fff",
        }}
      >
        <Table sx={{ minWidth: 1098 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={() => handleChange2()}
                  Checked={Check}
                />
              </TableCell> */}
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">District</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Sector</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Indent ID</span>
                </div>
              </TableCell>
              {/* <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">CDPO</span>
                </div>
              </TableCell> */}
              <TableCell>
                <div className="table-head-wrapper">
                  <span className="table-heading-text">Total Beneficiary</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="table-head-wrapper table-head-wrapper-border-less">
                  <span className="table-heading-text">Month</span>
                </div>
              </TableCell>
              <TableCell>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomStatus}
                    id="dropdown-custom-components"
                  >
                    Status
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenuStatus} align="end">
                    <Dropdown.Item>
                      <button className="btn drop-btn">Active</button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="btn drop-btn">Non Active</button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sectorInd && sectorInd.map((rows, index) => (
              <SectorIndentRow
                key={index}
                index={index}
                rows={rows}
                Check={Check}
                setCheck={setCheck}
                data="from component"
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SectorIndent;
