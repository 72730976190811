import React from "react";
import "./Iconcard.css";

export default function Iconcard({ icon, title, description }) {
  return (
    <React.Fragment>
      <div className="Iconcard__wrapper">
        <div className="Iconcard__icon">
          <i className={`${icon}`}></i>
        </div>
        <div className="Iconcard__details">
          <h2 className="Iconcard__title_text">
           {title}
          </h2>
          <p className="Iconcard__message_text">
            {description}
            
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
