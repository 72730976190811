import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment";

function SectorIndentRow(props) {
  const { rows,  index , handleCheck } = props;

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onChange={handleCheck}
            name={rows._id}
            checked={rows?.isChecked || false}

          />
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.SectorID?.Project?.District?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.SectorID?.Project?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.SectorID?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.IndentRef}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.SupervisorId?.Username}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{moment(rows?.createdAt).format("DD/MM/YYYY")}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span
              className={`btn-small btn-status cursor-none ${
                rows?.SupervisorApproved === true ? "btn-blue" : "btn-yellow"
              }`}
            >
              {rows?.SupervisorApproved === true && "Approved"}
              {rows?.SupervisorApproved === false && "Pending"}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <Link className="link-none" to={{pathname :`/Cdpo/ViewIndent/${rows._id}`}}>
              <button className="btn btn-medium btn-blue-outline-hover">
                View
              </button>
            </Link>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SectorIndentRow;
