import React from "react";
import "./Blogcard.css";
import { Link } from "react-router-dom";

export default function Blogcard({ bloginfo }) {
  return (
    <React.Fragment>
      <div className={`Blogcard__wrapper b${bloginfo.id}`}>
        <div className="Blogcard__content_wrap">
          <h1 className="mi_heading mi_white">{bloginfo.blogheading}</h1>
          <p className="Iconcard__message_text mi_white">{bloginfo.bloginfo}</p>
        </div>
        <Link to={`blog/${bloginfo.id}`} className="Blogcard__action_wrap">
          <button className="mi_btn mi_btn_medium mi_btn_secondary">
            Read More
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
}
