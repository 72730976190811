import React from "react";
import "../../../../assets/styles/table/Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InventoryHistoryRow from "./InventoryHistoryRow";

function InventoryHistoryTable() {
  const Data = [
    {
      id: "1",
      status: "APPROVED",
    },
    {
      id: "2",
      status: "PENDING",
    },
    // {
    //   id: "3",
    //   status: "APPROVED",
    // },
    // {
    //   id: "4",
    //   status: "PENDING",
    // },
    // {
    //   id: "5",
    //   status: "APPROVED",
    // },
    // {
    //   id: "6",
    //   status: "APPROVED",
    // },
    // {
    //   id: "7",
    //   status: "APPROVED",
    // },
    // {
    //   id: "8",
    //   status: "PENDING",
    // },
    // {
    //   id: "9",
    //   status: "PENDING",
    // },
    // {
    //   id: "10",
    //   status: "APPROVED",
    // },
  ];

  return (
    <TableContainer
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
        borderRadius: "0.4rem",
        background: "#fff",
        maxHeight: 440,
      }}
    >
      <Table stickyHeader sx={{ minWidth: 1098 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl">
                <span className="table-heading-text">Month</span>
              </div>
            </TableCell>
            {/* <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Rice</span>
                <span className="table-subheading-text">(in KG)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Dal</span>
                <span className="table-subheading-text">(in KG)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Oil</span>
                <span className="table-subheading-text">(in L)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Milk</span>
                <span className="table-subheading-text">(in L)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Egg</span>
                <span className="table-subheading-text">(in Unit)</span>
              </div>
            </TableCell> */}
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Balamrutham</span>
                <span className="table-subheading-text">(in Kg)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
                borderRight: "1px solid #ddd !important",
              }}
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Murukulu</span>
                <span className="table-subheading-text">(in Kg)</span>
              </div>
            </TableCell>
            <TableCell
              sx={{
                padding: "0px 16px",
              }}
              align="right"
            >
              <div className="table-head-wrapper-xl table-flex-column-center">
                <span className="table-heading-text">Date</span>
                <span className="table-subheading-text">Time</span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Data.map((rows, index) => (
            <InventoryHistoryRow key={index} index={index} rows={rows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InventoryHistoryTable;
