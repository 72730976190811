import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

function ShipmentRow(props) {
  const { rows, keys, index } = props;
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Project}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.Sector}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper table-flex-column">
            <span className="table-data-text text-pink">{rows?.Center}</span>
            <span className="table-data-sub-text">{rows?.Code}</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            {rows?.Bala === 1 ? <i class="bx bxs-check-circle table-status-icons icon-available"></i> : rows?.Bala === 0 ? <i class="bx bxs-error-circle table-status-icons icon-doubt"></i> : <i class="bx bxs-x-circle table-status-icons icon-not-available"></i>}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
          {rows?.Muru === 1 ? <i class="bx bxs-check-circle table-status-icons icon-available"></i> : rows?.Muru === 0 ? <i class="bx bxs-error-circle table-status-icons icon-doubt"></i> : <i class="bx bxs-x-circle table-status-icons icon-not-available"></i>}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
          {rows?.Oil === 1 ? <i class="bx bxs-check-circle table-status-icons icon-available"></i> : rows?.Oil === 0 ? <i class="bx bxs-error-circle table-status-icons icon-doubt"></i> : <i class="bx bxs-x-circle table-status-icons icon-not-available"></i>}
          </div>
        </TableCell>
        <TableCell>
          {/* <Link className="link-none" to="/Cdpo/ViewShipment">
            <button className="btn btn-medium btn-blue-outline-hover">
              View
            </button>
          </Link> */}
        </TableCell>
      </TableRow>
    </>
  );
}

export default ShipmentRow;
