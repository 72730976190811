import React, { useState } from "react";
import "./Header.scss";
import Notifications from "@mui/icons-material/NotificationsNoneOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Badge from "@mui/material/Badge";
import Statwiglogo from "../../assets/icons/svgs/Statwiglogo.png";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
// import Brandlogo from "../../assets/icons/svgs/Brandlogo.svg";
import Brandlogo from "../../assets/icons/logo/logo.svg";
// import Search from "@mui/icons-material/Search";
// import { Autocomplete, TextField } from "@mui/material";

function Header(props) {
  const [Profile, setProfile] = useState(false);
  const [MobileMenu, setMobileMenu] = useState(false);
  return (
    <header id="header">
      <nav className="main-navbar">
        <div className="brand-logo">
          <div
            className="mobile-sidebar"
            onClick={() => props.setOpen(!props.Open)}
          >
            <i className="bx bx-menu icons sidebar-icon"></i>
          </div>
          <Link to="/" className="brand-link">
            <img
              className="brand-logo-image"
              src={Brandlogo}
              alt="Brand-Logo"
            />
            {/* <span className="logo-sub-text">Commissionarate</span> */}
          </Link>
        </div>

        <ul className="main-nav-list">
          <li className="main-nav-items">
            {/* <div className="SearchBar">
              <Autocomplete
                freeSolo
                id="free-solo-2"
                disableClearable
                forcePopupIcon={true}
                popupIcon={
                  <Search
                    style={{ color: "#FFA83B" }}
                    // className={search ? "d-none-icons" : ""}
                  />
                }
                // options={searchData.map((search) => search.vctNo)}
                // inputValue={search}
                // onInputChange={(event, newInputValue) => {
                //   setSearch(newInputValue);
                //   onSearchChange(newInputValue);
                // }}
                // onChange={(event, newValue) => {
                //   setSearch(newValue);
                //   onSearchChange(newValue);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </div> */}

            {/* <div className="SearchBar">
              <Autocomplete
                freeSolo
                id="free-solo-2"
                disableClearable
                forcePopupIcon={true}
                popupIcon={
                  <Search
                    style={{ color: "#FFA83B" }}
                    // className={search ? "d-none-icons" : ""}
                  />
                }
                // options={searchData.map((search) => search.vctNo)}
                // inputValue={search}
                // onInputChange={(event, newInputValue) => {
                //   setSearch(newInputValue);
                //   onSearchChange(newInputValue);
                // }}
                // onChange={(event, newValue) => {
                //   setSearch(newValue);
                //   onSearchChange(newValue);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </div> */}
          </li>

          {/* <li className="main-nav-items">
            <Badge sx={{ color: "#333 !important" }} variant="dot">
              <Notifications className="icons notify-icon" />
            </Badge>
          </li> */}

          <li className="main-nav-items profile-container">
            <Avatar
              className="profile-avatar"
              onClick={() => setProfile(!Profile)}
            >
              A
            </Avatar>
            {/* <div className={`profile-drop ${Profile ? "" : "active-ss"}`}>
              <div className="profile-card">
                <div className="profile-cell">
                  <div className="profile-mobile-card">
                    <Avatar className="profile-avatar-mobile">A</Avatar>
                  </div>
                  <div className="username-container-mobile">
                    <span className="organisation-name-text">
                      A.B.C Ltd Pvt
                    </span>
                    <div className="username-text">Arjun M</div>
                  </div>
                </div>
                <div className="profile-cell-links">
                  <Link to="/Shipment" className="side-nav-link text-grey">
                    <i className="bx bxs-user side-nav-icon"></i>
                    <span className="side-nav-text">Manage Account</span>
                  </Link>
                  <Link to="/IndentRequest" className="side-nav-link text-grey">
                    <i className="bx bx-cog side-nav-icon"></i>
                    <span className="side-nav-text">Setting</span>
                  </Link>
                  <Link to="/Logout" className="side-nav-link text-grey">
                    <i className="bx bx-log-out side-nav-icon"></i>
                    <span className="side-nav-text">Logout</span>
                  </Link>
                </div>
              </div>
            </div> */}
          </li>
        </ul>

        <div
          className="mobile-view mobile-nav-list"
          onClick={() => setMobileMenu(!MobileMenu)}
        >
          <MoreVertOutlinedIcon className="icons menu-icon" />
        </div>
        <div
          className={`${
            MobileMenu ? "mobile-nav mobile-nav-active" : "mobile-nav"
          }`}
        >
          <div className="content-cell">
            <div className="mobile-menu-header">
              <i
                className="bx bx-x close-icon"
                onClick={() => setMobileMenu(false)}
              ></i>
              <i className="bx bx-search search-icon"></i>
            </div>
            <div className="profile-cell">
              <div className="profile-mobile-card">
                <Avatar className="profile-avatar-mobile">A</Avatar>
              </div>
              <div className="username-container-mobile">
                <span className="organisation-name-text">A.B.C Ltd Pvt</span>
                <div className="username-text">Arjun M</div>
              </div>
            </div>
            <div className="profile-cell-links">
              <Link to="/Shipment" className="side-nav-link text-grey">
                <i className="bx bxs-user  side-nav-icon"></i>
                <span className="side-nav-text">Manage Account</span>
              </Link>
              <Link to="/IndentRequest" className="side-nav-link text-grey">
                <i className="bx bx-cog side-nav-icon"></i>
                <span className="side-nav-text">Setting</span>
              </Link>
              <Link to="/Logout" className="side-nav-link text-grey">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link>
            </div>
          </div>
          <div className="sponsor-cell">
            <span className="sponser-text text-pink">Version 1.2</span>
            <span className="sponser-text text-pink">Made with care</span>
            <img
              className="sponser-logo-image"
              src={Statwiglogo}
              alt="Power-Logo"
            />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
