import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/indent/Indent.scss";
import "../../../assets/styles/input/Input.scss";
import "../../../assets/styles/inventory/Inventory.scss";
import ViewShipmentDetail from "./viewShipmentDetails/ViewShipmentDetail";
import ViewShipmentTable from "./viewShipmentTable/ViewShipmentTable";

function ViewShipment() {
  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">VIEW SHIPMENT</h1>
        <Link className="link-none" to="/Co/Shipment">
          <button className="btn-alt btn-link-view">
            <i class="bx bx-arrow-back icons-btn"></i> Back to Shipment
          </button>
        </Link>
      </div>
      <div className="user-table-grid-card">
        <ViewShipmentDetail />
      </div>
      <div className="user-table-content">
        <ViewShipmentTable />
      </div>
    </div>
  );
}

export default ViewShipment;
