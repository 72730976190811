import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import moment from "moment"

function ProjectIndentRow(props) {
  const { rows, index } = props;
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        id={index}
      >
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.districtStaticData?.District?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows.element.ProjectID?.Label}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.element?.IndentRef}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span className="table-data-text">{moment(rows?.createdAt).format("MMMM YYYY")}</span>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="table-data-wrapper">
            <span className="table-data-text">{rows?.totalQty}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <span
              className={`btn-small btn-status cursor-none ${rows?.element.SupervisorApproved === true ? "btn-blue" : "btn-yellow"
                }`}
            >
              {rows?.element.SupervisorApproved === true ? "Approved" : "Pending"}

            </span>
          </div>
        </TableCell>
        <TableCell>
          <div className="table-data-wrapper">
            <Link className="link-none" to={{ pathname: `/Cdpo/ProjectIndent/${rows.element._id}` }}>
              <button className="btn btn-medium btn-blue-outline-hover">
                View
              </button>
            </Link>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ProjectIndentRow;
