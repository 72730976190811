import React from "react";
import "./Aboutus.css";
import SectionTitle from "../common/section_title/SectionTitle";
import AboutusImage from "../../../assets/icons/pictures/aboutus.png";

export default function Aboutus({ aboutRef }) {
  return (
    <React.Fragment>
      <section className="Aboutus__main_container section_space" ref={aboutRef}>
        <div className="Page__main_container">
          <main className="Aboutus__two_column_grid">
            <article className="Aboutus__content_area section_left_space">
              <SectionTitle
                tagline="Learn About us"
                title="Empowering Humanity through Innovative Solutions"
              />
              <p
                className="mi_body Aboutus__body_text"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                At UnifyAid, we are on a mission to transform humanitarian aid
                delivery and make a lasting impact on the lives of beneficiaries
                worldwide. Our comprehensive and innovative solutions are
                designed to enhance efficiency, transparency, and impact in the
                humanitarian sector.
              </p>
              {/* <div
                className="Aboutus__action_wrapper"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <button className="mi_btn mi_btn_medium mi_btn_primary">
                  Learn more
                </button>
              </div> */}
            </article>
            <article className="Aboutus__image_area">
              <img
                src={AboutusImage}
                alt="AboutusImage"
                data-aos="fade-in"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </article>
          </main>
        </div>
      </section>
    </React.Fragment>
  );
}
