import React from "react";

function ViewShipmentDetail() {
  return (
    <>
      <div className="center-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Anaganwadi Name</span>
          <span className="indent-card-text-black text-pink">
            A.C.S Nagar 1
          </span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Anaganwadi</span>
          <span className="indent-card-text-black">1602011</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Project</span>
          <span className="indent-card-text-black"></span>
        </div>
        <div className="indent-data-group-grid">
          <span className="indent-card-text">Sector</span>
          <span className="indent-card-text-black">Sec2</span>
        </div>
      </div>
      <div className="mandal-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Mandal</span>
          <span className="indent-card-text-black"></span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">District</span>
          <span className="indent-card-text-black">Dis4</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Address</span>
          <span className="indent-card-text-black">
            
          </span>
        </div>
      </div>
      <div className="aww-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">AWW Name</span>
          <span className="indent-card-text-black">asda</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Mobile</span>
          <span className="indent-card-text-black">+91 9090909090</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">AWH Name</span>
          <span className="indent-card-text-black"></span>
        </div>
        <div className="indent-data-group-grid">
          <span className="indent-card-text">Mobile</span>
          <span className="indent-card-text-black">+91 9021079437</span>
        </div>
      </div>
    </>
  );
}

export default ViewShipmentDetail;
