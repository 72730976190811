import * as React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

function ResetPassword() {
    const [values, setValues] = React.useState({
        otp: "",
        email: "",
        password: "",
        repeatPassword: "",
    });

    useEffect(() => {
        var em = JSON.parse(localStorage.getItem("reset"));
        console.log(localStorage.getItem("reset"))
        console.log(em.email)
        setValues({ ...values, ["email"]: em.email });
    }, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setPassword = async (e) => {
        e.preventDefault()
        console.log(values);
        var eml = values.email;
        var psw = values.password;
        var rpw = values.repeatPassword;
        var otp = values.otp;
        if (psw === rpw) {
        } else {
            toast.error("Password & Confirm Password doesn not match. Please retry.");
            return;
        }
        if (otp.length == 6) {
        } else {
            toast.error("Invalid OTP. Please retry.");
            return;
        }
        var data2 = {
            email: eml,
            password: psw,
            otp: otp
        }
        try {
            const userAdd = await axios.get(`${process.env.REACT_APP_API_URL}/users/resetPassword`, { params: data2 })
            console.log(userAdd.data);
            if (userAdd.data.status == 1) {
                toast.success(userAdd.data.msg);
                window.location = "/Login";
            } else {
                toast.error(userAdd.data.msg);
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    return (
        <form>
            <div className="user-selection-container">
                <div className="select-text-group">
                    <span className="login-heading-text">
                        Reset Password
                    </span>
                </div>

                <div className="login-field">
                    <div className="login-input-group">
                        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-otp">OTP</InputLabel>
                            <OutlinedInput
                                sx={{
                                    backgroundColor: "#fff",
                                    input: {
                                        "&:-webkit-autofill": {
                                            "-webkit-box-shadow": "0 0 0 100px #fff inset",
                                            "-webkit-text-fill-color": "#111",
                                            "-internal-autofill-selected": "#fff !important",
                                        },
                                    },
                                }}
                                id="outlined-adornment-otp"
                                type="number"
                                value={values.otp}
                                onChange={handleChange("otp")}
                                label="OTP"
                            />
                        </FormControl>
                    </div>
                    <div className="login-input-group">
                        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                sx={{
                                    backgroundColor: "#fff",
                                    input: {
                                        "&:-webkit-autofill": {
                                            "-webkit-box-shadow": "0 0 0 100px #fff inset",
                                            "-webkit-text-fill-color": "#111",
                                            "-internal-autofill-selected": "#fff !important",
                                        },
                                    },
                                }}
                                id="outlined-adornment-password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </div>
                    <div className="login-input-group">
                        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-repeatPassword">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                sx={{
                                    backgroundColor: "#fff",
                                    input: {
                                        "&:-webkit-autofill": {
                                            "-webkit-box-shadow": "0 0 0 100px #fff inset",
                                            "-webkit-text-fill-color": "#111",
                                            "-internal-autofill-selected": "#fff !important",
                                        },
                                    },
                                }}
                                id="outlined-adornment-repeatPassword"
                                type={values.showPassword ? "text" : "password"}
                                value={values.repeatPassword}
                                onChange={handleChange("repeatPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                            />
                        </FormControl>
                    </div>
                    {/* <div className="login-action-group">
                        <div className="checkbox-group">
                            <FormGroup>
                                <FormControlLabel
                                    sx={{ color: "red" }}
                                    control={<Checkbox size="small" />}
                                    label={<span className="table-data-text">Remember me</span>}
                                />
                            </FormGroup>
                        </div>
                        <div className="forgot-link">
                            <Link
                                style={{ textDecoration: "none" }}
                                to="/forgot-password"
                                className="table-data-text"
                            >
                                Forgot password?
                            </Link>
                        </div>
                    </div> */}
                </div>
                {/* <Link
                    to={`${values.email === "cdpo"
                        ? "Cdpo/IndentRequest"
                        : values.email === "co"
                            ? "Co/IndentRequest"
                            : values.email === "supervisor" && "Supervisor/IndentRequest"
                        }`}
                    // to='Supervisor/IndentRequest'
                    className="link-none"
                > */}
                <button type="submit" className={`btn btn-pink btn-homepage`} onClick={setPassword}>Reset Password</button>
                {/* </Link> */}

                <div className="forgot-link">
                    <Link
                        style={{ textDecoration: "none" }}
                        to="/Login"
                        className="table-data-text"
                    >
                        Back to Login
                    </Link>
                </div>
                <ToastContainer />
            </div>
        </form>
    );
}

export default ResetPassword;
