import React, { useRef } from "react";

import HomeHeader from "./Site_header/HomeHeader";
import "./Home.css";
import Showcase from "./Showcase/Showcase";
import About from "./About/About";
import Features from "./Features/Features";
import Site_footer from "./Site_footer/Site_footer";
import Blog from "./Blog/Blog";

export default function Home() {
  const aboutRef = useRef(null);
  const featureRef = useRef(null);
  const blogRef = useRef(null);
  const contactRef = useRef(null);

  const handleNavClick = (option) => {
    switch (option) {
      case "about":
        aboutRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "feature":
        featureRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "blog":
        blogRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      case "contact":
        contactRef.current?.scrollIntoView({ behavaiour: "smooth" });
        break;
      default:
        break;
    }
  };
  return (
    <main className="landing_container">
      <HomeHeader handleNavClick={handleNavClick} />
      <Showcase />
      <About aboutRef={aboutRef} />
      <Features featureRef={featureRef} />
      <Blog blogRef={blogRef} />
      <Site_footer contactRef={contactRef} />
    </main>
  );
}
