import React from "react";
import LandingPage from "../../components/homePage/LandingPage";

function LandpageContainer() {
  return (
    <div className="home-container">
      <LandingPage />
    </div>
  );
}

export default LandpageContainer;
