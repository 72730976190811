import React, { useEffect, useState } from "react";
import "../../../assets/styles/page/Page.scss";
import SectorIndent from "./Tables/sectorIndent/SectorIndent.jsx";
import ProjectIndent from "./Tables/projectIndent/ProjectIndent.jsx";
import moment from "moment"
import { Link } from "react-router-dom";
import axios from "axios";


function IndentRequest() {
  const [projectid, setProjectid] = useState("");
  const [Tab, setTab] = useState(1);
  const [dateVal, setDateVal] = useState(moment().format('YYYY-MM'));
  const [consolidateTrue, setConsolidateTrue] = useState(true)

  useEffect(() => {
    let sec = JSON.parse(localStorage.getItem("user"))
    setProjectid((sec.user.Project))
    // console.log("****************************************", sec)
    checkIfConsolidated();
  }, []);
  const checkIfConsolidated = async () => {
    try {
      var sec = JSON.parse(localStorage.getItem("user"))
      // console.log("******DATE*****");
      let dt = dateVal.split("-")
      // console.log(dt)
      // console.log(dt[0])
      // console.log("*********************** PROJECT ID- ", projectid)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/cdpos/get-consolidate`, {
        params: {
          projectId: sec.user.Project,
          month: dt[1],
          //month: moment().format('MM'),
          year: dt[0], 
          //year: moment().format('YYYY'),
        }
      });
      console.log("CONSOLIDATED DATA")
      console.log(res.data)
      setConsolidateTrue(!res.data.isSectorIndentView)
    } catch (error) {
      console.log(error);
    }
  }

  const handleDateChange = (e) => {
    console.log("month", e.target.value);
    setDateVal(e.target.value)
  }

  return (
    <div id="page-container">
      <div className="users-page-header">
        <h1 className="page-heading-text">INDENT REQUEST</h1>
      </div>
      <div className="page-tab-group">
        <ul className="tab-list">
          <li
            className={`${Tab === 1 ? "tab-item-active" : "tab-item"}`}
            onClick={() => setTab(1)}
          >
            <span
              className={`${Tab === 1 ? "tab-link-text-active" : "tab-link-text"
                }`}
            >
              Sector Indent
            </span>
          </li>
          <li
            className={`${Tab === 2 ? "tab-item-active" : "tab-item"}`}
            onClick={() => setTab(2)}
          >
            <span
              className={`${Tab === 2 ? "tab-link-text-active" : "tab-link-text"
                }`}
            >
              Project Indent
            </span>
          </li>
        </ul>
        <div className="page-heading-btn-group">
          <span className="indent-card-data-text-md indent-flex-center">
            {/* {moment().format('MMMM YYYY')} <i class="bx bxs-calendar icons-class"></i> */}
            <input type="month" value={dateVal} onChange={handleDateChange} />
          </span>
          {consolidateTrue === true ? <Link to={{ pathname: `/Cdpo/ProjectIndent/` + projectid }}>

            <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"}>
              Consolidate Indents
            </button>
          </Link> : <button className={consolidateTrue ? "btn btn-pink" : "btn btn-pink btn-disable"} disabled={consolidateTrue}>
            Consolidate Indents
          </button>}
        </div>
      </div>
      <div className="user-table-contents">
        {Tab === 1 && <SectorIndent dateVal={dateVal} handleDateChange={handleDateChange} setConsolidateTrue={setConsolidateTrue} consolidateTrue={consolidateTrue} />}
        {Tab === 2 && <ProjectIndent />}
      </div>
    </div>
  );
}

export default IndentRequest;
