import React from "react";
import { blogcontent } from "../data/blogcontent";
import { useNavigate } from "react-router-dom";

function RelatedBlogCard({ bloginfo, navigate }) {
  return (
    <>
      <div
        // onClick={() => navigate(`blog/${bloginfo.id}`)}
        onClick={() => navigate(`/blog/${bloginfo.id}`)}
        class="related_post_card"
      >
        <div class={`related_post_image_holder b${bloginfo.id}`}></div>

        <div class="related_content_space">
          <p class="related_blog_tag_ts">{bloginfo.date} </p>
          <p class="related_blog_title_ts">{bloginfo.blogheading}</p>
        </div>
      </div>
    </>
  );
}

export default function RelatedBlog() {
  const navigate = useNavigate();
  return (
    <>
      <div class="blog_related_wrapper">
        <div class="blog_related_card_container">
          <p class="related_blog_heading_ts">Popular Posts</p>
          <div class="related_post_list">
            {blogcontent?.map((bloginfo) => (
              <RelatedBlogCard bloginfo={bloginfo} navigate={navigate} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
