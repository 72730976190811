import React, { useState } from "react";
import "./LandingPage.scss";
import Selection from "./commons/Selection.jsx";
import Brandlogo from "../../assets/icons/logo/logo.svg";
// import Brandlogo from "../../assets/icons/svgs/Brandlogo.svg";
import ResetPasswordPage from "./commons/ResetPassword";
import Background from "../../assets/icons/backgrounds/background.png";

function ResetPassword() {
  const [Role, setRole] = useState(null);
  const [Step, setStep] = useState(1);

  const handleOtherRole = () => {
    setStep(2);
    setRole("USER");
  };

  return (
    <div className="home-container">
      <div
        style={{ backgroundImage: `url(${Background})` }}
        className="landing-container"
      >
        <div className="container-center-wrap">
          <div className="landing-page-box">
            <div className="user-content-area">
              <img src={Brandlogo} alt="logo" className="landing-brand-logo" />
              {/* <span className="landing-large-text">Welcome back,</span>
            <span className="landing-large-sub-text">Login to Continue</span> */}
            </div>
            <div className="user-selection-area">
              <ResetPasswordPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
