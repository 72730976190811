import React from "react";
import "./Sidebar.scss";
import { useLocation } from "react-router-dom";
import Statwiglogo from "../../assets/icons/svgs/Statwiglogo.png";
import { Link, NavLink } from "react-router-dom";

function Sidebar(props) {
  const location = useLocation();

    console.log("location.pathname." , location.pathname);
  return (
    <div id="sidebar" className={`${props.Open && "show-active"}`}>
      <nav className="side-nav">
        <div className="side-nav-list">
          {/* ---------------CO Links------------------------------ */}
          {location.pathname.includes("/Co") && (
            <>
              <NavLink to="/Co/IndentRequest" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Indent Request</span>
              </NavLink>
              <NavLink to="/Co/Inventory" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-save side-nav-icon"></i>
                <span className="side-nav-text">Inventory</span>
              </NavLink>
              <NavLink to="/Co/Shipment" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bxs-truck side-nav-icon"></i>
                <span className="side-nav-text">Shipment</span>
              </NavLink>
              <NavLink to="/Co/InventoryReport" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bxs-truck side-nav-icon"></i>
                <span className="side-nav-text">Inventory Report</span>
              </NavLink>
              <Link to="/Logout" className="side-nav-link">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link>
              {/* <Link to="/Co/IndentRequest" className="side-nav-link active">
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Indent Request</span>
              </Link>
              <Link to="/Co/Inventory" className="side-nav-link">
                <i className="bx bx-save side-nav-icon"></i>
                <span className="side-nav-text">Inventory</span>
              </Link>
              <Link to="/Co/Shipment" className="side-nav-link">
                <i className="bx bxs-truck side-nav-icon"></i>
                <span className="side-nav-text">Shipment</span>
              </Link>
              <Link to="/Co/IndentRequest" className="side-nav-link">
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Beneficiary</span>
              </Link>
              <Link to="/" className="side-nav-link">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link> */}
            </>
          )}

          {/* CDPO Links */}

          {location.pathname.includes("/Cdpo") && (
            <>
              <NavLink to="/Cdpo/IndentRequest" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Indent Request</span>
              </NavLink>
              <NavLink to="/Cdpo/Inventory" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-save side-nav-icon"></i>
                <span className="side-nav-text">Inventory</span>
              </NavLink>
              <NavLink to="/Cdpo/Shipment" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bxs-truck side-nav-icon"></i>
                <span className="side-nav-text">Shipment</span>
              </NavLink>
              <Link to="/Logout" className="side-nav-link">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link>
{/*               
              <Link to="/Cdpo/IndentRequest" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Indent Request</span>
              </Link>
              <Link to="/Cdpo/Inventory" className={({ isActive }) => (isActive ? "side-nav-link active" : "side-nav-link")}>
                <i className="bx bx-save side-nav-icon"></i>
                <span className="side-nav-text">Inventory</span>
              </Link>
              <Link to="/Cdpo/Shipment" className="side-nav-link">
                <i className="bx bxs-truck side-nav-icon"></i>
                <span className="side-nav-text">Shipment</span>
              </Link>
              <Link to="#" className="side-nav-link">
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Beneficiary</span>
              </Link>
              <Link to="/" className="side-nav-link">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link> */}
            </>
          )}

          {/* Supervisor */}
          {location.pathname.includes("/Supervisor") && (
            <>
              <Link to="/Supervisor/IndentRequest" className="side-nav-link active">
                <i className="bx bx-message-square-detail side-nav-icon"></i>
                <span className="side-nav-text">Indent Request</span>
              </Link>
              <Link to="/Logout" className="side-nav-link">
                <i className="bx bx-log-out side-nav-icon"></i>
                <span className="side-nav-text">Logout</span>
              </Link>
              
            </>
          )}
        </div>
        <div className="sponser-logo">
          <span className="sponser-text">Version 1.2</span>
          <span className="sponser-text">Made with care</span>
          <img
            className="sponser-logo-image"
            src={Statwiglogo}
            alt="Power-Logo"
          />
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
