import React from "react";
import "../../../assets/styles/layouts/Layout.scss";
import InventoryHistory from "../../../components/cdpo/inventoryHistory/InventoryHistory";
import Header from "../../../shared/header/Header.jsx";
import Sidebar from "../../../shared/sidebar/Sidebar.jsx";

function InventoryHistoryContainer() {
  const [Open, setOpen] = React.useState(false);
  return (
    <div className="layout-container">
      <Header setOpen={setOpen} Open={Open} />
      <Sidebar Open={Open} />
      <InventoryHistory />
    </div>
  );
}

export default InventoryHistoryContainer;
