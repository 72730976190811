import React from "react";

function ViewShipmentDetail() {
  return (
    <>
      <div className="center-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Anaganwadi Name</span>
          <span className="indent-card-text-black text-pink">
            A.C.S Nagar 1
          </span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Anaganwadi</span>
          <span className="indent-card-text-black">1602011</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Project</span>
          <span className="indent-card-text-black">Alwal</span>
        </div>
        <div className="indent-data-group-grid">
          <span className="indent-card-text">Sector</span>
          <span className="indent-card-text-black">Addagutta</span>
        </div>
      </div>
      <div className="mandal-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Mandal</span>
          <span className="indent-card-text-black">Maradpally</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">District</span>
          <span className="indent-card-text-black">Secunderabad</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Address</span>
          <span className="indent-card-text-black">
            388, Street Number 8, Nehrunagar Society, Mahendra Hills, East
            Marredpally, Secunderabad, Telangana 500026
          </span>
        </div>
      </div>
      <div className="aww-card">
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">AWW Name</span>
          <span className="indent-card-text-black">Vathala Swaroopa</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">Mobile</span>
          <span className="indent-card-text-black">+91 9090909090</span>
        </div>
        <div className="indent-data-group-grid mb-medium">
          <span className="indent-card-text">AWH Name</span>
          <span className="indent-card-text-black">Kakarla Yellamma</span>
        </div>
        <div className="indent-data-group-grid">
          <span className="indent-card-text">Mobile</span>
          <span className="indent-card-text-black">+91 9090909090</span>
        </div>
      </div>
    </>
  );
}

export default ViewShipmentDetail;
