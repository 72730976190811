import React from "react";
import { Link } from "react-router-dom";
import "./Popup.scss";

function Popup(props) {
  const { setOpenModal , indentId ,
     redirectLink  , text} = props;
  return (
    <div className="popup-container">
      <i class="bx bxs-check-circle success-icon icon-available"></i>
      <span className="popup-big-text">Success!</span>
      <span className="popup-medium-text">{text} been</span>
      <span className="popup-medium-text">Approved Successfully!</span>
     {indentId && <span className="popup-small-text">
        Indent ID - <span className="popup-small-text-bold">{indentId || "Indent"}</span>
      </span>}
      <Link to={redirectLink}>
        <button className="btn btn-pink btn-popup" onClick={() => setOpenModal(false)}>
          OK
        </button>
      </Link>
    </div>
  );
}

export default Popup;
