import React from "react";
import "../../../assets/styles/layouts/Layout.scss";
import Header from "../../../shared/header/Header.jsx";
import Sidebar from "../../../shared/sidebar/Sidebar.jsx";
import ViewConsolidate from "../../../components/cdpo/viewIndent/ViewConsolidate.jsx";

function ViewIndentContainer() {
  const [Open, setOpen] = React.useState(false);
  return (
    <div className="layout-container">
      <Header setOpen={setOpen} Open={Open} />
      <Sidebar Open={Open} />
      <ViewConsolidate />
    </div>
  );
}

export default ViewIndentContainer;
