import React from "react";
import "../LoginForm.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

export default function ResetPasswordComponent({
  setLayout,
  handleClickAlert,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const resetPassword = async (values) => {
    try {
      if (values.password !== values.confirmPassword) {
        setError("confirmPassword", {
          type: "custom",
          message: "Passwords do not match!",
        });
      } else {
        const resetEmail = JSON.parse(localStorage.getItem("reset"));
        const payload = {
          email: resetEmail.email,
          otp: resetEmail.otp,
          password: values.password,
        };

        const userAdd = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/resetPassword`,
          { params: payload }
        );

        if (userAdd.data.status == 1) {
          console.log(userAdd.data.msg);
          setLayout("1");
          handleClickAlert();
        } else {
          console.log(userAdd.data.msg);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form
      action="#"
      className="mi_login_form"
      onSubmit={handleSubmit(resetPassword)}
    >
      <div className="form_headline">
        <h1 className="form_headline_ts">Reset Your Password</h1>
        <p className="form_subheadline_ts">
          That's it, Please create your new password
        </p>
      </div>

      <div className="form_input">
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">New Password</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="input_password">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="input_field"
                    {...field}
                    error={Boolean(errors.password)}
                    helperText={
                      errors.password?.type === "required"
                        ? "Password is required!"
                        : errors.password?.message
                    }
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="password_visible_icon"
                  >
                    {showPassword ? (
                      <i class="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i class="fa-solid fa-eye"></i>
                    )}
                  </div>
                </div>
              )}
            />
            <p className="custom_error_text">
              {errors.password?.type === "required"
                ? "Password is required!"
                : errors.password?.message}
            </p>
          </div>
        </div>
        <div class="input_space">
          <label for="" class="input_label">
            <span class="label_text">Confirm Password</span>
            <span class="important_sign">*</span>
          </label>
          <div class="input_wrap">
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="input_password">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    class="input_field"
                    {...field}
                    error={Boolean(errors.confirmPassword)}
                    helperText={
                      errors.confirmPassword?.type === "required"
                        ? "Password is required!"
                        : errors.confirmPassword?.message
                    }
                  />
                  <div
                    onClick={() => setShowPassword2(!showPassword2)}
                    className="password_visible_icon"
                  >
                    {showPassword2 ? (
                      <i class="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i class="fa-solid fa-eye"></i>
                    )}
                  </div>
                </div>
              )}
            />
            <p className="custom_error_text">
              {errors.confirmPassword?.type === "required"
                ? "Password is required!"
                : errors.confirmPassword?.message}
            </p>
          </div>
        </div>
      </div>
      <div className="form_action">
        <button type="submit" className="form_submit_button">
          Reset Password
        </button>
      </div>
    </form>
  );
}
